import { useState } from 'react';
// MUI
import { TextField } from '@mui/material';
// ICONS
import {
  ChevronUpDownIcon,
  XMarkIcon,
  PencilIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/solid';
//DND
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
// Components
import useThemeStore from 'Theme/store';

const Item = ({ title, id, onDelete, onUpdate }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  // Editable content
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(title);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    onUpdate({ id, title: text });
  };

  const { theme } = useThemeStore();

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...attributes}
      className={`w-full rounded-md   gap-2 p-2 flex items-center mb-2 border border-snow-900 border-solid   ${
        theme == 'dark'
          ? 'dark:bg-graphite-900 dark:hover:bg-graphite-700'
          : 'bg-white hover:bg-snow-300'
      }`}
    >
      <div
        {...listeners}
        ref={setActivatorNodeRef}
        className={`p-1 flex items-center cursor-grab hover:bg-gray-100 dark:hover:bg-graphite-300 rounded`}
      >
        <ChevronUpDownIcon
          className={`w-4 h-4 ${
            theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
          }`}
        />
      </div>
      <div className="w-full">
        {!isEditing ? (
          <h1
            className={`${
              theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
            } leading-3 font-barlow text-sm`}
          >
            {title}
          </h1>
        ) : (
          <TextField
            className="!font-barlow !text-sm"
            sx={{
              flex: 1,
              '& .MuiInputBase-input': {
                fontSize: '14px !important',
                fontFamily: 'Barlow',
                color:
                  theme == 'dark' ? 'dark:text-white' : 'text-graphite-900',
              },
            }}
            variant="standard"
            color="secondary"
            value={text}
            onChange={(e) => setText(e.target.value)}
            onBlur={handleSave}
            autoFocus
            size="small"
          />
        )}
      </div>
      <PencilIcon
        className={`w-4 h-4 cursor-pointer ${
          theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
        }`}
        onClick={handleEdit}
      />
      {isEditing && (
        <CheckCircleIcon
          className={`w-6 h-6 cursor-pointer ${
            theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
          }`}
          onClick={handleSave}
        />
      )}
      {!isEditing && (
        <XMarkIcon
          className={`w-4 h-4 cursor-pointer ${
            theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
          }`}
          onClick={onDelete}
        />
      )}
    </li>
  );
};

export default Item;
