import React from 'react';
// Mobx
import { inject, observer } from 'mobx-react';
// Store
import { useChat } from 'Context/Chat/Chat.context';
import useTemplateModalStore from '../../store';
// Components
import { TrashIcon } from '@heroicons/react/24/outline';
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
import useThemeStore from 'Theme/store';

const DeleteTemplateModal = ({ store }) => {
  const [loading, setLoading] = React.useState(false);
  const { sidebarTools, setSidebarTools } = useChat();
  // Store
  const { deleteTemplateModal, setDeleteTemplateModal, toDelete } =
    useTemplateModalStore();
  // Modal
  const handleClose = () => setDeleteTemplateModal(false);

  const onDelete = async () => {
    setLoading(true);
    try {
      await store.api.post('/tool/delete', {
        tool: toDelete._id,
      });
      store.tools.forEach((x) => {
        if (x._id === toDelete._id) {
          x.in_trash = true;
        }
      });
      setSidebarTools(sidebarTools.filter((x) => x._id !== toDelete._id));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  const { theme } = useThemeStore();

  return (
    <CommonModal
      open={deleteTemplateModal}
      handleClose={handleClose}
      sx={{
        minHeight: '200px',
      }}
    >
      <div className="flex flex-col gap-5">
        <div className="w-full flex justify-center">
          <div
            className={`font-figtree line-clamp-1 font-bold text-[28px] leading-7 ${
              theme == 'dark' ? 'text-crystal-bell' : 'text-raisin-black'
            }`}
          >
            Delete Template
          </div>
        </div>
        <div
          className={`font-figtree w-96 text-base text-center ${
            theme == 'dark' ? 'text-crystal-bell' : 'text-raisin-black'
          }`}
        >
          Are you sure you want to delete this template?
        </div>
        <div className="flex w-full items-center justify-center">
          <button
            className="flex gap-3 items-center justify-center text-[18px] leading-6 font-semibold rounded-xl px-5 py-2 text-white focus:outline-none bg-nue-blue hover:bg-nue-blue/70"
            onClick={onDelete}
          >
            <TrashIcon className="h-[18px] w-[18px]" aria-hidden="true" />
            <span>Delete</span>
          </button>
        </div>
      </div>
    </CommonModal>
  );
};

export default inject('store')(observer(DeleteTemplateModal));
