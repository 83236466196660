function SvgComponent(props) {
  return (
    <svg
      width="119"
      height="28"
      viewBox="0 0 119 28"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.41731 27.9998C6.5305 27.9998 4.86712 27.6967 3.42814 27.0923C1.98917 26.488 0.845471 25.677 0 24.6612L3.51968 21.614C4.26869 22.364 5.11613 22.9142 6.05904 23.2646C7.00196 23.615 7.89664 23.7902 8.74408 23.7902C9.08266 23.7902 9.38483 23.7538 9.65057 23.6809C9.91632 23.6081 10.1407 23.5057 10.3218 23.3729C10.5029 23.24 10.6417 23.0825 10.7392 22.9014C10.8356 22.7203 10.8848 22.5087 10.8848 22.2666C10.8848 21.7833 10.6673 21.4083 10.2313 21.1416C10.0138 21.0205 9.65057 20.8758 9.1427 20.7065C8.63483 20.5372 7.98128 20.3433 7.18306 20.1258C6.04625 19.8355 5.06101 19.491 4.22637 19.0914C3.39173 18.6928 2.70866 18.2272 2.17618 17.6947C1.69193 17.1869 1.31693 16.6248 1.05118 16.0077C0.784447 15.3906 0.652558 14.6957 0.652558 13.9211C0.652558 13.0264 0.864172 12.2154 1.2874 11.49C1.71063 10.7646 2.27854 10.1357 2.9931 9.60321C3.70669 9.07171 4.52853 8.6662 5.46062 8.38766C6.39172 8.1101 7.35333 7.97034 8.34447 7.97034C9.40845 7.97034 10.4242 8.0914 11.3917 8.33353C12.3592 8.57565 13.2598 8.91423 14.0945 9.34927C14.9291 9.78431 15.6722 10.305 16.3258 10.9093L13.2785 14.3197C12.8435 13.9093 12.3533 13.5402 11.809 13.2134C11.2647 12.8867 10.7027 12.6278 10.122 12.4339C9.54132 12.241 9.00884 12.1436 8.52557 12.1436C8.16239 12.1436 7.84152 12.1741 7.56396 12.2341C7.28542 12.2951 7.05018 12.3916 6.85629 12.5245C6.66239 12.6573 6.5177 12.8148 6.42125 12.9959C6.32479 13.177 6.27656 13.3896 6.27656 13.6308C6.27656 13.8719 6.34251 14.1022 6.47637 14.3197C6.60924 14.5373 6.79723 14.7193 7.03837 14.864C7.27951 15.0097 7.66731 15.1721 8.19979 15.3542C8.73128 15.5353 9.45668 15.7597 10.3769 16.0254C11.5138 16.3404 12.4931 16.6908 13.3159 17.0776C14.1378 17.4644 14.7913 17.9241 15.2756 18.4565C15.6624 18.868 15.9527 19.3453 16.1466 19.8896C16.3395 20.4339 16.437 21.0205 16.437 21.6494C16.437 22.8827 16.0925 23.9782 15.4025 24.9329C14.7136 25.8886 13.7638 26.6386 12.5541 27.1819C11.3445 27.7262 9.96553 27.9979 8.41829 27.9979L8.41731 27.9998Z"
        fill="inherit"
      />
      <path
        d="M30.8138 8.37213H27.0776V3.58276H21.2725V8.37213H17.8621V13.4509H21.2725V27.6369H27.0776V13.4509H30.8138V8.37213Z"
        fill="inherit"
      />
      <path
        d="M33.1545 27.637V8.37226H38.6693L38.7884 11.3191C38.936 11.0908 39.0955 10.8713 39.2677 10.6577C39.9331 9.83584 40.7126 9.1823 41.6083 8.69805C42.5029 8.21478 43.4587 7.97266 44.4744 7.97266C44.9094 7.97266 45.3149 8.00907 45.6899 8.08191C46.0649 8.15474 46.4094 8.23939 46.7244 8.33584L45.1279 14.7581C44.8612 14.5888 44.5049 14.45 44.0581 14.3408C43.6102 14.2315 43.1447 14.1774 42.6614 14.1774C42.1289 14.1774 41.6329 14.2679 41.1742 14.449C40.7146 14.6301 40.3278 14.891 40.0128 15.2295C39.6978 15.5681 39.4508 15.9677 39.2687 16.4264C39.0876 16.886 38.9961 17.4057 38.9961 17.9864V27.637H33.1545Z"
        fill="inherit"
      />
      <path
        d="M89.1346 27.9351C87.2714 27.9351 85.6031 27.5001 84.1277 26.629C82.6523 25.758 81.485 24.567 80.6268 23.0552C79.7675 21.5434 79.3384 19.8328 79.3384 17.9214C79.3384 16.0099 79.7675 14.2993 80.6268 12.7875C81.485 11.2757 82.6523 10.0848 84.1277 9.21369C85.6031 8.34263 87.2714 7.90759 89.1346 7.90759C90.8521 7.90759 92.4299 8.21074 93.8689 8.81507C95.3078 9.42039 96.4505 10.2659 97.297 11.3544L94.1405 15.1281C93.8019 14.7412 93.4023 14.379 92.9437 14.0395C92.484 13.7009 91.9761 13.4351 91.42 13.2412C90.863 13.0483 90.2832 12.9509 89.6789 12.9509C88.7596 12.9509 87.9496 13.1625 87.2478 13.5857C86.546 14.009 86.0017 14.5956 85.6149 15.3456C85.2281 16.0956 85.0342 16.9548 85.0342 17.9214C85.0342 18.8879 85.234 19.6753 85.6327 20.4243C86.0323 21.1743 86.5815 21.7668 87.2832 22.2019C87.984 22.6369 88.7823 22.8554 89.6779 22.8554C90.3069 22.8554 90.8876 22.7767 91.4191 22.6192C91.9506 22.4617 92.4407 22.2265 92.8885 21.9115C93.3364 21.5975 93.7527 21.2107 94.1405 20.7501L97.2606 24.5601C96.4378 25.6005 95.295 26.4233 93.8315 27.0276C92.3679 27.632 90.8019 27.9351 89.1327 27.9351H89.1346Z"
        fill="inherit"
      />
      <path
        d="M107.733 27.935C105.725 27.935 103.942 27.4999 102.382 26.6289C100.822 25.7578 99.5885 24.5738 98.681 23.0738C97.7736 21.5748 97.3208 19.8572 97.3208 17.9222C97.3208 15.9872 97.7745 14.2706 98.681 12.7706C99.5875 11.2716 100.822 10.0856 102.382 9.21552C103.942 8.34446 105.725 7.90942 107.733 7.90942C109.741 7.90942 111.525 8.34446 113.085 9.21552C114.645 10.0866 115.872 11.2716 116.767 12.7706C117.661 14.2706 118.109 15.9881 118.109 17.9222C118.109 19.8562 117.661 21.5748 116.767 23.0738C115.872 24.5738 114.645 25.7588 113.085 26.6289C111.525 27.4999 109.74 27.935 107.733 27.935ZM107.733 22.9281C108.604 22.9281 109.378 22.7106 110.055 22.2746C110.732 21.8395 111.265 21.247 111.651 20.497C112.038 19.748 112.219 18.8887 112.196 17.9212C112.219 16.9537 112.038 16.0885 111.651 15.3267C111.265 14.5649 110.732 13.9665 110.055 13.5305C109.378 13.0954 108.604 12.8769 107.733 12.8769C106.862 12.8769 106.083 13.0945 105.393 13.5305C104.704 13.9655 104.165 14.5649 103.778 15.3267C103.392 16.0885 103.21 16.9537 103.234 17.9212C103.21 18.8887 103.392 19.748 103.778 20.497C104.165 21.247 104.704 21.8395 105.393 22.2746C106.082 22.7096 106.862 22.9281 107.733 22.9281Z"
        fill="inherit"
      />
      <path
        d="M76.553 5.15255C77.1701 4.59645 77.4782 3.87106 77.4782 2.97539C77.4782 2.03149 77.175 1.30118 76.5707 0.78051C75.9654 0.260826 75.1189 0 74.0313 0C72.9437 0 72.1199 0.278543 71.492 0.834644C70.863 1.39173 70.5491 2.10433 70.5491 2.97539C70.5491 3.94291 70.8571 4.687 71.4743 5.20668C72.0914 5.72735 72.9437 5.98719 74.0323 5.98719C75.1209 5.98719 75.9369 5.70963 76.554 5.15255H76.553Z"
        fill="inherit"
      />
      <path
        d="M66.8326 26.0249V20.6853H62.8277L66.8326 12.6755V8.37335H61.0639V10.708C60.7155 10.2552 60.3169 9.84579 59.8671 9.48063C59.2864 9.00918 58.6151 8.64008 57.8533 8.37434C57.0915 8.10859 56.2509 7.97473 55.3316 7.97473C53.6624 7.97473 52.1752 8.40485 50.8691 9.26311C49.563 10.1224 48.5344 11.3015 47.7854 12.8005C47.0354 14.3005 46.6604 16.0298 46.6604 17.9885C46.6604 19.9471 47.0354 21.6105 47.7854 23.1223C48.5344 24.6341 49.5452 25.8192 50.8149 26.6775C52.0846 27.5367 53.5295 27.9658 55.1505 27.9658C56.0216 27.9658 56.8435 27.833 57.6181 27.5672C58.3917 27.3015 59.0925 26.9383 59.7224 26.4786C60.3513 26.02 60.8838 25.4875 61.3188 24.8822C61.5797 24.519 61.7972 24.143 61.9714 23.7542C63.3159 26.2611 65.9625 27.9708 69.0009 27.9708V22.7769L66.8346 26.0269L66.8326 26.0249ZM60.5925 20.6903C60.2293 21.4521 59.7214 22.0387 59.0688 22.4501C58.4163 22.8615 57.6417 23.0672 56.747 23.0672C55.8523 23.0672 55.12 22.8615 54.4793 22.4501C53.8385 22.0387 53.3366 21.4521 52.9734 20.6903C52.6102 19.9284 52.4291 19.0279 52.4291 17.9875C52.4291 16.9472 52.6102 16.0466 52.9734 15.2848C53.3366 14.5229 53.8376 13.9304 54.4793 13.5072C55.12 13.084 55.8759 12.8724 56.747 12.8724C57.6181 12.8724 58.4163 13.084 59.0688 13.5072C59.7214 13.9304 60.2303 14.5229 60.5925 15.2848C60.9556 16.0466 61.1368 16.9481 61.1368 17.9875C61.1368 19.0269 60.9556 19.9284 60.5925 20.6903Z"
        fill="inherit"
      />
      <path
        d="M76.934 8.37244H71.1289V10.0053H73.5069L71.5039 14.0102H74.8415L71.1279 19.5801V27.6667C74.4734 26.7198 76.933 23.6411 76.933 19.9964V8.37146L76.934 8.37244Z"
        fill="inherit"
      />
    </svg>
  );
}

export default SvgComponent;
