function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_848_652)">
        <path
          d="M5.521 19.9H10.843L14.362 23.415C14.5511 23.6052 14.776 23.7561 15.0236 23.859C15.2712 23.962 15.5368 24.015 15.805 24.015C15.9815 24.0147 16.1572 23.9922 16.328 23.948C16.6732 23.8609 16.9894 23.6843 17.2447 23.436C17.4999 23.1878 17.6853 22.8767 17.782 22.534L23.989 1.42499L5.521 19.9Z"
          fill="#797779"
        />
        <path
          d="M4.08703 18.5L22.572 0.0120239L1.47803 6.23302C1.13457 6.3307 0.822612 6.51648 0.573111 6.77193C0.323609 7.02739 0.145234 7.34364 0.0556829 7.68931C-0.0338682 8.03498 -0.0314835 8.39806 0.0626007 8.74253C0.156685 9.08699 0.339198 9.40087 0.592034 9.65302L4.08703 13.145V18.5Z"
          fill="#797779"
        />
      </g>
      <defs>
        <clipPath id="clip0_848_652">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
