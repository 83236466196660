import { create } from 'zustand';

const usePublicLayoutStore = create((set) => ({
  registerModal: false,
  openRegisterModal: () => set({ registerModal: true }),
  closeRegisterModal: () =>
    set({ registerModal: false, continueConversation: false }),
  showPromptSidebar: true,
  togglePromptSidebar: () =>
    set((state) => ({ showPromptSidebar: !state.showPromptSidebar })),
  closePromptSidebar: () => set({ showPromptSidebar: false }),
  continueConversation: false,
  toggleContinueConversation: () =>
    set((state) => ({ continueConversation: !state.continueConversation })),
}));

export default usePublicLayoutStore;
