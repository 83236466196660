import { XMarkIcon } from '@heroicons/react/24/outline';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';

import useThemeStore from 'Theme/store';
import QualifyExperience from './QualifyExperience';
import TutorialModal from './TutorialModal';

const DiscordModal = inject('store')(
  observer(({ mode, onInteraction }) => {
    const [openDialogTutorial, setOpenDialogTutorial] = useState(false);
    const [modeDialogTutorial, setModeDialogTutorial] = useState(false);

    const [openDialogQualifyExperience, setOpenDialogQualifyExperience] =
      useState(false);
    const [modeDialogQualifyExperience, setModeDialogQualifyExperience] =
      useState('review_basic');

    const onDialogTutorial = (mode) => {
      setOpenDialogTutorial(true);
      setModeDialogTutorial(mode);
    };

    const onDialogFeedback = () => {
      setOpenDialogQualifyExperience(true);
    };

    const { theme } = useThemeStore();

    return (
      <>
        <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50 font-figtree">
          <div className="fixed inset-0 transition-opacity">
            <div
              className="absolute inset-0 bg-raisin-black opacity-75"
              onClick={() => {
                onInteraction('close');
              }}
            ></div>
          </div>

          <div
            className={`relative ${
              theme == 'dark' ? 'bg-lead' : 'bg-seasalt'
            } rounded-lg overflow-auto shadow-xl transform transition-all sm:max-w-lg sm:w-full max-h-full flex flex-col`}
          >
            <div className="px-6 overflow-auto relative dark:text-crystal-bell text-raisin-black">
              <button
                onClick={() => {
                  onInteraction('close');
                }}
                className="absolute right-1 top-2 p-1 hover:bg-lavender dark:hover:bg-ship-grey text-sm rounded-md"
              >
                <XMarkIcon className="w-5 h-5" />
              </button>

              <div className="dialog-content-container overflow-auto pr-2 mt-8">
                <div className="font-medium text-xl text-center">
                  👋 Welcome to Straico - Where Creativity Meets AI! 🚀
                </div>
                <div className="font-normal mt-8 text-justify">
                  Join our vibrant community on Discord and enjoy{' '}
                  <span className="font-bold">with us</span> at Straico!
                </div>
                <br></br>
                <div className="font-normal text-justify">
                  Click the Discord button below to connect with like-minded
                  individuals, provide valuable feedback, request features, and
                  stay updated on our roadmap.
                </div>
                <br></br>
                <div className="font-normal text-justify">
                  Together, let's explore the possibilities of Generative AI and
                  unleash your creativity and don't miss out.
                </div>
                <br></br>
                <div className="font-normal text-center">Join us now! ✨</div>
                <br></br>
              </div>
            </div>
            <div className="flex justify-center w-full pb-6">
              <a
                href="https://discord.gg/2UwWGWnahE"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                <button className="flex items-center justify-center px-4 py-4 rounded-md hover:bg-lavender dark:hover:bg-ship-grey text-raisin-black text-sm border border-violet-blue">
                  <img className="h-7 px-1" src="/svg/discord-logo-blue.svg" />
                </button>
              </a>
            </div>
          </div>
        </div>

        {openDialogTutorial ? (
          <TutorialModal
            mode={modeDialogTutorial}
            onInteraction={onInteraction}
          ></TutorialModal>
        ) : (
          <></>
        )}

        {openDialogQualifyExperience ? (
          <QualifyExperience
            modal={modeDialogQualifyExperience}
            onInteraction={onInteraction}
          ></QualifyExperience>
        ) : (
          <></>
        )}
      </>
    );
  })
);

export default DiscordModal;
