import { ArrowLeftIcon } from '@heroicons/react/24/solid';

const BackButton = ({ text, onClick }) => {
  return (
    <button
      className="flex items-center space-x-1 py-2 text-nue-blue font-semibold font-figtree"
      onClick={onClick}
    >
      <ArrowLeftIcon className="text-nue-blue size-4" />
      <span className="underline text-sm">{text}</span>
    </button>
  );
};

export default BackButton;
