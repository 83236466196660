import { useEffect, useState } from 'react';
// MUI
import { Button } from '@mui/material';
// DND
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
// RHF
import { useFormContext } from 'react-hook-form';
// Icons
import { PlusIcon } from '@heroicons/react/24/solid';
// Store
import useCreateTemplatesStore from 'Features/CreateTemplatesForm/Store';
// Components
import { toCamelCase } from 'Features/CreateTemplatesForm/Utils/functions';
import useThemeStore from 'Theme/store';
import { toast } from 'react-hot-toast';
import CommonModal from '../CommonModal/CommonModal';
import Item from './Item';

const ModalDropdownVariable = () => {
  const {
    dropdownModal,
    setDropdownModal,
    dropdownItems,
    setDropdownItems,
    editDropdownModal,
    setEditDropdownModal,
  } = useCreateTemplatesStore();
  const handleClose = () => setDropdownModal(false);
  // Form
  const [localName, setLocalName] = useState('');
  const handleChangeName = (e) => {
    setLocalName(e.target.value);
  };
  const { watch, setValue } = useFormContext();
  const handleSubmit = () => {
    if (!editDropdownModal.edit) {
      if (
        watch('promptVariables').filter(
          (i) => i.variable === toCamelCase(localName)
        ).length > 0
      ) {
        toast.error(
          'Variable name already exists, please use a different name.',
          {
            icon: '🚨',
          }
        );
        return;
      }
    }
    if (dropdownItems.length > 1 && localName !== '') {
      if (editDropdownModal.edit) {
        const newVariables = watch('promptVariables').map((i) => {
          if (i.variable === editDropdownModal.defaultData.variable) {
            return {
              name: localName,
              type: 'dropdown',
              options: dropdownItems,
              variable: toCamelCase(localName),
            };
          }
          return i;
        });
        setValue('promptVariables', newVariables);
        handleClose();
        setValue(
          'prompt',
          `${watch('prompt').replace(
            editDropdownModal.defaultData.variable,
            `${toCamelCase(localName)}`
          )}`
        );
        return;
      }

      setValue('promptVariables', [
        ...watch('promptVariables'),
        {
          name: localName,
          type: 'dropdown',
          options: dropdownItems,
          variable: toCamelCase(localName),
        },
      ]);
      handleClose();
      setValue(
        'prompt',
        `${watch('prompt') ?? ''} {{${toCamelCase(localName)}}}`
      );
    } else {
      toast.error(
        'To add a dropdown variable, either include options or specify the variable name.',
        {
          icon: '🚨',
        }
      );
    }
  };
  // Drag and drop
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      const activeIndex = dropdownItems.findIndex(
        (item) => item.id === active?.id
      );
      const overIndex = dropdownItems.findIndex((item) => item.id === over?.id);
      setDropdownItems(arrayMove(dropdownItems, activeIndex, overIndex));
    }
  }

  const handleDelete = (id) => () => {
    setDropdownItems(dropdownItems.filter((i) => i.id !== id));
  };

  useEffect(() => {
    setDropdownItems([]);
    setLocalName('');
    if (!dropdownModal) {
      setEditDropdownModal({
        edit: false,
        defaultData: {},
      });
    }
  }, [dropdownModal]);

  useEffect(() => {
    if (editDropdownModal.edit) {
      setLocalName(editDropdownModal.defaultData.name);
      setDropdownItems(editDropdownModal.defaultData.options);
    }
  }, [editDropdownModal]);

  const handleAddTodo = () => {
    const optionCount = dropdownItems.length + 1;

    setDropdownItems([
      ...dropdownItems,
      {
        id: optionCount,
        title: `Option ${optionCount}`,
      },
    ]);
  };

  const { theme } = useThemeStore();

  return (
    <>
      <CommonModal open={dropdownModal} handleClose={handleClose}>
        <p
          className={`font-lexend line-clamp-1 font-bold uppercase text-md ${
            theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
          }`}
        >
          Dropdown Menu
        </p>
        <div className="flex flex-col gap-6 pt-6">
          <div className="flex flex-col gap-2">
            <label
              className={`uppercase text-[12px] font-medium font-lexend ${
                theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
              }`}
            >
              Variable name *
            </label>
            <input
              value={localName}
              onChange={handleChangeName}
              className={`w-full border border-snow-900 border-solid text-sm p-[8px] font-barlow rounded-md  ${
                theme == 'dark'
                  ? 'dark:bg-graphite-900 dark:text-white'
                  : 'bg-snow-100 text-graphite-900'
              }`}
              placeholder="Name of your variable"
            />
          </div>
          <div>
            <label
              className={`uppercase text-[12px] font-medium font-lexend ${
                theme == 'dark'
                  ? 'dark:bg-graphite-900 dark:text-white'
                  : 'bg-snow-100 text-graphite-900'
              }`}
            >
              Options *
            </label>
            <div className="py-1 max-h-[300px] overflow-y-auto overflow-x-hidden gap-3">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={dropdownItems}
                  strategy={verticalListSortingStrategy}
                >
                  <ul>
                    {dropdownItems.map((item) => (
                      <>
                        <Item
                          onDelete={handleDelete(item.id)}
                          onUpdate={(updatedItem) => {
                            setDropdownItems(
                              dropdownItems.map((t) =>
                                t.id === updatedItem.id ? updatedItem : t
                              )
                            );
                          }}
                          {...item}
                        />
                      </>
                    ))}
                  </ul>
                </SortableContext>
              </DndContext>
            </div>
            <div
              onClick={handleAddTodo}
              className={`flex border-spacing-2 border cursor-pointer   items-center gap-2 px-4 py-2 border-dashed border-snow-900 rounded-lg  ${
                theme == 'dark'
                  ? 'dark:bg-graphite-900 dark:hover:bg-graphite-700 dark:text-white'
                  : 'bg-snow-400 hover:bg-snow-700 text-gray-900'
              }`}
            >
              <PlusIcon
                className={`w-4 h-4 cursor-pointer ${
                  theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
                }`}
              />
              <span
                className={`text-sm font-medium font-barlow ${
                  theme == 'dark' ? ' dark:text-white' : ' text-graphite-900'
                }`}
              >
                Add option
              </span>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end mt-6">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSubmit}
          >
            <span className="font-lexend font-bold">Save</span>
          </Button>
        </div>
      </CommonModal>
    </>
  );
};

export default ModalDropdownVariable;
