import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
// Components
import MainBody from '../Components/Body';
import Subheader from '../Components/Subheader';
// icons
import {
  ArrowDownIcon,
  ArrowPathIcon,
  ArrowUpIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';
import { Button } from '@mui/material';
import useThemeStore from 'Theme/store';

const Transactions = inject('store')(
  observer(({ store }) => {
    const [loadingBar, setLoadingBar] = React.useState(false);
    const [transactions, setTransactions] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [hasMore, setHasMore] = React.useState(true);

    const getTransactions = async (page) => {
      setLoadingBar(true);
      try {
        const response = await store.api.get('/transaction', {
          params: { page: page },
        });
        if (response.data.success) {
          setTransactions([
            ...transactions,
            ...(response.data.transactions || []),
          ]);
          setHasMore(response.data.pagination.next);
        }
        // eslint-disable-next-line no-unreachable
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setLoadingBar(false);
      }
    };

    const onLoadMore = () => {
      setPage(page + 1);
    };

    useEffect(() => {
      getTransactions(page);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
      <>
        <Helmet>
          <title>{`Transactions - Straico`}</title>
        </Helmet>
        <Subheader title="Transactions" icon="ArrowsRightLeftIcon" />
        <MainBody className="h-full overflow-auto">
          <div className="flex w-full flex-col gap-4 px-4 md:px-28 h-full pt-5 font-figtree">
            <div
              id="scrollableDiv"
              className="h-full transition-all ease-in duration-500 py-2"
            >
              <div className="flex gap-3 flex-col mt-3">
                {transactions.map((transaction, index) => (
                  <TransactionCard transaction={transaction} key={index} />
                ))}
                {loadingBar && (
                  <div className="flex gap-3 flex-col mt-3">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => (
                      <SkeletonCard key={index} />
                    ))}
                  </div>
                )}
                <Button
                  startIcon={<ArrowPathIcon className="size-4" />}
                  onClick={onLoadMore}
                  disabled={!hasMore}
                  variant="outlined"
                  color="primary"
                  sx={{
                    mb: 3,
                  }}
                >
                  Load More
                </Button>
              </div>
            </div>
          </div>
        </MainBody>
      </>
    );
  })
);

const TransactionCard = inject('store')(
  observer(({ transaction }) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };

    const { theme: themeValue } = useThemeStore();

    return (
      <div
        className={`w-full transition duration-500 transform flex flex-col md:flex-row justify-between items-center ${
          themeValue == 'dark' ? 'bg-umbra' : 'bg-white'
        } rounded-md p-4 gap-6`}
      >
        {/* Left column */}
        <div className="flex flex-col justify-center items-center">
          <div className="flex justify-center items-center w-14 h-14 mx-3">
            {transaction?.operation_type === 'add' ? (
              <ArrowUpIcon className="text-violet-blue" />
            ) : (
              <ArrowDownIcon className="text-violet-blue" />
            )}
          </div>
          <div className="flex flex-col justify-center items-center mt-1">
            <div
              className={`flex text-battleship-gray dark:text-crystal-bell text-base font-light tracking-wide`}
            >
              <span className="text-sm font-semibold">
                {transaction?.operation_type == 'add' ? '+' : '-'}
                {transaction?.coins != null
                  ? transaction?.coins?.toLocaleString('en-US')
                  : ''}
              </span>
              <CurrencyDollarIcon className="w-5 h-5 text-violet-blue" />
            </div>
          </div>
        </div>

        {/* Right column */}
        <div className="flex flex-col h-full gap-4 w-full">
          <div className="flex flex-row">
            <div className="w-4/12">
              <div
                className={`flex justify-start items-center font-bold uppercase tracking-wide text-base text-raisin-black dark:text-crystal-bell leading-none mt-1`}
              >
                {transaction?.type}
              </div>
            </div>
            <div className="w-8/12">
              <div
                className={`flex justify-end text-battleship-gray dark:text-crystal-bell text-xs font-light tracking-wide`}
              >
                ID: {transaction?.hash_id}
              </div>
            </div>
          </div>
          <p className="mt-1 pr-1 font-bold uppercase tracking-wide text-sm line-clamp text-raisin-black dark:text-crystal-bell">
            {transaction?.title}
          </p>
          <p className="-mt-4 pr-1 text-base font-semibold line-clamp dark:text-crystal-bell">
            {transaction?.postitle}
          </p>
          <p className="-mt-4 pr-1 text-base line-clamp dark:text-crystal-bell">
            {transaction?.subtitle}
          </p>
          <p className="-mt-2 pr-1 text-sm line-clamp text-right text-battleship-gray dark:text-crystal-bell">
            {transaction?.date
              ? new Date(transaction?.date)?.toLocaleString('en-US', options)
              : ''}
          </p>
        </div>
      </div>
    );
  })
);

const SkeletonCard = () => {
  const { theme: themeValue } = useThemeStore();

  return (
    <div
      className={`w-md flex w-full items-center justify-between flex-row ${
        themeValue == 'dark' ? 'bg-umbra' : 'bg-white'
      } rounded-lg overflow-hidden`}
    >
      <div className="bg-ghost-white dark:bg-night-black h-32 w-32 animate-pulse"></div>
      <div className="px-4 py-2 w-full">
        <div className="bg-ghost-white dark:bg-night-black h-4 w-1/2 mb-2 animate-pulse"></div>
        <div className="bg-ghost-white dark:bg-night-black h-4 w-1/3 animate-pulse"></div>
      </div>
    </div>
  );
};

export default Transactions;
