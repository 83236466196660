import { useEffect, useRef } from 'react';
// State
import useTextAreaFocus from '../../Chat/TextAreaHightlight/state';
import useThemeStore from 'Theme/store';
// Styles
import { cn } from 'utils/styles';
// Components
import Editor from 'react-simple-code-editor';

const TextAreaEditMessage = ({
  value,
  onChange,
  highlightWords,
  autoFocus,
  disabled,
  onKeyDown,
  maxRows,
  minRows,
  placeholder = `Chat here or type '/' for prompt templates`,
  classNameContainer,
  classNameMainContainer,
  children,
  withoutMaxHeight,
}) => {
  const mainContainerRef = useRef(null);
  const containerRef = useRef(null);

  function escapeHTML(str) {
    const map = {
      '<': '&lt;',
      '>': '&gt;',
      '&': '&amp;',
      '"': '&quot;',
      "'": '&apos;',
    };

    // eslint-disable-next-line no-useless-escape
    return str.replace(/[\<\>\&\"\']/g, (match) => map[match]);
  }

  // !!IMPORTANT Be careful with this function, it affect the styles of prompt templates
  const highlight = (code) => {
    let highlightedCode = code;

    highlightedCode = highlightedCode.replace(/<[^>]+>/g, (match) =>
      escapeHTML(match)
    );

    highlightWords.forEach((highlightConfig) => {
      const { highlight, className, prefix, suffix } = highlightConfig;
      if (highlight && className) {
        highlightedCode = highlightedCode.replace(
          highlight,
          `<span class="${className}">${prefix ?? ''}$1${suffix ?? ''}</span>`
        );
      }
    });

    return highlightedCode;
  };

  // Focus management
  const { focusRequest, blur } = useTextAreaFocus();
  function focusEditor() {
    containerRef.current._input.focus();
    blur();
  }

  useEffect(() => {
    if (autoFocus || focusRequest) focusEditor();
  }, [autoFocus, focusRequest]);

  // Adjust container height
  useEffect(() => {
    adjustContainerHeight();
  }, [value, highlightWords, minRows, maxRows]);

  const handleChange = (e) => {
    onChange(e);
    adjustContainerHeight();
  };

  const adjustContainerHeight = () => {
    const textareaRef = containerRef.current._input;
    const textareaContainerRef = mainContainerRef.current;
    if (textareaRef) {
      const textareaHeight = textareaRef.scrollHeight + 30;
      const minContainerHeight = minRows
        ? minRows * parseInt(window.getComputedStyle(textareaRef).lineHeight)
        : null;
      const maxContainerHeight = maxRows
        ? maxRows * parseInt(window.getComputedStyle(textareaRef).lineHeight)
        : null;

      textareaContainerRef.style.height = 'auto';
      textareaContainerRef.style.height = `${Math.min(
        Math.max(textareaHeight, minContainerHeight),
        maxContainerHeight
      )}px`;
      if (textareaHeight > maxContainerHeight) {
        textareaContainerRef.style.overflowY = 'scroll';
      } else {
        textareaContainerRef.style.overflowY = 'hidden';
      }
    }
  };

  // Set spellcheck and other attributes on each input instance
  useEffect(() => {
    const inputElements = mainContainerRef?.current?.querySelectorAll(
      '.npm__react-simple-code-editor__textarea'
    );

    if (inputElements) {
      for (const inputElement of inputElements) {
        inputElement.autocomplete = 'on';
        inputElement.autocorrect = 'on';
        inputElement.spellcheck = true;
      }
    }
  }, []);

  const { theme: themeValue } = useThemeStore();

  return (
    <div
      ref={mainContainerRef}
      className={cn(
        `rounded !h-[inherit] notranslate ${
          themeValue == 'dark' ? 'dark:bg-black' : 'bg-white'
        }   dark:text-crystal-bell border mt-2 border-platinum focus:border-violet-blue dark:border-quicksilver`,
        {
          'max-h-[10.5rem]': !withoutMaxHeight,
        },
        classNameMainContainer
      )}
    >
      {children}
      <Editor
        ref={containerRef}
        value={value}
        onValueChange={handleChange}
        padding={10}
        className={cn(
          `font-figtree notranslate text-sm shadow-sm px-4 py-3 rounded min-w-full font-regular  ${
            themeValue == 'dark'
              ? 'dark:bg-black dark:text-crystal-bell'
              : 'bg-white text-raisin-black'
          }`,
          classNameContainer
        )}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        autoFocus={autoFocus}
        disabled={disabled}
        highlight={highlight}
        textareaClassName="bg-transparent w-full h-full"
        autoCorrect="on"
        autoComplete="on"
      />
    </div>
  );
};

export default TextAreaEditMessage;
