import { Button } from '@mui/material';
import Modal from 'Components/Common/Modal/Modal';
import useComingSoonModal from './store';

const ComingSoonModal = () => {
  const { open, closeModal } = useComingSoonModal();
  return (
    <Modal open={open} onClose={closeModal}>
      <div className="w-full font-figtree">
        <div className="max-w-[400px] mx-auto">
          <div className="mb-8">
            <h1 className="mb-4 text-3xl font-extrabold text-raisin-black text-center">
              Coming Soon
            </h1>
            <p className="text-cool-gray">
              Stay tuned for something amazing! Our exciting new feature is
              coming soon. We can't wait to share it with you. Keep an eye on
              this space for updates.
            </p>
          </div>
          <div className="space-y-4 flex flex-col text-center">
            <Button
              variant="contained"
              color="secondary"
              className="p-3 bg-seasalt border rounded w-full font-semibold"
              onClick={closeModal}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ComingSoonModal;
