import React, { useEffect, useMemo, useState } from 'react';
// RHF
import { useFormContext } from 'react-hook-form';
// MUI
import { Button, IconButton, Slide, Tooltip } from '@mui/material';
// Icons
import { ArrowsPointingOutIcon, XMarkIcon } from '@heroicons/react/20/solid';
// Context
import { useChat } from 'Context/Chat/Chat.context';
import useCreateTemplatesStore from 'Features/CreateTemplatesForm/Store';
// Theme
import useThemeStore from 'Theme/store';
// Components
import SwitchInput from '@straico-ui/forms/SwitchInput';
import { TextareaRender } from 'Components/Chat/TemplateModal/components/ViewPromptSection/ViewPromptSection';
import FullScreenModal from 'Components/Common/FullScreenModal';
import { Link } from 'react-router-dom';

const SectionReview = ({ models, userData }) => {
  const { edit, setOpenModal } = useCreateTemplatesStore();
  const { watch, register, setValue } = useFormContext();

  const variables = watch('variables');
  const title = watch('name');
  const files = watch('files');
  const isPublic = watch('is_public');
  // TODO vamos a revisar lo de los mdoelos
  const selectedModelId = watch('default_model');

  const selectedModelsId = watch('multi_default_models');

  const selectedModel = useMemo(
    () => models.find((model) => model.id === selectedModelId),
    [selectedModelId, models]
  );

  const selectedModels = useMemo(
    () => models.filter((model) => selectedModelsId.includes(model.id)),
    [selectedModelsId, models]
  );

  // Folders
  const { templatesFolders } = useChat();

  const ownFolderUser = useMemo(
    () => templatesFolders.filter((f) => Boolean(f?.creator) === true),
    [templatesFolders]
  );

  useEffect(() => {
    if (!edit) {
      setValue('location', ownFolderUser[0]?._id);
    }
  }, [ownFolderUser]);
  // View full screen modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { theme } = useThemeStore();

  const onCheckIsPublic = (value) => {
    setValue('is_public', value.target.checked);
  };

  return (
    <div className="flex flex-col h-full">
      <p className={`text-xl font-bold`}>
        Step 3: Review and save your prompt template
      </p>
      <div className="py-7">
        <p className="uppercase text-battleship-gray dark:text-crystal-bell font-bold">
          {title ?? 'N/A'}
        </p>
        {/* TODO hacer el UI de los Modelos */}
        <div>
          {selectedModels && selectedModels.length > 0 ? (
            selectedModels.map((model) => (
              <p
                key={model.id}
                className="px-2 py-1 mt-2 bg-violet-blue text-white rounded-md w-fit text-xs font-bold"
              >
                {model?.title ?? 'N/A'}
              </p>
            ))
          ) : (
            <p className="px-2 py-1 mt-2 bg-violet-blue text-white rounded-md w-fit text-xs font-bold">
              {selectedModel?.title ?? 'N/A'}
            </p>
          )}
        </div>
      </div>
      <div className="flex h-full flex-col gap-2 max-h-[60vh]">
        <div className="flex flex-1 h-full flex-col gap-2 sha">
          <TextareaRender
            textareaProps={{
              classNameMainContainer: `max-h-full ${
                theme == 'dark' ? 'dark:bg-night-black' : 'bg-white'
              }`,
              classNameContainer: `shadow-none resize-none outline-none focus:outline-none text-md rounded min-w-full text-sm ${
                theme == 'dark'
                  ? 'dark:bg-night-black dark:text-crystal-bell'
                  : 'bg-white text-raisin-black'
              }`,
            }}
            inputValue={watch('prompt') ?? ''}
            highligthingWords={[
              {
                highlight: /{{([\w\s.,?!;-]*)}}/g,
                className:
                  'bg-ghost-white text-cool-gray rounded-md py-1 px-2 font-semibold',
              },
            ]}
            filesForm={variables.filter((i) =>
              ['attachment', 'image'].includes(i.type)
            )}
            defaultFiles={files}
            handleSeeFile={(file) => {
              window.open(file.url, '_blank');
            }}
          />
        </div>
        <div className="flex justify-end items-end">
          <Button
            sx={{
              textTransform: 'initial',
              color: (themeMui) =>
                theme === 'dark' ? '#fff' : themeMui.palette.primary.main,
            }}
            onClick={handleOpen}
            startIcon={<ArrowsPointingOutIcon className="w-4 h-4" />}
          >
            Full screen
          </Button>
        </div>
        <FullScreenModal
          open={open}
          onClose={handleClose}
          transition={Transition}
        >
          <div className="h-full w-full overflow-hidden">
            <div
              className="border-platinum border-solid bg-violet-blue h-[3px] w-full"
              content=" "
            />
            <div className="flex flex-col justify-center items-center h-full">
              <div
                className={`uppercase mb-2 text-xl font-bold flex justify-between px-4 w-full ${
                  theme == 'dark'
                    ? 'dark:text-crystal-bell'
                    : 'text-raisin-black'
                }`}
              >
                <p>{title}</p>
                <IconButton onClick={handleClose}>
                  <XMarkIcon className="w-7 h-7" />
                </IconButton>
              </div>
              <div className="h-[85%] px-4 py-8 w-full">
                <TextareaRender
                  textareaProps={{
                    classNameMainContainer: `max-h-full ${
                      theme == 'dark' ? 'dark:bg-night-black' : 'bg-white'
                    }`,
                    classNameContainer: `shadow-none resize-none outline-none focus:outline-none text-md rounded min-w-full text-sm ${
                      theme == 'dark'
                        ? 'dark:bg-night-black dark:text-crystal-bell'
                        : 'bg-white text-raisin-black'
                    }`,
                  }}
                  inputValue={watch('prompt') ?? ''}
                  highligthingWords={[
                    {
                      highlight: /{{([\w\s.,?!;-]*)}}/g,
                      className:
                        'bg-ghost-white text-cool-gray rounded-md py-1 px-2 font-semibold',
                    },
                  ]}
                  filesForm={variables.filter((i) =>
                    ['attachment', 'image'].includes(i.type)
                  )}
                  defaultFiles={files}
                  handleSeeFile={(file) => {
                    window.open(file.url, '_blank');
                  }}
                />
              </div>
            </div>
          </div>
        </FullScreenModal>
        <div className="flex flex-col gap-2">
          <p className="uppercase text-sm font-bold">Location</p>
          <select
            {...register('location')}
            className={`w-full border border-platinum text-sm border-solid p-2 rounded-md ${
              theme == 'dark'
                ? 'bg-lead text-crystal-bell'
                : 'bg-seasalt text-raisin-black'
            }`}
          >
            {ownFolderUser.map((folder) => (
              <option value={folder?._id} key={folder?._id}>
                {folder?.name}
              </option>
            ))}
          </select>
        </div>
        <Tooltip
          arrow
          placement="top"
          title={
            !userData?.hero_alias && !userData?.catch_phrase ? (
              <div className="flex flex-col text-left font-[600] p-2 gap-1">
                <p>
                  If you want to make public your templates first complete your
                  profile
                </p>
                <Button
                  to="/my-profile/edit"
                  variant="filled"
                  size="small"
                  sx={{
                    p: 0,
                  }}
                  onClick={() => setOpenModal(false)}
                  component={Link}
                  className="text-nue-blue dark:text-white font-[800]"
                >
                  Go to complete
                </Button>
              </div>
            ) : undefined
          }
        >
          <div className="mt-5">
            <div className="flex gap-3 items-center">
              <p className="uppercase text-sm font-bold">
                Make Template Public
              </p>
              <SwitchInput
                disabled={!userData?.hero_alias && !userData?.catch_phrase}
                checked={isPublic}
                onChange={onCheckIsPublic}
              />
            </div>
            <p className="text-xs text-cool-gray dark:text-crystal-bell  mt-4">
              Enabling this will make your template visible to all users. You
              can configure SEO settings for better discoverability.
            </p>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default SectionReview;
