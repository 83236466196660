function SvgComponent({ fillColor = '#76B900', ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="8" fill={fillColor} />
      <g clipPath="url(#clip0_12624_2222)">
        <path
          d="M9.71256 9.66065V8.55426C9.81853 8.54743 9.92449 8.5406 10.0305 8.5406C12.9711 8.44498 14.8984 11.1495 14.8984 11.1495C14.8984 11.1495 12.8188 14.1272 10.5868 14.1272C10.2888 14.1272 9.99735 14.0794 9.71918 13.9838V10.6236C10.865 10.767 11.0968 11.2861 11.7789 12.4676L13.3089 11.1427C13.3089 11.1427 12.1896 9.63333 10.3086 9.63333C10.1099 9.6265 9.91125 9.64016 9.71256 9.66065ZM9.71256 6V7.65275L10.0305 7.63227C14.1169 7.48885 16.786 11.088 16.786 11.088C16.786 11.088 13.7261 14.9262 10.5404 14.9262C10.2623 14.9262 9.99073 14.8989 9.71918 14.8511V15.8756C9.94436 15.9029 10.1762 15.9234 10.4014 15.9234C13.3685 15.9234 15.5143 14.3594 17.594 12.5154C17.9384 12.8022 19.3491 13.492 19.6405 13.7925C17.6668 15.4999 13.0638 16.8727 10.4543 16.8727C10.2027 16.8727 9.96423 16.859 9.7258 16.8317V18.2727H20.9982V6L9.71256 6ZM9.71256 13.9838V14.8579C6.97062 14.3526 6.20897 11.409 6.20897 11.409C6.20897 11.409 7.52695 9.90651 9.71256 9.66065V10.6168H9.70593C8.56015 10.4734 7.65942 11.5798 7.65942 11.5798C7.65942 11.5798 8.16939 13.4442 9.71256 13.9838ZM4.84462 11.2861C4.84462 11.2861 6.46727 8.81378 9.71918 8.55426V7.65275C6.11625 7.95326 3.00342 11.0949 3.00342 11.0949C3.00342 11.0949 4.76515 16.3536 9.71256 16.8317V15.8756C6.08313 15.4111 4.84462 11.2861 4.84462 11.2861Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_12624_2222">
          <rect
            width="18"
            height="12.2727"
            fill="white"
            transform="translate(3 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
