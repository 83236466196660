function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_848_702)">
        <path d="M1.34444 9.93332L-2 18.3778C-2 18.4111 -1.15556 18.4444 -0.122222 18.4444H1.75556L2.15556 17.4222L2.82222 15.6667L3.08889 14.9444L6.58889 14.9111L10.0889 14.8889L10.4222 15.7444L11.1111 17.5222L11.4667 18.4444H13.3444C14.3778 18.4444 15.2222 18.4222 15.2222 18.4C15.2222 18.3778 14.2778 15.9889 13.1222 13.0889L9.77778 4.68887L8.53333 1.55554H6.61111H4.68889L1.34444 9.93332ZM7.71111 8.77776L8.77778 11.5778C8.77778 11.6333 7.8 11.6667 6.61111 11.6667C5.42222 11.6667 4.44444 11.6333 4.44444 11.5778C4.44444 11.5333 4.82222 10.5222 5.28889 9.33332L6.35555 6.57776C6.47778 6.25554 6.58889 5.99999 6.61111 6.02221C6.63333 6.04443 7.13333 7.27776 7.71111 8.77776ZM11.6667 1.68888C11.6667 1.76665 13.1556 5.55554 14.9778 10.1111L18.2778 18.3889L20.1556 18.4222C21.2556 18.4333 22 18.4111 21.9778 18.3444C21.9667 18.2889 20.4444 14.4889 18.6222 9.89999L15.2889 1.55554H13.4778C12.1 1.55554 11.6667 1.58888 11.6667 1.68888Z" />
      </g>
      <defs>
        <clipPath id="clip0_848_702">
          <rect width="20.1111" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
