import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'; // Assuming icons from heroicons
import { useEffect, useRef, useState } from 'react';
import useThemeStore from 'Theme/store';
import TemplateFav from './TemplateFav';
import TemplateFavDummy from './TemplateFavDummy';

const ScrollableToolsSection = ({
  title,
  tools,
  isSmallMobile,
  subTitle,
  dummy = false,
}) => {
  const scrollContainer = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const { theme: themeValue } = useThemeStore();

  const checkScrollButtons = () => {
    const container = scrollContainer.current;
    if (!container) return;
    const isScrollable = container.scrollWidth > container.clientWidth;
    setShowLeftArrow(container.scrollLeft > 0);
    setShowRightArrow(
      container.scrollLeft < container.scrollWidth - container.clientWidth &&
        isScrollable
    );
  };

  const scrollLeft = () => {
    scrollContainer.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollContainer.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  useEffect(() => {
    const container = scrollContainer.current;
    container.addEventListener('scroll', checkScrollButtons);
    return () => container.removeEventListener('scroll', checkScrollButtons);
  }, []);

  useEffect(() => {
    checkScrollButtons(); // Run on component mount and whenever the `tools` array length changes
  }, [tools]);

  return (
    <div className="font-figtree">
      <h2
        className={`text-xl font-bold leading-[30px] ${
          themeValue === 'dark' ? 'text-white' : 'text-black'
        }`}
      >
        {title}
      </h2>
      <p
        className={`text-base ${
          themeValue === 'dark' ? 'text-white' : 'text-black'
        }`}
      >
        {subTitle}
      </p>
      <div className="flex items-center justify-between relative">
        {showLeftArrow && (
          <button
            onClick={scrollLeft}
            className={`z-20 flex justify-center items-center w-6 h-6 absolute right-full mr-3 ${
              themeValue === 'dark' ? 'bg-lead' : 'bg-seasalt'
            }`}
            aria-label="Scroll Left"
          >
            <ChevronLeftIcon className="w-6 h-6 text-battleship-gray" />
          </button>
        )}

        <div
          className="flex overflow-x-scroll scrollbar-hide w-full"
          ref={scrollContainer}
        >
          <section className="flex space-x-4">
            {tools.length > 0 ? (
              tools.map((tool, index) =>
                dummy ? (
                  <TemplateFavDummy
                    key={index}
                    tool={tool}
                    isSmallMobile={isSmallMobile}
                  />
                ) : (
                  <TemplateFav
                    key={index}
                    tool={tool}
                    isSmallMobile={isSmallMobile}
                  />
                )
              )
            ) : (
              <TemplateFavSkeleton />
            )}
          </section>
        </div>

        {showRightArrow && (
          <button
            onClick={scrollRight}
            className={`z-20 flex justify-center items-center w-6 h-6 absolute left-full ml-3 ${
              themeValue === 'dark' ? 'bg-lead' : 'bg-seasalt'
            }`}
            aria-label="Scroll Right"
          >
            <ChevronRightIcon className="w-6 h-6 text-battleship-gray" />
          </button>
        )}
        <style>
          {`
          .scrollbar-hide::-webkit-scrollbar {
            display: none; /* For Chrome, Safari, and Opera */
          }
        `}
        </style>
      </div>
    </div>
  );
};

export default ScrollableToolsSection;

export const TemplateFavSkeleton = () => {
  return (
    <div
      className={`
        animate-pulse /* Adds a pulsing effect */
        ${useThemeStore().theme === 'dark' ? 'bg-black' : 'bg-white'}
        font-figtree rounded-[14px] w-[260px] p-3 my-3 shadow-[4px_4px_7.6px_0px_rgba(0,0,0,0.10)]
      `}
    >
      <div className="flex items-center space-x-2">
        {/* Ensure dimensions and rounded-full are correctly applied for a circle */}
        <div className="h-4 bg-platinum rounded w-3/4"></div>
      </div>
      <div className="flex items-center space-x-2 mt-4">
        {/* This is the circle div; ensure it has a square aspect ratio for a perfect circle shape */}
        <div className="w-8 h-8">
          <div className="rounded-full bg-platinum w-8 h-8"></div>
        </div>
        <div className="h-4 bg-platinum rounded w-full"></div>
      </div>
      <div className="mt-2">
        <div className="flex mt-2 space-x-2 align-middle items-center">
          <div className="flex space-x-1 items-center">
            <div className="h-4 bg-platinum rounded w-32"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
