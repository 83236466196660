/* eslint-disable no-console */
import { SparklesIcon } from '@heroicons/react/24/outline';
import { LoadingButton } from '@mui/lab';
import TextAreaInput from '@straico-ui/forms/TextAreaInput';
import TextInput from '@straico-ui/forms/TextInput';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const SectionSeo = ({ store }) => {
  const { register, watch, setValue } = useFormContext();
  const { theme } = useThemeStore();

  // Generate SEO with AI
  const [loading, setLoading] = useState(false);
  const onGenerateSeoWithIA = async () => {
    setLoading(true);
    try {
      const res = await store.api.post('/v2/templates/seo/generate', {
        title: watch('name'),
        description: watch('description'),
        category: watch('category') ?? '',
        prompt: watch('prompt'),
      });
      const data = await res.data;
      if (data.status) {
        const seoData = data.data;
        setValue('seo_title', seoData.seo_title?.slice(0, 30));
        setValue('slug', seoData.slug);
        setValue('meta_description', seoData?.meta_description?.slice(0, 180));
        setValue('keywords', seoData?.keywords?.join(','));
      } else {
        toast.error(
          data?.message ??
            'Failed to generate SEO with AI, please try again later.'
        );
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to generate SEO with AI, please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-full font-figtree">
      <p className={`text-xl font-bold`}>
        Step 4: Enhance visibility with SEO settings
      </p>
      <p className="text-sm mt-4">
        Optimize your template's searchability by configuring SEO title, meta
        description and keywords, or let the AI generate them for you.
      </p>
      <LoadingButton
        loading={loading}
        onClick={onGenerateSeoWithIA}
        startIcon={<SparklesIcon className="size-4" />}
        variant="contained"
        sx={{
          mt: 2,
          textTransform: 'none',
          width: 'fit-content',
        }}
      >
        Generate SEO with AI
      </LoadingButton>
      <div className="flex flex-col gap-6 pt-6">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-3">
            <label className="text-sm font-bold">SEO Title</label>{' '}
            <span className="text-xs text-sonic-silver">
              {watch('seo_title')?.length ?? 0} / 30
            </span>
          </div>
          <TextInput
            {...register('seo_title')}
            placeholder="Enter SEO title here..."
            maxlength={30}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-3">
            <label className="text-sm font-bold">Meta Description</label>{' '}
            <span className="text-xs text-sonic-silver">
              {watch('meta_description')?.length ?? 0} / 180
            </span>
          </div>
          <TextAreaInput
            {...register('meta_description')}
            placeholder="Enter meta description here..."
            maxlength={180}
          />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-3">
            <label className="text-sm font-bold">Slug</label>{' '}
          </div>
          <TextInput {...register('slug')} placeholder="Enter URL slug..." />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-3">
            <label className="text-sm font-bold">Keywords</label>{' '}
          </div>
          <TextAreaInput
            {...register('keywords')}
            placeholder="Add keywords (comma-separated)..."
          />
        </div>
      </div>
      <div className="mt-2">
        <p className=" text-sm font-bold">Search Result Preview</p>
        <div className="w-full border-sonic-silver border-2 p-4 mr-1 rounded-lg mt-3 flex flex-col gap-1">
          <p className="text-sm text-sonic-silver">
            https://www.straico.com/{watch('slug')}
          </p>
          <h1
            className={cn('text-3xl underline', {
              'text-[#0A70FF]': theme === 'dark',
              'text-nue-blue': theme !== 'dark',
            })}
          >
            {watch('seo_title')?.length > 0 ? watch('seo_title') : 'SEO Title'}
          </h1>
          <p className="text-xs">
            {watch('meta_description')?.length > 0
              ? watch('meta_description')
              : `Lorem ipsum dolor sit amet consectetur adipisicing elit. A ducimus
            praesentium est possimus, sit asperiores, laudantium quos quae eaque
            ipsum ipsam labore magnam dolorem perferendis provident recusandae
            maxime nisi reiciendis.`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default inject('store')(observer(SectionSeo));
