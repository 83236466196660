function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M8.58824 3.88236H3.88235C2.84276 3.88236 2 4.72511 2 5.76471V16.1176C2 17.1572 2.84276 18 3.88235 18H14.2353C15.2749 18 16.1176 17.1572 16.1176 16.1176V11.4118M14.7866 2.55133C15.5217 1.81622 16.7136 1.81622 17.4487 2.55133C18.1838 3.28643 18.1838 4.47827 17.4487 5.21338L9.36793 13.2941H6.70588L6.70588 10.6321L14.7866 2.55133Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
