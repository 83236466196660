// Icons
import { PlusIcon, DocumentTextIcon, EyeIcon } from '@heroicons/react/20/solid';
// Components
import useThemeStore from 'Theme/store';
import usePublicLayoutStore from '../State';

const AttachmentsSection = ({
  selectedTool, // Template base info
  selectedPrompt, // Template variables and prompt
}) => {
  // State's
  const { theme } = useThemeStore();
  const { openRegisterModal } = usePublicLayoutStore();

  const handleOpen = () => () => {
    openRegisterModal();
  };

  const handleSeeFile = (file) => () => {
    window.open(file?.url ?? file, '_blank');
  };

  return (
    <div className="font-figtree">
      <div className="my-2">
        {selectedTool?.files?.length > 0 && (
          <h1 className="uppercase mb-2 text-sm font-bold text-cool-gray dark:text-crystal-bell dark:text-whitedark:text-white">
            Fixed Attachments
          </h1>
        )}
        {selectedTool?.files?.map((file) => (
          <div
            key={file?._id}
            className="w-fit border border-platinum rounded flex items-center gap-2 justify-between p-[4px]"
          >
            <DocumentTextIcon className="w-5 h-5 text-cool-gray" />
            <p
              className={`truncate w-full mt-0 ${
                theme == 'dark' ? 'dark:text-white' : 'text-cool-gray'
              }`}
            >
              {file?.name}
            </p>
            <div className="flex gap-2 items-center">
              {file?.url && (
                <EyeIcon
                  className={`w-4 h-4 cursor-pointer ${
                    theme == 'dark' ? 'dark:text-white' : 'text-cool-gray'
                  }`}
                  onClick={handleSeeFile(file)}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {selectedPrompt?.prompts?.filter((i) =>
        ['image', 'attachment'].includes(i.type)
      ).length > 0 && (
        <h1 className="uppercase mb-2 text-sm font-bold text-cool-gray dark:text-crystal-bell">
          variable Attachments
        </h1>
      )}
      {selectedPrompt?.prompts
        ?.filter((i) => ['image', 'attachment'].includes(i.type))
        ?.map((prompt, index) => {
          return (
            <div className="mb-4" key={index}>
              <div
                className={`uppercase mb-2 text-sm font-bold ${
                  theme == 'dark' ? 'dark:text-white' : 'text-cool-gray'
                }`}
              >
                {prompt?.title}
              </div>
              <div
                onClick={handleOpen(prompt.attr)}
                className={`cursor-pointer hover:bg-platinum  dark:hover:bg-night-black flex items-center gap-2 w-full border border-dashed border-platinum p-[8px] rounded-md  dark:text-whitedark:text-whitefocus:border-white  ${
                  theme == 'dark'
                    ? 'dark:bg-night-black dark:text-crystal-bell'
                    : 'bg-white text-cool-gray'
                }`}
              >
                <PlusIcon
                  className={`w-4 h-4 cursor-pointer ${
                    theme == 'dark' ? 'dark:text-white' : 'text-cool-gray'
                  }`}
                />
                <p>Add {prompt.type} here</p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default AttachmentsSection;
