import { useEffect, useState } from 'react';
// MUI
import { Button } from '@mui/material';
// Store
import useCreateTemplatesStore from 'Features/CreateTemplatesForm/Store';
// RHF
import { useFormContext } from 'react-hook-form';
// Utils
import { toast } from 'react-hot-toast';
import { toCamelCase } from 'Features/CreateTemplatesForm/Utils/functions';
// Components
import CommonModal from '../CommonModal/CommonModal';
import useThemeStore from 'Theme/store';

const ModalImage = () => {
  const { modalImage, setModalImage } = useCreateTemplatesStore();
  const handleClose = () => setModalImage(false);
  // Local form
  const INITIAL_FORM = {
    name: '',
  };
  const [localForm, setLocalForm] = useState(INITIAL_FORM);
  const handleChange = (e) =>
    setLocalForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  // RHF
  const { setValue, watch } = useFormContext();
  // Handle Submit
  const handleSubmit = () => {
    if (
      watch('variables').filter(
        (i) => i.variable === toCamelCase(localForm.name)
      ).length > 0
    ) {
      toast.error(
        'Variable name already exists, please use a different name.',
        {
          icon: '🚨',
        }
      );
      return;
    }

    if (localForm.name !== '') {
      setValue('variables', [
        ...watch('variables'),
        {
          ...localForm,
          type: 'image',
          variable: toCamelCase(localForm.name),
        },
      ]);
      handleClose();
    } else {
      toast.error('To add image context, fill the field name', {
        icon: '🚨',
      });
    }
  };

  useEffect(() => {
    setLocalForm(INITIAL_FORM);
  }, [modalImage]);

  const { theme } = useThemeStore();

  return (
    <CommonModal
      sx={{
        minHeight: '200px',
      }}
      open={modalImage}
      handleClose={handleClose}
    >
      <p
        className={`font-lexend line-clamp-1 font-bold uppercase text-md ${
          theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
        }`}
      >
        Image Context
      </p>
      <div className="flex flex-col gap-6 pt-6">
        <div className="flex flex-col gap-2">
          <label
            className={`uppercase text-[12px] font-medium ${
              theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
            }`}
          >
            Field name *
          </label>
          <input
            value={localForm.name}
            onChange={handleChange}
            name="name"
            className={`w-full border border-snow-900 border-solid text-sm p-[8px] font-barlow rounded-md ${
              theme == 'dark'
                ? 'dark:bg-graphite-900 dark:text-white'
                : 'bg-snow-100 text-graphite-900'
            }`}
            placeholder="Name of your variable"
          />
        </div>
      </div>
      <div className="w-full flex justify-end mt-6">
        <Button
          sx={{
            color: 'white',
            '&:hover': {
              background: 'rgb(222 105 52 / 80%)',
            },
          }}
          variant="contained"
          color="secondary"
          size="small"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </CommonModal>
  );
};

export default ModalImage;
