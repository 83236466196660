function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill="none"
      {...props}
    >
      <path
        d="M13.7617 8.57361L10.7617 10.5736V6.57361L13.7617 8.57361Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.76757"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.76172 9.27119V7.87603C1.76172 5.02148 1.76172 3.59422 2.66721 2.67587C3.57271 1.75753 4.99828 1.71778 7.84941 1.63827C9.20045 1.6006 10.5805 1.57361 11.7617 1.57361C12.9429 1.57361 14.323 1.6006 15.674 1.63827C18.5251 1.71778 19.9507 1.75753 20.8562 2.67587C21.7617 3.59422 21.7617 5.02148 21.7617 7.87603V9.27119C21.7617 12.1257 21.7617 13.553 20.8562 14.4713C19.9507 15.3896 18.5252 15.4294 15.6741 15.5089C14.323 15.5466 12.9429 15.5736 11.7617 15.5736C10.5805 15.5736 9.20039 15.5466 7.84932 15.5089C4.99823 15.4294 3.57269 15.3896 2.6672 14.4713C1.76172 13.553 1.76172 12.1257 1.76172 9.27119Z"
        stroke="currentColor"
        strokeWidth="1.76757"
      />
    </svg>
  );
}

export default SvgComponent;
