import { Dialog } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// Mobx
import { observer, inject } from 'mobx-react';
// Icons
import {
  AdjustmentsHorizontalIcon,
  BoltIcon,
  ChatBubbleBottomCenterTextIcon,
  PaperClipIcon,
} from '@heroicons/react/24/solid';

const WordcapLimitsDialog = inject('store')(
  observer(({ store, open, exceeded, onClose }) => {
    const navigate = useNavigate();

    const getPercentValue = (type) => {
      return store.profile.wordcap_limits
        ? (store.profile.wordcap_limits[type] * 100).toFixed(2)
        : 0;
    };

    const handleNavigation = (path) => {
      navigate(path);

      setTimeout(() => {
        const targetSection = document.querySelector('#wordcapLimitsSection');
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
          targetSection.focus();
        }
      }, 300);
    };

    const getWords = () => {
      return store.profile.wordcap_limits
        ? store.profile.wordcap_limits.words
        : 0;
    };

    return (
      <Dialog
        PaperProps={{
          maxWidth: '440px',
          className:
            'font-figtree border-nue-blue border-t-4 bg-seasalt text-raisin-black dark:bg-lead dark:text-crystal-bell',
          sx: {
            borderRadius: '4px',
            p: '24px',
          },
        }}
        open={open}
        onClose={onClose}
      >
        <div className="h-full w-full sm:max-w-lg">
          <div className="text-base leading-6">
            <div className="flex flex-col pb-1">
              <div className="uppercase font-bold text-raisin-black dark:text-crystal-bell">
                Understanding Your Wordcap Limit
              </div>
            </div>

            <div className="font-normal py-5">
              {getWords() == 0 ? (
                'You have no word limit for this request. Word capacity will depend on your selected LLMs.'
              ) : (
                <>
                  {exceeded ? "You've exceeded the" : 'You have a'}{' '}
                  <strong>
                    {getWords().toLocaleString('en-US')}
                    -word limit
                  </strong>{' '}
                  for this request.
                </>
              )}{' '}
              <br /> Here's how the limit is distributed:
            </div>

            <div className="grid grid-cols-5 gap-x-2 text-sm leading-[22px] py-2 border-y border-cool-gray">
              <div className="flex items-center gap-2 col-span-2">
                <BoltIcon className="w-4 h-4 text-cool-gray" />
                <div className="font-bold">Current Prompt</div>
              </div>
              <div className="col-span-3">
                {getPercentValue('input')}% of the limit
                {getWords() == 0
                  ? '.'
                  : ', or around ' +
                    Math.floor(
                      getWords() * store.profile.wordcap_limits.input
                    ) +
                    ' words.'}
              </div>
              <div className="flex items-center gap-2 col-span-2">
                <PaperClipIcon className="w-4 h-4 text-violet-blue" />
                <div className="font-bold">Attachments</div>
              </div>
              <div className="col-span-3">
                {getPercentValue('files')}% of the limit
                {getWords() == 0
                  ? '.'
                  : ', or around ' +
                    Math.floor(
                      getWords() * store.profile.wordcap_limits.files
                    ) +
                    ' words.'}
              </div>
              <div className="flex items-center gap-2 col-span-2">
                <ChatBubbleBottomCenterTextIcon className="w-4 h-4 text-lavender" />
                <div className="font-bold">Conversation History</div>
              </div>
              <div className="col-span-3">
                {getPercentValue('messages')}% of the limit
                {getWords() == 0
                  ? '.'
                  : ', or around ' +
                    Math.floor(
                      getWords() * store.profile.wordcap_limits.messages
                    ) +
                    ' words.'}
              </div>
            </div>

            <div className="max-w-[440px] py-5">
              This distribution helps manage your Straico coin expenditure
              effectively. If you wish to adjust these limits, you can do so in
              your account settings.{' '}
              <a
                className="font-semibold text-violet-blue underline"
                href="https://straico-uqhq.updates.userguiding.com/post/wordcap-limit-%F0%9F%92%AC-v1.36.0-830"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </div>

            <div className="flex justify-center text-white">
              <button
                className="flex gap-2 items-center uppercase bg-nue-blue rounded px-4 py-1"
                onClick={() => handleNavigation('/user-settings')}
              >
                <AdjustmentsHorizontalIcon className="w-4 h-4" />
                Go to Settings
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  })
);

export default WordcapLimitsDialog;
