import { create } from 'zustand';

const usePublicPromptStore = create((set) => ({
  selectedPrompt: null,
  setSelectedPrompt: (prompt) => set({ selectedPrompt: prompt }),
  selectedTool: null,
  setSelectedTool: (tool) => set({ selectedTool: tool }),
  modalPrompt: false,
  toggleModalPrompt: () =>
    set((state) => ({ modalPrompt: !state.modalPrompt })),
  openModalPrompt: () => set({ modalPrompt: true }),
  closeModalPrompt: () => set({ modalPrompt: false }),
  updateVariables: null,
  setUpdateVariables: (variables) => set({ updateVariables: variables }),
  submit: false,
  setSubmit: (submit) => set({ submit }),
  tools: [],
  setTools: (tools) => set({ tools }),
  defaultValues: null,
  setDefaultValues: (values) => set({ defaultValues: values }),
}));

export default usePublicPromptStore;
