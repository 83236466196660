function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      {...props}
    >
      <path
        d="M5.21429 9H10.7857M5.21429 12.5556H10.7857M12.6429 17H3.35714C2.33147 17 1.5 16.2041 1.5 15.2222V2.77778C1.5 1.79594 2.33147 1 3.35714 1H8.54394C8.79022 1 9.0264 1.09365 9.20054 1.26035L14.228 6.07298C14.4022 6.23968 14.5 6.46578 14.5 6.70152V15.2222C14.5 16.2041 13.6685 17 12.6429 17Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
