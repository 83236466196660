import { XMarkIcon } from '@heroicons/react/24/outline';

const ActiveFilterItem = ({ text, onDelete }) => {
  return (
    <div className="bg-lavender dark:bg-ship-grey text-violet-blue dark:text-tropical-indigo text-xs truncate max-w-[150px] py-[2px] px-1 rounded-lg justify-between items-center flex">
      <p className="w-full truncate">{text}</p>
      <XMarkIcon
        className="w-4 h-4 ml-auto cursor-pointer"
        onClick={onDelete}
      />
    </div>
  );
};

export default ActiveFilterItem;
