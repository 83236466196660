/* eslint-disable no-console */
import { useEffect, useMemo, useState } from 'react';
// mobx
import { inject, observer } from 'mobx-react';
// store
import useSmartModeStore from '../../../Features/SmartMode/store';
import useThemeStore from '../../../Theme/store';
// styles
import { styled, Switch, Tooltip } from '@mui/material';
import { toast } from 'react-hot-toast';

// SVG en base64
const svgIconBase64 = `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAyNyAzNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjYuNSA4LjVIMTkuMjc3NkwyMy42MTA5IDBIMTIuMDU1OEwwLjUgMjIuNjY2NEg5LjE2NjY3VjM0TDI2LjUgOC41WiIgZmlsbD0iaW5oZXJpdCIvPjwvc3ZnPg==`;

const SmartModeSwitch = styled(Switch)(({ themeValue, smartMode }) => ({
  width: 46,
  height: 24,
  padding: 0,
  borderWidth: 2,
  borderRadius: 24 / 2,
  margin: '0 !important',
  border:
    themeValue === 'dark'
      ? smartMode === 'on'
        ? '1.5px solid #9197fc'
        : '1.5px solid gray'
      : smartMode === 'on'
      ? '1.5px solid #464bba'
      : '1.5px solid gray',
  boxShadow:
    themeValue === 'dark' && smartMode === 'on'
      ? '0 0 5px #9197fc, 0 0 10px rgba(188, 191, 255, 0.5)'
      : smartMode === 'on'
      ? '0 0 5px #464bba, 0 0 10px rgba(70, 75, 186, 0.5)'
      : 'none',
  transition: 'box-shadow 0.3s ease-in-out, border 0.3s ease-in-out',
  '& .MuiSwitch-switchBase': {
    padding: 1.5,
    transform: 'translateX(1px)',
    '&.Mui-checked': {
      transform: 'translateX(21px)',
      '& .MuiSwitch-thumb:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '60%',
        transform: 'rotate(90deg)',
        backgroundImage: `url(${svgIconBase64})`,
        filter: 'brightness(0) invert(1)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 17,
    height: 17,
    position: 'relative',
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '60%',
      transform: 'rotate(90deg)',
      backgroundImage: `url(${svgIconBase64})`,
      filter: 'brightness(0) invert(0.5)',
    },
  },
}));

const ToggleSmartMode = ({ classNameContainer, store }) => {
  const { smartMode, setSmartMode, hasUsedSmartMode, setHasUsedSmartMode } =
    useSmartModeStore();
  const { theme: themeValue } = useThemeStore();

  const handleToggle = () => {
    const newMode = smartMode === 'on' ? 'off' : 'on';
    setSmartMode(newMode);
    localStorage.setItem('smartMode', newMode);
    const htmlElement = document.querySelector('html');
    htmlElement.classList.remove('on', 'off');
    htmlElement.classList.add(newMode);

    const customToastOptions = {
      style: {
        background: newMode === 'on' ? '#D4D5E6' : '#E1E1E5',
        color: '#000',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '30%',
        minWidth: '20%',
      },
      icon: (
        <img
          src={svgIconBase64}
          alt="Lightning Icon"
          width="24"
          height="24"
          style={{
            marginRight: '8px',
            filter:
              newMode === 'on'
                ? 'invert(34%) sepia(96%) saturate(5500%) hue-rotate(245deg) brightness(60%) contrast(95%)'
                : 'invert(0%) sepia(0%) saturate(0%) hue-rotate(204deg) brightness(100%) contrast(10%)',
          }}
        />
      ),
      duration: 2000,
    };

    const titleStyle = {
      fontWeight: 700,
      fontSize: '16px',
      marginBottom: '4px',
      color: newMode === 'on' ? '#5256A6' : '#575557',
      fontFamily: 'Figtree, sans-serif',
    };

    const subtitleStyle = {
      fontSize: '14px',
      color: '#6B6B6B',
    };

    if (newMode === 'on') {
      toast(
        <div>
          <div style={titleStyle}>Smart Mode – Activated</div>
          <div style={subtitleStyle}>
            Prompt optimization & template generation
          </div>{' '}
          {/* Subtítulo */}
        </div>,
        customToastOptions
      );
    } else {
      toast(
        <div>
          <div style={titleStyle}>Smart Mode – Deactivated</div>{' '}
          <div style={subtitleStyle}>
            Prompt optimization & template generation
          </div>{' '}
        </div>,
        customToastOptions
      );
    }
  };

  useEffect(() => {
    const storedMode = localStorage.getItem('smartMode');

    // Obtener información del perfil del usuario
    const lastLogin = store?.profile?.last_login;
    const created = store?.profile?.created;

    // Determinar si el usuario es nuevo (no tiene sesiones previas de login)
    const isNewUser =
      !lastLogin ||
      (lastLogin &&
        created &&
        new Date(created).toDateString() ===
          new Date(lastLogin).toDateString());

    if (storedMode) {
      // Restaurar el estado del Smart Mode si existe en localStorage
      setSmartMode(storedMode);
    } else if (isNewUser) {
      // Si el usuario es nuevo, activar Smart Mode por defecto
      setSmartMode('on');
      localStorage.setItem('smartMode', 'on');
      setHasUsedSmartMode(true);
    } else {
      // Si el usuario no es nuevo y nunca ha usado Smart Mode antes
      const hasUsedSmartModeBefore = hasUsedSmartMode;

      if (!hasUsedSmartModeBefore) {
        // Si es la primera vez que ve el Smart Mode, mantenerlo apagado
        setSmartMode('off');
        localStorage.setItem('smartMode', 'off');
        setHasUsedSmartMode(true);
      }
    }
  }, [
    setSmartMode,
    setHasUsedSmartMode,
    store?.profile?.last_login,
    store?.profile?.created,
    hasUsedSmartMode,
  ]);

  const switchValue = useMemo(() => smartMode === 'on', [smartMode]);

  return (
    <div
      className={`${classNameContainer} mx-4 uppercase flex items-center justify-center font-figtree font-bold`}
    >
      <Tooltip title={`Smart Mode ${smartMode === 'on' ? 'on' : 'off'}`}>
        <SmartModeSwitch
          smartMode={smartMode}
          themeValue={themeValue}
          checked={switchValue}
          onChange={handleToggle}
          className={`fill-${switchValue ? 'white' : 'gray-400'}`}
        />
      </Tooltip>
    </div>
  );
};

export default inject('store')(observer(ToggleSmartMode));
