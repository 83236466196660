/* eslint-disable react/no-deprecated */
import { Component } from 'react';
import { computed, observable, makeObservable } from 'mobx';
import { Link, useNavigate } from 'react-router-dom';
import MainBody from '../Components/Body';
import { Helmet } from 'react-helmet';
import {
  PlayIcon,
  BookmarkIcon,
  PlusCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CurrencyDollarIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/outline';

import { observer, inject } from 'mobx-react';
import { TranslateContext } from '../Context/translate-context';
import CreatePrompt from '../Components/CreatePrompt';
import Subheader from '../Components/Subheader';

export const formatRuns = (runs) => {
  if (runs == 1) {
    return '1 run';
  } else if (runs < 1000) {
    return runs + ' runs';
  } else if (runs < 1000000) {
    const thousands = Math.floor(runs / 1000);
    const remainder = runs % 1000;
    const formatted = remainder ? `.${(remainder / 100).toFixed(0)}` : '';
    return `${thousands}${formatted}K runs`;
  } else {
    const millions = Math.floor(runs / 1000000);
    const remainder = Math.floor((runs % 1000000) / 100000);
    const formatted = remainder ? `.${remainder}` : '';
    return `${millions}${formatted}M runs`;
  }
};

@inject('store')
@observer
class Body extends Component {
  static contextType = TranslateContext;

  @observable openDialogCreatePrompt = false;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentWillMount = () => {
    this.props.store.labelSection = this.context.translator(
      'sectionToolBar.labelDashboard'
    );
  };

  @computed get permissions() {
    return this.props.store.tools.filter((tool) =>
      tool.permissions.some((r) =>
        this.props.store.profile.permissions.includes(r)
      )
    );
  }

  @computed get programming() {
    return this.permissions.filter(
      (tool) => tool.category === 'Programming' && tool.visible
    );
  }

  @computed get freestyle() {
    return this.permissions.filter(
      (tool) => tool.category === 'Freestyle' && tool.visible
    );
  }

  @computed get content() {
    return this.permissions.filter(
      (tool) => tool.category === 'Content' && tool.visible
    );
  }

  @computed get business() {
    return this.permissions.filter(
      (tool) => tool.category === 'Business' && tool.visible
    );
  }

  @computed get writing() {
    return this.permissions.filter(
      (tool) => tool.category === 'Writing' && tool.visible
    );
  }

  @computed get personal() {
    return this.permissions.filter(
      (tool) => tool.category === 'For fun' && tool.visible
    );
  }

  @computed get media() {
    return this.permissions.filter(
      (tool) => tool.category === 'Media' && tool.visible
    );
  }

  @computed get myPrompts() {
    return this.permissions.filter(
      (tool) =>
        (tool.category === 'My Prompts' || tool.category === 'My Tools') &&
        (tool.in_trash == undefined || tool.in_trash == false) &&
        tool.visible
    );
  }

  onInteraction = async (str) => {
    if (str == 'close') {
      this.openDialogCreatePrompt = false;
    }
  };

  activateModalCreatePrompt = () => {
    this.openDialogCreatePrompt = true;
  };

  onToogleMenu = (name) => {
    // console.log(name);
    this.props.store[name] = !this.props.store[name];
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{`Tools - Straico`}</title>
        </Helmet>

        <Subheader
          title={this.props.store.labelSection}
          icon="WrenchIcon"
        ></Subheader>

        <MainBody className="px-4 md:px-28 py-5">
          {this.personal.length ? (
            <>
              <Title
                title="Media"
                color={this?.media[0]?.fromColor}
                count={this.media.length}
                show={this.props.store.showSectionMedia}
                name={'showSectionMedia'}
                toogleMenu={this.onToogleMenu}
              />
              <Grid show={this.props.store.showSectionMedia}>
                {this.media.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    model={tool.model}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                    toolId={tool._id}
                    total={tool.total}
                    coins={tool.coins}
                    runs={tool.runs}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.myPrompts.length >= 0 ? (
            <>
              <Title
                title="My Tools"
                color={'nue-blue'}
                count={this.myPrompts.length}
                show={this.props.store.showSectionMyPrompts}
                name={'showSectionMyPrompts'}
                toogleMenu={this.onToogleMenu}
              />
              <Grid show={this.props.store.showSectionMyPrompts}>
                <Tool
                  key="1"
                  draw="canvas"
                  activateModalCreatePrompt={this.activateModalCreatePrompt}
                />
                {this.myPrompts.map((tool, index) => (
                  <Tool
                    key={index}
                    group={'My tool'}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    model={tool.model}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                    toolId={tool._id}
                    total={tool.total}
                    coins={tool.coins}
                    runs={tool.runs}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.business.length ? (
            <>
              <Title
                title="Business and Mgmt"
                color={this.business[0].fromColor}
                count={this.business.length}
                show={this.props.store.showSectionBussMgmt}
                name={'showSectionBussMgmt'}
                toogleMenu={this.onToogleMenu}
              />
              <Grid show={this.props.store.showSectionBussMgmt}>
                {this.business.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    model={tool.model}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                    toolId={tool._id}
                    total={tool.total}
                    coins={tool.coins}
                    runs={tool.runs}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.content.length ? (
            <>
              <Title
                title="Marketing"
                color={this.content[0].fromColor}
                count={this.content.length}
                show={this.props.store.showSectionMarketing}
                name={'showSectionMarketing'}
                toogleMenu={this.onToogleMenu}
              />
              <Grid show={this.props.store.showSectionMarketing}>
                {this.content.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    model={tool.model}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                    toolId={tool._id}
                    total={tool.total}
                    coins={tool.coins}
                    runs={tool.runs}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.writing.length ? (
            <>
              <Title
                title="Language & Writing"
                color={this.writing[0].fromColor}
                count={this.writing.length}
                show={this.props.store.showSectionLangWriti}
                name={'showSectionLangWriti'}
                toogleMenu={this.onToogleMenu}
              />
              <Grid show={this.props.store.showSectionLangWriti}>
                {this.writing.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    model={tool.model}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                    toolId={tool._id}
                    total={tool.total}
                    coins={tool.coins}
                    runs={tool.runs}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.programming.length ? (
            <>
              <Title
                title="Programming"
                color={this.programming[0].fromColor}
                count={this.programming.length}
                show={this.props.store.showSectionProgramming}
                name={'showSectionProgramming'}
                toogleMenu={this.onToogleMenu}
              />
              <Grid show={this.props.store.showSectionProgramming}>
                {this.programming.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    model={tool.model}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                    toolId={tool._id}
                    total={tool.total}
                    coins={tool.coins}
                    runs={tool.runs}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.freestyle.length ? (
            <>
              <Title
                title="Freestyle"
                color={this.freestyle[0].fromColor}
                count={this.freestyle.length}
                show={this.props.store.showSectionFreestyle}
                name={'showSectionFreestyle'}
                toogleMenu={this.onToogleMenu}
              />
              <Grid show={this.props.store.showSectionFreestyle}>
                {this.freestyle.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    model={tool.model}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                    toolId={tool._id}
                    total={tool.total}
                    coins={tool.coins}
                    runs={tool.runs}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.personal.length ? (
            <>
              <Title
                title="For fun"
                color={this.personal[0].fromColor}
                count={this.personal.length}
                show={this.props.store.showSectionPersonal}
                name={'showSectionPersonal'}
                toogleMenu={this.onToogleMenu}
              />
              <Grid show={this.props.store.showSectionPersonal}>
                {this.personal.map((tool, index) => (
                  <Tool
                    key={index}
                    group={tool.category}
                    title={tool.title}
                    to={tool.to}
                    Icon={tool.Icon}
                    desc={tool.desc}
                    model={tool.model}
                    fromColor={tool.fromColor}
                    toColor={tool.toColor}
                    toolId={tool._id}
                    total={tool.total}
                    coins={tool.coins}
                    runs={tool.runs}
                  />
                ))}
              </Grid>
              <Divider />
            </>
          ) : null}

          {this.openDialogCreatePrompt ? (
            <CreatePrompt onInteraction={this.onInteraction}></CreatePrompt>
          ) : (
            <></>
          )}
        </MainBody>
      </>
    );
  }
}

export const Divider = () => (
  <div className="divide-y-2 divide-dashed divide-cool-gray py-8">
    {' '}
    <div></div>
    <div></div>
  </div>
);

export const Title = ({ title, count, show, toogleMenu, name }) => (
  <div
    className={`border-l-8 ${
      show ? 'border-t-4' : ''
    } border-violet-blue relative cursor-pointer rounded-sm hover:bg-white/50 ml-0.5`}
    onClick={() => toogleMenu(name)}
  >
    <h2
      className={`font-lexend font-semibold uppercase text-xl sm:text-2xl md:text-2xl text-violet-blue p-1 ml-2`}
    >
      {title}
    </h2>
    <div className="absolute right-12 top-1 md:mt-1 md:mr-2 mt-0.5 mr-0.5">
      <p className={`text-violet-blue font-bold`}>{count}</p>
    </div>
    {show ? (
      <ChevronUpIcon
        className={`w-8 h-8 absolute right-0 top-0 text-violet-blue md:mt-1 md:mr-2 mt-0.5 mr-0.5`}
      />
    ) : (
      <ChevronDownIcon
        className={`w-8 h-8 absolute right-0 top-0 text-violet-blue md:mt-1 md:mr-2 mt-0.5 mr-0.5`}
      />
    )}
  </div>
);

export const Grid = ({ children, show }) => (
  <div
    className={`${
      show ? 'grid' : 'hidden'
    } grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3`}
  >
    {children}
  </div>
);

export const Tool = inject('store')(
  observer(
    ({
      // store,
      toolId,
      // Icon,
      title,
      desc,
      model,
      to,
      group,
      // fromColor,
      // toColor,
      total,
      draw,
      activateModalCreatePrompt,
      coins,
      runs,
    }) => {
      const navigate = useNavigate();

      const onHistory = async (e) => {
        e.preventDefault();
        try {
          navigate(`/history?tool=${toolId}`);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      };

      return (
        <>
          {draw == 'canvas' ? (
            // Canvas tool
            <div
              className="font-figtree bg-white/30 flex rounded-md group transition hover:shadow-md overflow-hidden md:max-w-1lg text-cool-gray cursor-pointer border-platinum border-2 border-dashed md:flex relative transform hover:scale-105"
              onClick={activateModalCreatePrompt}
            >
              <div className="w-full">
                <div className="p-4">
                  <div className="flex flex-row">
                    <div className="w-4/5">
                      <div className="uppercase tracking-wide text-sm text-cool-gray font-semibold leading-none group-hover:text-violet-blue">
                        Create my own tool
                      </div>
                    </div>
                    <div className="w-1/5 -mt-1">
                      <div
                        className={`flex justify-end text-battleship-gray text-sm tracking-wide`}
                      >
                        <span className="-mt-[2px]">10</span>
                        <CurrencyDollarIcon className="w-4 h-4 text-violet-blue" />
                      </div>
                    </div>
                  </div>

                  <div className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-light text-battleship-gray group-hover:text-violet-blue">
                    My idea
                  </div>
                </div>
                <div className="flex justify-center mb-4">
                  <PlusCircleIcon className="w-28 h-28 text-cool-gray group-hover:text-violet-blue" />
                </div>
              </div>
            </div>
          ) : (
            // Real tool
            <Link to={to || '/'} className="flex relative ">
              <div
                className={`bg-white flex-1 rounded-md transition overflow-hidden md:max-w-1lg text-cool-gray cursor-pointer border border-platinum md:flex relative transform hover:scale-105 hover:text-raisin-black shadow-2xl focus:shadow-2xl hover:shadow-md`}
              >
                <div className="w-full grid content-between">
                  <div className="p-4">
                    <div className="flex flex-row">
                      <div className="w-4/5">
                        <div
                          className={`flex justify-start items-center font-bold uppercase tracking-wide text-sm text-nue-blue leading-none mt-1`}
                        >
                          {group || 'New'}
                        </div>
                      </div>
                      <div className="w-1/5">
                        <div
                          className={`flex justify-end text-cool-gray text-sm tracking-wide`}
                        >
                          <span className="-mt-[2px]">{coins}</span>
                          <CurrencyDollarIcon className="w-4 h-4 text-violet-blue" />
                        </div>
                      </div>
                    </div>

                    <div className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-light text-raisin-black">
                      {title}
                    </div>
                    {model ? (
                      <div className="text-cool-gray text-xs">{model}</div>
                    ) : (
                      <></>
                    )}
                    <p className="mt-1 pr-1 text-base line-clamp">{desc}</p>

                    {runs ? (
                      <div>
                        <hr className="h-0.5 my-2 bg-battleship-gray border-0 w-24" />
                        <div className="flex text-battleship-gray text-sm uppercase tracking-wide">
                          <span className="-mt-[1px]">{formatRuns(runs)}</span>
                          <RocketLaunchIcon className="w-5 h-5 text-gray-400 ml-1" />
                        </div>{' '}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="w-full grid grid-cols-2">
                    <div className="flex justify-center py-4 px-2 hover:bg-platinum text-cool-gray rounded-md transition">
                      <PlayIcon className="w-7 h-7 transition" />{' '}
                      <span className="uppercase mt-0.5 ml-1">Run</span>
                    </div>
                    <div
                      className="flex justify-center py-4 px-2 hover:bg-platinum text-cool-gray rounded-md transition"
                      onClick={(e) => onHistory(e)}
                    >
                      <BookmarkIcon className="w-7 h-7 transition" />{' '}
                      <span className="uppercase mt-0.5 ml-1">
                        Saved{total > 0 ? ' (' + total + ')' : ''}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )}
        </>
      );
    }
  )
);

export default Body;
