import { useEffect, useState } from 'react';
// MUI
import { Button } from '@mui/material';
// Store
import useCreateTemplatesStore from 'Features/CreateTemplatesForm/Store';
// RHF
import { useFormContext } from 'react-hook-form';
// Utils
import { toCamelCase } from 'Features/CreateTemplatesForm/Utils/functions';
import { toast } from 'react-hot-toast';
// Components
import useThemeStore from 'Theme/store';
import CommonModal from '../CommonModal/CommonModal';

const ModalTextVariable = () => {
  const { textModal, setTextModal, setEditTextModal, editTextModal } =
    useCreateTemplatesStore();

  const handleClose = () => setTextModal(false);

  // Form
  const INITIAL_FORM = {
    name: '',
    placeholder: '',
    maxLength: '',
  };

  const [currentVariable, setCurrentVariable] = useState(INITIAL_FORM);

  const [localForm, setLocalForm] = useState(INITIAL_FORM);

  const handleChange = (e) =>
    setLocalForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const { setValue, watch } = useFormContext();

  const handleSubmit = () => {
    // Validate empty fields
    if (
      localForm.name === '' ||
      localForm.placeholder === '' ||
      localForm.maxLength === ''
    ) {
      toast.error(
        'To add a text variable, fill the variable name, placeholder and max characters fields.',
        {
          icon: '🚨',
        }
      );
      return;
    }

    // Validate that the variable name is unique
    const newVariableName = toCamelCase(localForm.name);
    const variables = watch('promptVariables');
    const isVariableNameTaken = variables.some(
      (variable) => variable.variable === newVariableName
    );

    if (
      (!editTextModal.edit ||
        newVariableName !== toCamelCase(currentVariable.name)) &&
      isVariableNameTaken
    ) {
      toast.error(
        'Variable name already exists, please use a different name.',
        {
          icon: '🚨',
        }
      );
      return;
    }

    // Validate that max length is a whole number, not negative, greater than 0 and less than 100,000.
    try {
      localForm.maxLength = Number(localForm.maxLength);
      if (
        isNaN(localForm.maxLength) ||
        !Number.isInteger(localForm.maxLength)
      ) {
        toast.error('Maximum character field must be a whole number.', {
          icon: '🚨',
        });
        return;
      }
      localForm.maxLength = parseInt(localForm.maxLength);
    } catch (e) {
      toast.error('Maximum character field must be a whole number.', {
        icon: '🚨',
      });
      return;
    }

    if (localForm.maxLength < 1 || localForm.maxLength > 100000) {
      toast.error('Maximum character field must be between 1 and 100,000.', {
        icon: '🚨',
      });
      return;
    }

    // Edit
    if (editTextModal.edit) {
      const newVariables = watch('promptVariables').map((i) => {
        if (i.variable === editTextModal.defaultData.variable) {
          return {
            ...localForm,
            type: 'textarea',
            variable: toCamelCase(localForm.name),
          };
        }
        return i;
      });
      setValue('promptVariables', newVariables);
      handleClose();
      setValue(
        'prompt',
        `${watch('prompt').replace(
          editTextModal.defaultData.variable,
          `${toCamelCase(localForm.name)}`
        )}`
      );
      return;
    }

    // Add
    setValue('promptVariables', [
      ...watch('promptVariables'),
      {
        ...localForm,
        type: 'textarea',
        variable: toCamelCase(localForm.name),
      },
    ]);
    setValue(
      'prompt',
      `${watch('prompt') ?? ''} {{${toCamelCase(localForm.name)}}}`
    );

    handleClose();
  };

  useEffect(() => {
    setLocalForm(INITIAL_FORM);
    if (!textModal) {
      setEditTextModal({
        edit: false,
        defaultData: {},
      });
    }
  }, [textModal]);

  useEffect(() => {
    if (editTextModal.edit) {
      setLocalForm(editTextModal.defaultData);
      setCurrentVariable(editTextModal.defaultData);
    }
  }, [editTextModal]);

  const { theme } = useThemeStore();

  return (
    <CommonModal open={textModal} handleClose={handleClose}>
      <p
        className={`font-lexend line-clamp-1 font-bold uppercase text-md ${
          theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
        }`}
      >
        text variable
      </p>
      <div className="flex flex-col gap-6 pt-6">
        <div className="flex flex-col gap-2">
          <label
            className={`uppercase text-[12px] font-medium font-lexend ${
              theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
            }`}
          >
            Variable name *
          </label>
          <input
            value={localForm.name}
            onChange={handleChange}
            name="name"
            className={`w-full border border-snow-900 border-solid text-sm p-[8px] font-barlow rounded-md ${
              theme == 'dark'
                ? 'dark:bg-graphite-900 dark:text-white'
                : 'bg-snow-100 text-graphite-900'
            }`}
            placeholder="Name of your variable"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            className={`uppercase text-[12px] font-medium font-lexend ${
              theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
            }`}
          >
            Placeholder *
          </label>
          <p
            className={`text-xs font-barlow ${
              theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
            }`}
          >
            Temporary text holder for your input.
          </p>
          <input
            value={localForm.placeholder}
            onChange={handleChange}
            name="placeholder"
            className={`w-full border border-snow-900 border-solid text-sm p-[8px] font-barlow rounded-md ${
              theme == 'dark'
                ? 'dark:bg-graphite-900 dark:text-white'
                : 'bg-snow-100 text-graphite-900'
            }`}
            placeholder="Placeholder of your variable"
          />
        </div>

        <div className="flex flex-col gap-2">
          <label
            className={`uppercase text-[12px] font-medium font-lexend ${
              theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
            }`}
          >
            Max Characters *
          </label>
          <p
            className={`text-xs font-barlow ${
              theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
            }`}
          >
            Maximum number of characters allowed (min: 1, max: 100,000).
          </p>
          <input
            value={localForm.maxLength}
            onChange={handleChange}
            name="maxLength"
            className={`w-full border border-snow-900 border-solid text-sm p-[8px] font-barlow rounded-md ${
              theme == 'dark'
                ? 'dark:bg-graphite-900 dark:text-white'
                : 'bg-snow-100 text-graphite-900'
            }`}
            placeholder="10000"
            type="number"
          />
        </div>
      </div>
      <div className="w-full flex justify-end mt-6">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSubmit}
        >
          <span className="font-lexend font-bold">Save</span>
        </Button>
      </div>
    </CommonModal>
  );
};

export default ModalTextVariable;
