import { useState } from 'react';
// Mobx
import { inject, observer } from 'mobx-react';
// Components
import useChatStore from 'Components/Chat/store';
import Modal from 'Components/Common/Modal/NewModal';
import useThemeStore from 'Theme/store';
// Icons
import { CheckIcon } from '@heroicons/react/24/outline';
import useTreeDNDChatStore from 'Components/Chat/TreeDNDChat/Store';

const EditTitle = inject('store')(
  observer(({ store, chat, onInteraction }) => {
    const [success, setSuccess] = useState(false);

    const [fTitle, setFTitle] = useState(chat.title);

    const { theme } = useThemeStore();

    // Chat manage STATE
    const { selectedChat } = useChatStore();
    const { treeData, setTreeData } = useTreeDNDChatStore();

    const edit = async () => {
      try {
        await store.api
          .post('/chat/edit', {
            hash: chat._id,
            title: fTitle,
          })
          .then(async () => {
            setSuccess(true);
            const chat = selectedChat.chat;

            // Update tree data
            const oldData = treeData.filter((item) => item.id !== chat._id);
            const selectedChatTree = treeData.find(
              (item) => item.id === chat._id
            );
            setTreeData([...oldData, { ...selectedChatTree, text: fTitle }]);

            await sleep(2000);
            onInteraction({ title: fTitle });
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    };

    const sleep = (time) => {
      return new Promise((resolve) => setTimeout(resolve, time));
    };

    return (
      <Modal
        open={true}
        title="Edit title"
        onClose={() => onInteraction('close')}
        onConfirm={() => edit()}
        buttonConfirmProps={{
          disabled: fTitle == undefined || fTitle == '' || success,
        }}
        onConfirmBtnText="Update"
        onConfirmBtnIcon={
          <CheckIcon className="h-[18px] w-[18px]" aria-hidden="true" />
        }
      >
        <div className="font-figtree w-96 text-base text-black dark:text-crystal-bell text-center">
          <input
            value={fTitle}
            key="1"
            onChange={(e) => setFTitle(e.target.value)}
            type="text"
            className={`w-full border border-platinum text-sm leading-[18px] ${
              theme == 'dark'
                ? 'bg-lead text-crystal-bell'
                : 'bg-white text-raisin-black'
            }  border-solid px-3 py-2 rounded-[10px] mb-2`}
            placeholder="New chat title"
          />

          {success ? (
            <div className="text-violet-blue bg-lavender rounded-md p-1 text-center mt-4">
              Information updated
            </div>
          ) : null}
        </div>
      </Modal>
    );
  })
);
export default EditTitle;
