function SvgComponent({ fillColor = '#74AA9C', ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width="24" height="24" rx="8" fill={fillColor} />
      <path
        d="M15.187 5.88753C13.1282 8.80648 11.3073 11.4326 11.2707 11.5424C11.2066 11.698 12.6066 13.7294 12.6981 13.6287C12.7347 13.5921 14.0707 11.6797 15.672 9.3921C17.2733 7.10452 18.6184 5.1738 18.6733 5.10975C18.7374 5.0274 18.4537 4.99994 17.2916 4.99994H15.8184L15.187 5.88753Z"
        fill="white"
      />
      <path
        d="M17.5018 7.84575L16.3306 9.52026L16.4404 13.4549C16.4953 15.6235 16.541 17.7556 16.541 18.1948V19H17.4927H18.4443L18.4992 17.966C18.5632 16.6575 18.7463 6.25359 18.7005 6.21699C18.6822 6.19869 18.1423 6.93072 17.5018 7.84575Z"
        fill="white"
      />
      <path
        d="M6.6315 10.3804C6.97006 10.8745 8.47072 13.0157 9.95307 15.1386L12.6524 19H14.0982C14.8942 19 15.5348 18.9634 15.5165 18.9268C15.5073 18.881 14.0158 16.7399 12.2132 14.1686L8.92823 9.48366H7.46418H6.00928L6.6315 10.3804Z"
        fill="white"
      />
      <path
        d="M7.79346 16.4562C7.15294 17.3804 6.48497 18.3229 6.31111 18.5699L6 19H7.4732H8.94641L9.65098 17.9843C10.0444 17.4261 10.3556 16.9229 10.3556 16.8588C10.3464 16.8039 10.0353 16.319 9.65098 15.7699L8.96471 14.7908L7.79346 16.4562Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgComponent;
