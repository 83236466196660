import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const LabelIcon = ({ icon, text, className }) => {
  const Icon = icon;
  const { theme } = useThemeStore();

  return (
    <div
      className={cn('flex items-center font-figtree', {
        'text-white': theme === 'dark',
        'text-raisin-black': theme !== 'dark',
      })}
    >
      <Icon
        className={cn('size-5 font-bold ', {
          'stroke-white': theme === 'dark',
          'stroke-raisin-black': theme !== 'dark',
        })}
      />
      <label
        htmlFor="input"
        className={cn('ml-2 text-sm font-bold', className)}
      >
        {text}
      </label>
    </div>
  );
};

export default LabelIcon;
