import { Typography } from '@mui/material';
import { CodeBlock } from './CodeBlocks';

export const MarkdownComponents = {
  // eslint-disable-next-line no-unused-vars
  code({ _, inline, className, children, ...props }) {
    const languageMatch = /language-(\w+)/.exec(className || '');

    if (inline) {
      return (
        <code className={`${className} whitespace-pre-wrap w-fit`} {...props}>
          {children}
        </code>
      );
    }

    if (languageMatch && languageMatch[1])
      return (
        <CodeBlock
          className={className}
          key={Math.random()}
          language={(languageMatch && languageMatch[1]) || 'js'}
          value={String(children).replace(/\n$/, '')}
          {...props}
        />
      );
    else {
      return (
        <code
          className={`${className} whitespace-pre-wrap w-fit text-base font-figtree mb-[10px]`}
          {...props}
        >
          {children}
        </code>
      );
    }
  },

  p: ({ children }) => (
    <pre className="mb-4 prose break-words dark:prose-invert prose-p:leading-relaxed prose-pre:p-0 !whitespace-pre-wrap text-base dark:text-crystal-bell !font-figtree">
      {children}
    </pre>
  ),
  pre: ({ children }) => <pre className="p-1">{children}</pre>,
  ul: ({ children }) => <ul className="list-disc">{children}</ul>,
  ol: ({ children }) => <ol className="list-decimal">{children}</ol>,
  li: ({ children }) => (
    <li className="mb-5 ml-5  list-item dark:text-crystal-bell">{children}</li>
  ),
  blockquote: ({ children }) => (
    <blockquote className="border-l-4 pl-4 italic text-raisin-black dark:text-crystal-bell">
      {children}
    </blockquote>
  ),
  a: ({ href, children }) => (
    <a
      href={href}
      className="text-violet-blue hover:underline"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ),

  table({ children }) {
    return (
      <div className="overflow-x-auto w-full mb-1">
        <table className="table-auto border-collapse  w-full">{children}</table>
      </div>
    );
  },
  tematicBreak() {
    return (
      <hr className="border-t border-violet-blue dark:border-tropical-indigo my-6" />
    );
  },
  tr({ children }) {
    return <tr className="border-violet-blue border-b ">{children}</tr>;
  },
  td({ children }) {
    return (
      <td className="p-2 border-b border-violet-blue dark:border-tropical-indigo">
        {children}
      </td>
    );
  },
  th({ children }) {
    return (
      <th className="p-2 border-b-2 border-violet-blue dark:border-tropical-indigo font-semibold text-left">
        {children}
      </th>
    );
  },
  em: ({ children }) => <em className="italic">{children}</em>,
  strong: ({ children }) => <strong className="font-bold">{children}</strong>,
  del: ({ children }) => <del className="line-through">{children}</del>,
  hr: () => (
    <hr className="border-t border-cool-gray dark:border-quicksilver my-6" />
  ),
  img: ({ src, alt }) => (
    <img src={src} alt={alt} className="max-w-full my-4" />
  ),
};
