function SvgComponent({ fillColor = '#E78D66', ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="8" fill={fillColor} />
      <g clipPath="url(#clip0_325_16226)">
        <path d="M18.5456 5H15.6365V7.79672H18.5456V5Z" fill="#1F1F1E" />
        <path d="M19.9999 5H17.0908V7.79672H19.9999V5Z" fill="white" />
        <path d="M6.90909 5H4V7.79672H6.90909V5Z" fill="#1F1F1E" />
        <path d="M6.90909 7.79672H4V10.5934H6.90909V7.79672Z" fill="#1F1F1E" />
        <path d="M6.90909 10.5934H4V13.3902H6.90909V10.5934Z" fill="#1F1F1E" />
        <path d="M6.90909 13.3902H4V16.1869H6.90909V13.3902Z" fill="#1F1F1E" />
        <path d="M6.90909 16.1869H4V18.9836H6.90909V16.1869Z" fill="#1F1F1E" />
        <path d="M8.36368 5H5.45459V7.79672H8.36368V5Z" fill="white" />
        <path
          d="M19.9999 7.79672H17.0908V10.5934H19.9999V7.79672Z"
          fill="white"
        />
        <path
          d="M8.36368 7.79672H5.45459V10.5934H8.36368V7.79672Z"
          fill="white"
        />
        <path
          d="M15.6364 7.79672H12.7273V10.5934H15.6364V7.79672Z"
          fill="#1F1F1E"
        />
        <path
          d="M17.091 7.79672H14.1819V10.5934H17.091V7.79672Z"
          fill="white"
        />
        <path
          d="M11.2726 7.79672H8.36353V10.5934H11.2726V7.79672Z"
          fill="white"
        />
        <path
          d="M14.1818 10.5934H11.2727V13.3902H14.1818V10.5934Z"
          fill="white"
        />
        <path
          d="M17.091 10.5934H14.1819V13.3902H17.091V10.5934Z"
          fill="white"
        />
        <path
          d="M11.2726 10.5934H8.36353V13.3902H11.2726V10.5934Z"
          fill="white"
        />
        <path
          d="M12.7272 13.3902H9.81812V16.1869H12.7272V13.3902Z"
          fill="#1F1F1E"
        />
        <path
          d="M14.1818 13.3902H11.2727V16.1869H14.1818V13.3902Z"
          fill="white"
        />
        <path
          d="M19.9999 10.5934H17.0908V13.3902H19.9999V10.5934Z"
          fill="white"
        />
        <path
          d="M8.36368 10.5934H5.45459V13.3902H8.36368V10.5934Z"
          fill="white"
        />
        <path
          d="M18.5456 13.3902H15.6365V16.1869H18.5456V13.3902Z"
          fill="#1F1F1E"
        />
        <path
          d="M19.9999 13.3902H17.0908V16.1869H19.9999V13.3902Z"
          fill="white"
        />
        <path
          d="M18.5456 16.1869H15.6365V18.9836H18.5456V16.1869Z"
          fill="#1F1F1E"
        />
        <path
          d="M8.36368 13.3902H5.45459V16.1869H8.36368V13.3902Z"
          fill="white"
        />
        <path
          d="M19.9999 16.1869H17.0908V18.9836H19.9999V16.1869Z"
          fill="white"
        />
        <path
          d="M8.36368 16.1869H5.45459V18.9836H8.36368V16.1869Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_325_16226">
          <rect
            width="16"
            height="14"
            fill="white"
            transform="translate(4 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
