import { useState } from 'react';
// Icons
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
// MUI
import { Switch } from '@mui/material';
// Toast
import toast from 'react-hot-toast';
//New file icons
import FilePDF from 'Theme/icons/fileIcons/filepdf';
import FileWord from 'Theme/icons/fileIcons/fileword';
import FileExcel from 'Theme/icons/fileIcons/fileexcel';
import FilePowerPoint from 'Theme/icons/fileIcons/filepowerpoint';
import FileVideo from 'Theme/icons/fileIcons/filevideo';
import FileMusic from 'Theme/icons/fileIcons/musicfile';
import WebFile from 'Theme/icons/fileIcons/webfile';
import FileText from 'Theme/icons/fileIcons/textfile';

const AttachedFiles = ({ files, onFileChange, setShowFileDialog }) => {
  const [showMultiFileToggled, setShowMultiFileToggled] = useState(false);

  const getLabelMinutes = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;
    let minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
    let secondsStr =
      remainingSeconds < 10
        ? '0' + remainingSeconds
        : remainingSeconds.toString();
    return minutesStr + ':' + secondsStr;
  };

  const getIconFile = (type, file) => {
    let iconComponent;

    switch (type) {
      case 'pdf':
        iconComponent = (
          <div className="mx-2 mt-1  h-7 w-7">
            <FilePDF color={'#5256A6'} />
          </div>
        );
        break;
      case 'youtube':
        iconComponent = (
          <img src={file.metadata.thumbnail} className="w-16 h-10 mt-1"></img>
        );
        break;
      case 'webpage':
        iconComponent = (
          <div className="mx-2 mt-1  h-7 w-7">
            <WebFile color={'#5256A6'} />
          </div>
        );
        break;
      case 'docx':
        iconComponent = (
          <div className="mx-2 mt-1 h-7 w-7">
            <FileWord color={'#5256A6'} />
          </div>
        );
        break;
      case 'pptx':
        iconComponent = (
          <div className="mx-2 mt-1  h-7 w-7">
            <FilePowerPoint color={'#5256A6'} />
          </div>
        );
        break;
      case 'xlsx':
        iconComponent = (
          <div className="mx-2 mt-1 h-7 w-7">
            <FileExcel color={'#5256A6'} />
          </div>
        );
        break;
      case 'txt':
        iconComponent = (
          <div className="mx-2 mt-1 h-7 w-7">
            <FileText color={'#5256A6'} />
          </div>
        );
        break;
      case 'mp3':
        iconComponent = (
          <div className="mx-2 mt-1 h-7 w-7">
            <FileMusic color={'#5256A6'} />
          </div>
        );
        break;
      case 'mp4':
        iconComponent = (
          <div className="mx-2 mt-1  h-7 w-7">
            <FileVideo color={'#5256A6'} />
          </div>
        );
        break;
      case 'json':
        iconComponent = (
          <div className="mx-2 mt-1 h-7 w-7">
            <FileText color={'#5256A6'} />
          </div>
        );
        break;
      case 'html':
        iconComponent = (
          <div className="mx-2 mt-1 h-7 w-7">
            <FileText color={'#5256A6'} />
          </div>
        );
        break;
      case 'csv':
        iconComponent = (
          <div className="mx-2 mt-1 h-7 w-7">
            <FileText color={'#5256A6'} />
          </div>
        );
        break;
      default:
        iconComponent = <div>N</div>;
    }

    return iconComponent;
  };

  const changeStatus = (file) => {
    onFileChange((prev) =>
      prev.map((f) =>
        f.file === file.file ? { ...file, enabled: !file.enabled } : f
      )
    );
    toast.success(!file.enabled ? 'Enabled attachment' : 'Disable attachment');
  };

  return (
    <>
      {files && (
        <div className="mx-3 px-3 py-2 w-[calc(100%_-_1.5rem)] bg-lavender z-10 flex rounded-b-md justify-between">
          <div className="flex w-full">
            {files.length > 1 && (
              <div
                className="cursor-pointer"
                onClick={() => setShowMultiFileToggled(!showMultiFileToggled)}
              >
                {!showMultiFileToggled && (
                  <div className="text-raisin-black">
                    <ChevronRightIcon className="h-5 w-5" />
                  </div>
                )}
                {showMultiFileToggled && (
                  <div className="text-raisin-black">
                    <ChevronDownIcon className="h-5 w-5" />
                  </div>
                )}
              </div>
            )}
            <div className="flex flex-col w-full">
              {files.length > 1 && (
                <div className="text-raisin-black ml-2">
                  <p className="text-xs mt-0.5">
                    Currently querying on multiple attachments
                  </p>
                </div>
              )}
              {/* Files */}
              <div className="flex flex-col">
                {files &&
                  (showMultiFileToggled || files.length == 1) &&
                  files.map((file) => (
                    <div
                      className="pr-3 py-2 w-100 bg-lavender z-10 flex rounded-b-md justify-between"
                      key={file.metadata._id}
                    >
                      <div
                        className="cursor-pointer"
                        onClick={() => setShowFileDialog(true)}
                      >
                        {getIconFile(file.metadata.type, file)}
                      </div>
                      <div className="text-violet-blue ml-2 flex-grow">
                        <a
                          className="font-medium text-sm"
                          target="_blank"
                          href={file.metadata.url}
                          rel="noreferrer"
                        >
                          {file.metadata.name}
                        </a>
                        {file.metadata.type == 'youtube' ? (
                          <p className="text-xs -mt-1 font-thin">
                            {getLabelMinutes(file.metadata.size)} |{' '}
                            {file.metadata.channel}
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <Switch
                        size="small"
                        color="primary"
                        checked={file.enabled}
                        onChange={() => changeStatus(file)}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AttachedFiles;
