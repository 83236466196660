import useTemplateModalStore from 'Components/Chat/TemplateModal/store';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';

const useGetTemplateData = (store) => {
  const location = useLocation();
  const navigate = useNavigate();
  // STORE
  const { setTemplateData, setTemplateDataLoading, setOpenModal } =
    useTemplateModalStore((state) => state);

  const deleteQueryParams = () => {
    const params = new URLSearchParams(location.search);
    params.delete('selectedTool');

    navigate(
      {
        pathname: location.pathname,
        search: params.toString(),
      },
      { shallow: true }
    );
  };

  const getTemplateData = async (templateId) => {
    setTemplateDataLoading(true);

    try {
      setOpenModal(true);

      const res = await store.api.get(`/v2/templates/${templateId}`);
      const { data } = res.data;

      if (!data) {
        throw new Error('Template not found');
      } else {
        setTemplateData(data);
      }

      setTemplateData(data ?? null);
    } catch (error) {
      //delete query params
      deleteQueryParams();
      toast.error(
        'Error when try to open the template, please try again later 🥲'
      );
    } finally {
      setTemplateDataLoading(false);
    }
  };

  useEffect(() => {
    const hash = new URLSearchParams(location.search).get('selectedTool');
    if (hash) {
      getTemplateData(hash);
    } else {
      setTemplateData(null);
      setOpenModal(false);
      setTemplateDataLoading(false);
    }
  }, [location.search]);

  return getTemplateData;
};

export default useGetTemplateData;
