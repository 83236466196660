import StraicoIcon from '../../../Theme/icons/straico';

const SidebarComponent = ({ ...props }) => {
  return (
    <>
      <div className="bg-seasalt dark:bg-lead sticky top-0 z-40 font-figtree">
        <div className="flex mx-auto select-none">
          <div className="flex-1 flex md:justify-start py-6 px-3">
            <StraicoIcon className="fill-nue-blue" />
          </div>
        </div>
      </div>
      {props.children}
    </>
  );
};

export default SidebarComponent;
