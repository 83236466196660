/* eslint-disable no-control-regex */
export const countWords = (text) => {
  if (typeof text !== 'string') {
    return 0;
  }
  // eslint-disable-next-line no-control-regex
  let words = text.replace(/[^\x00-\xFF]/g, (c) => {
    const charCode = c.charCodeAt(0);

    // Removes ZERO WIDTH NO-BREAK SPACE and "General Punctation Chars"
    // http://www.unicodemap.org/range/40/General_Punctuation/
    if (charCode === 65279 || (charCode >= 0x2007 && charCode <= 0x206f)) {
      return '';
    }
    // Remove unicode spaces
    if (charCode === 0x00a0) {
      return '';
    }
    // hex charcode with 4 positions fixed(0 padding)
    return `0x${(charCode + 0x10000).toString(16).substr(-4).toUpperCase()}`;
  });

  words = words.replace(/&nbsp;/g, ''); // Remove &nbsp;
  words = words.replace(/\s\s+/g, ' '); // Remove double spaces
  words = words.replace(/(<[^>]*>)|([.,;:?!]+)/g, ' '); // HTML tags and punctuation

  // Removes other chars, except for "one letter words(Special Chars)"
  const charRegex =
    /\[.?\]|\{.?\}|([^\s\wàÀáÁåÅæÆèÈéÉÈíÍöÖøØőŐвВєЄжЖзЗиИйЙкКўЎяЯ]|_)/g;
  words = words.replace(charRegex, '').trim();

  if (words) {
    return words
      .split(/\s+/)
      .map((token) =>
        token.replace(/0x[0-9A-Fa-f]{4}/g, (c) => String.fromCharCode(+c))
      ).length;
  }

  return 0;
};
