import { create } from 'zustand';

const useShareLinkTool = create((set) => ({
  shareLink: null,

  // Functions
  setShareLink: (shareLink) => {
    set(() => ({
      shareLink: shareLink,
    }));
  },
}));

export default useShareLinkTool;
