import { create } from 'zustand';

const useSmartModeStore = create((set) => ({
  smartMode: localStorage.getItem('smartMode') || 'off',
  hasUsedSmartMode: false,
  dontAskUndoOptimization:
    localStorage.getItem('dontAskUndoOptimization') === 'true' || false,

  setSmartMode: (mode) => {
    localStorage.setItem('smartMode', mode);
    set({ smartMode: mode });
  },
  setHasUsedSmartMode: (value) => set({ hasUsedSmartMode: value }),
  setDontAskUndoOptimization: (value) => {
    localStorage.setItem('dontAskUndoOptimization', value);
    set({ dontAskUndoOptimization: value });
  },
}));

export default useSmartModeStore;
