import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { inject, observer } from 'mobx-react';

function ActivateAppsumo({ store }) {
  const [email, setEmail] = useState('-');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [active, setActive] = useState(false);
  const [passwordOne, setPasswordOne] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const [, setErrorMessage] = useState('');
  const [, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    checkCode({ store });
  }, []);

  const checkCode = async ({ store }) => {
    try {
      await store.api
        .get('/appsumo/validate-code', {
          params: {
            code: location.pathname.split('/').pop(),
          },
        })
        .then(({ data }) => {
          setActive(true);
          setEmail(data.email);
        });
    } catch (err) {
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error);
      }
    }
  };

  const onChangeAny = (val, attr) => {
    switch (attr) {
      case 'fname':
        setFname(val);
        break;
      case 'lname':
        setLname(val);
        break;
      case 'passwordOne':
        setPasswordOne(val);
        break;
      case 'passwordTwo':
        setPasswordTwo(val);
        break;
      default:
        break;
    }
    setErrorMessage('');
  };

  const onActivate = async (e) => {
    e.preventDefault();

    if (!fname) {
      toast.error('First name required');
      return;
    }

    if (!lname) {
      toast.error('Last name required');
      return;
    }

    if (passwordOne == '' || passwordOne == ' ') {
      toast.error('Password required');
      return;
    }

    if (passwordOne !== passwordTwo) {
      toast.error('Both passwords must be the same');
      return;
    }

    try {
      setSuccessMessage('');
      setErrorMessage('');

      await store.api
        .post('/appsumo/activate-account', {
          email: email,
          fname: fname,
          lname: lname,
          password: passwordOne,
        })
        .then(({ data }) => {
          store.loginWithDataTokenAndProfile(data);
          toast.success('User activated');
          navigate('/my-profile/pricing');
        });
    } catch (err) {
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Sumo-ling - Straico`}</title>
      </Helmet>

      <div className="h-screen flex flex-col">
        <header className="h-auto bg-nue-blue font-medium text-2xl py-3">
          <NavLink to="/login">
            <img
              className="h-7 pl-4 -mt-1"
              src="/svg/logo-snow-100.svg"
              alt="Logo"
            />
          </NavLink>
        </header>

        <div className="lg:grid lg:grid-cols-3 flex-1 bg-seasalt h-full overflow-auto">
          {/* Image container */}
          <div className="login-banner-wrapper bg-ghost-white h-full lg:col-span-2">
            <LazyLoadImage
              className="h-[calc(100vh_-_48px)] w-full object-cover"
              src="/images/straico-wall-16.png"
              placeholderSrc="/images/straico-wall-16-blur.png"
              effect="blur"
            />
          </div>
          {/* Forms container */}
          <div className="flex items-center justify-center mt-8 lg:mt-0 font-figtree text-raisin-black overflow-auto">
            {active ? (
              <form className="pt-36" onSubmit={onActivate}>
                {/* Logos */}
                <div className="flex justify-center mb-8">
                  <div className="flex-grow-1 w-1/8"></div>
                  <div className="flex-grow-1 w-1/8"></div>
                  <div className="flex-grow-1 w-1/8">
                    <img
                      src="/svg/taco-symbol.svg"
                      className="h-9"
                      alt="Taco"
                    />
                  </div>
                  <div className="flex-grow-1 w-1/8 mx-2"></div>
                  <div className="flex-grow-1 w-1/8">
                    <img
                      src="/svg/logo-symbol-crayola-600.svg"
                      className="h-10"
                      alt="Crayola Logo"
                    />
                  </div>
                  <div className="flex-grow-1 w-1/8"></div>
                  <div className="flex-grow-1 w-1/8"></div>
                  <div className="flex-grow-1 w-1/8"></div>
                </div>
                {/* Header */}
                <div className="text-center mt-2">
                  <div className="text-3xl font-semibold text-center">
                    Welcome to your
                  </div>
                  <div className="text-3xl font-semibold text-center">
                    <span className="text-seasalt">{'.... '}</span>
                    <span className="capitalize">Straico</span> adventure
                    <span className="text-seasalt">{' ....'}</span>
                  </div>
                  <div className="text-3xl font-semibold text-center">
                    <span className="">Sumo-ling!</span> 🙂
                  </div>
                  <p className="text-base text-cool-gray my-7">
                    Please, drop your info here.
                  </p>
                  <div className="mb-4">
                    <label className="text-cool-gray uppercase text-xs block mt-6 text-left font-light tracking-wider">
                      Email Address
                    </label>
                    <input
                      disabled
                      value={email}
                      type="email"
                      className="bg-white mt-1 border border-seasalt font-regular focus:border-violet-blue w-full py-2 px-3 text-base leading-tight rounded-md shadow-sm disabled"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="text-cool-gray uppercase text-xs block mt-6 text-left font-light tracking-wider">
                      First name <span className="text-violet-blue">*</span>
                    </label>
                    <input
                      value={fname}
                      onChange={(e) => onChangeAny(e.target.value, 'fname')}
                      type="text"
                      className="bg-white mt-1 border border-seasalt font-regular focus:border-violet-blue w-full py-2 px-3 text-base leading-tight rounded-md shadow-sm"
                      placeholder="Samantha"
                      style={{ backgroundColor: 'white' }}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="text-cool-gray uppercase text-xs block mt-6 text-left font-light tracking-wider">
                      Last name <span className="text-violet-blue">*</span>
                    </label>
                    <input
                      value={lname}
                      onChange={(e) => onChangeAny(e.target.value, 'lname')}
                      type="text"
                      className="bg-white mt-1 border border-seasalt font-regular focus:border-violet-blue w-full py-2 px-3 text-base leading-tight rounded-md shadow-sm"
                      placeholder="Tacomo"
                      style={{ backgroundColor: 'white' }}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="text-cool-gray uppercase text-xs block mt-6 text-left font-light tracking-wider">
                      Set your password{' '}
                      <span className="text-violet-blue">*</span>
                    </label>
                    <input
                      value={passwordOne}
                      onChange={(e) =>
                        onChangeAny(e.target.value, 'passwordOne')
                      }
                      type="password"
                      className="bg-white mt-1 border border-seasalt font-regular focus:border-violet-blue w-full py-2 px-3 text-base leading-tight rounded-md shadow-sm"
                      placeholder="******"
                      style={{ backgroundColor: 'white' }}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="text-cool-gray uppercase text-xs block mt-6 text-left font-light tracking-wider">
                      Confirm your password{' '}
                      <span className="text-violet-blue">*</span>
                    </label>
                    <input
                      value={passwordTwo}
                      onChange={(e) =>
                        onChangeAny(e.target.value, 'passwordTwo')
                      }
                      type="password"
                      className="bg-white mt-1 border border-seasalt font-regular focus:border-violet-blue w-full py-2 px-3 text-base leading-tight rounded-md shadow-sm"
                      placeholder="******"
                      style={{ backgroundColor: 'white' }}
                    />
                  </div>
                  <div className="flex flex-col mt-8 mb-8">
                    <button
                      type="submit"
                      className="bg-nue-blue hover:bg-lavender text-white font-bold py-2 px-4 rounded-md uppercase flex items-center justify-center"
                    >
                      <div className="flex items-center">
                        <img
                          src="/svg/logo-symbol-snow-100.svg"
                          className={`cursor-pointer duration-500 h-5 w-5`}
                          alt=""
                        />
                        <span className="ml-1">Activate</span>
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <div>
                <div
                  className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-lavender mt-8 lg:mt-0`}
                >
                  <ArchiveBoxXMarkIcon
                    className={`h-8 w-8 text-violet-blue`}
                    aria-hidden="true"
                  />
                </div>

                <div className="mt-3 text-center ">
                  <div className="text-3xl font-bold mb-1 text-center uppercase">
                    Wrong information
                  </div>
                  <p className="text-base text-cool-gray">
                    Misspelled code or it expired
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default inject('store')(observer(ActivateAppsumo));
