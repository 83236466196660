import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
// Mobx
import { inject, observer } from 'mobx-react';
// Router
import Loader from 'Components/Loader';
import { toastReward } from 'utils/toastCall';
import Subheader from '../Components/Subheader';

const Images = inject('store')(
  observer((props) => {
    const iframeRef = useRef();
    const [done, setDone] = useState(false);

    useEffect(() => {
      props.store.listenEvent('pricing_dialog', () => {
        toastReward({
          title: 'Images sold out 😭',
          message: 'Recharge your account for more coins',
          linkText: "It's quick and easy. Click here!",
          linkUrl: '/my-profile/pricing',
          image: '/gifs/growing.gif',
          time: 10000,
        });
      });
    }, []);

    const reloadIframe = () => {
      iframeRef.current.src += '';
    };

    const handleIframeLoad = () => {
      // unblock the back button
      window.history.pushState(null, '', window.location.href);
      window.addEventListener('popstate', function () {
        window.history.pushState(null, '', window.location.href);
      });
      if (!done) {
        const timer = setTimeout(() => {
          reloadIframe(true);
          setDone(true);
        }, 100);
        return () => clearTimeout(timer);
      }
    };

    return (
      <div className="h-[calc(100%-48px)]">
        {/* Header */}
        <Helmet>
          <title>{`Images - Straico`}</title>
        </Helmet>
        <Subheader title="Images" icon="PhotoIcon" />
        {/* End of header */}
        {/* Iframe of stability */}
        {/* TODO: use .env variables to this */}
        {!done && (
          <div className="w-full h-[calc(100%-48px)]">
            <div className="w-full h-full flex justify-center items-center">
              <Loader />
            </div>
          </div>
        )}
        <iframe
          ref={iframeRef}
          // src={`http://localhost:3001?token=${localStorage.getItem('token')}`}
          src={`https://images.straico.com?token=${localStorage.getItem(
            'token'
          )}`}
          title="Image"
          data-hj-allow-iframe="true"
          className={`w-full h-[calc(100%-48px)] ${done ? '' : 'hidden'}}`}
          onLoad={handleIframeLoad}
        />
      </div>
    );
  })
);

export default Images;
