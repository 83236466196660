import { create } from 'zustand';

const useTreeDNDChatStore = create((set) => ({
  page: 1,
  setPage: (page) => set({ page }),
  treeData: [],
  setTreeData: (treeData) => set({ treeData }),
  totalPage: 0,
  setTotalPage: (totalPage) => set({ totalPage }),
  loading: false,
  setLoading: (loading) => set({ loading }),
  showSearch: false,
  setShowSearch: (showSearch) => set({ showSearch }),
  search: '',
  setSearch: (search) => set({ search }),
  openDeleteChatModal: false,
  setOpenDeleteChatModal: (openDeleteChatModal) => set({ openDeleteChatModal }),
  openShareChatModal: false,
  setOpenShareChatModal: (openShareChatModal) => set({ openShareChatModal }),
  toDelete: null,
  setToDelete: (toDelete) => set({ toDelete }),
  selectedChatIds: [], // Existing state for selected chat IDs
  setSelectedChatIds: (selectedChatIds) => set({ selectedChatIds }),
  deleteChatsModalOpen: false, // New state for the bulk delete modal
  setDeleteChatsModalOpen: (deleteChatsModalOpen) =>
    set({ deleteChatsModalOpen }),
  cleanTreeData: () => set({ treeData: [] }),
}));

export default useTreeDNDChatStore;
