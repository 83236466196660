import {
  ListBulletIcon,
  XMarkIcon,
  Bars3Icon,
  PhotoIcon,
  PaperClipIcon,
  ClipboardDocumentIcon,
  PencilSquareIcon,
  EyeIcon,
} from '@heroicons/react/24/solid';
// MUI
import { Tooltip } from '@mui/material';
// Hooks
import useClipboardComponent from 'Hooks/useClipboardComponent';
// Toast
import { toast } from 'react-hot-toast';
// Components
import useThemeStore from 'Theme/store';

const CustomItemVariable = ({
  name,
  type,
  options,
  variable,
  onDelete,
  dontShowCopyField = false,
  onEdit,
  onSee,
}) => {
  const handleDelete = () => {
    onDelete && onDelete();
  };

  const handleSee = () => {
    onSee && onSee();
  };

  const { theme } = useThemeStore();

  const ICON_RENDER = {
    dropdown: (
      <ListBulletIcon
        className={`w-4 h-4 ${
          theme == 'dark' ? 'text-ghost-white' : 'text-cool-gray'
        }`}
      />
    ),
    text: (
      <Bars3Icon
        className={`w-4 h-4 ${
          theme == 'dark' ? 'text-ghost-white' : 'text-cool-gray'
        }`}
      />
    ),
    image: (
      <PhotoIcon
        className={`w-4 h-4 ${
          theme == 'dark' ? 'text-ghost-white' : 'text-cool-gray'
        }`}
      />
    ),
    attachment: (
      <PaperClipIcon
        className={`w-4 h-4 ${
          theme == 'dark' ? 'text-ghost-white' : 'text-cool-gray'
        }`}
      />
    ),
  };

  const { copy } = useClipboardComponent();
  const handleCopy = () => {
    if (copy(`{{${variable}}}`)) {
      toast.success('Variable copied');
    } else {
      toast.error('Error copying variable');
    }
  };

  return (
    <div className="flex justify-between gap-3 font-figtree">
      <div
        className={`flex gap-1 items-center ${
          theme == 'dark' ? 'bg-sonic-silver' : 'bg-platinum'
        } rounded-md px-2 w-fit hover:opacity-90`}
      >
        {ICON_RENDER[type] ?? (
          <ListBulletIcon
            className={`w-4 h-4 ${
              theme == 'dark' ? 'text-crystal-bell' : 'text-cool-gray'
            }`}
          />
        )}
        <Tooltip title={name} placement="top">
          <p
            className={`${
              theme == 'dark' ? 'text-crystal-bell' : 'text-cool-gray'
            }   text-sm max-w-[80px] truncate`}
          >
            {`${name} ${
              type === 'dropdown' ? `(${options?.length || 0})` : ''
            }`}
          </p>
        </Tooltip>
        {onEdit && (
          <PencilSquareIcon
            className={`w-4 h-4 cursor-pointer ${
              theme == 'dark' ? 'text-crystal-bell' : 'text-cool-gray'
            }`}
            onClick={onEdit}
          />
        )}
        {onDelete && (
          <XMarkIcon
            className={`w-4 h-4 cursor-pointer ${
              theme == 'dark' ? 'text-crystal-bell' : 'text-cool-gray'
            }`}
            onClick={handleDelete}
          />
        )}
        {onSee && (
          <EyeIcon
            className={`w-4 h-4 cursor-pointer ${
              theme == 'dark' ? 'text-crystal-bell' : 'text-cool-gray'
            }`}
            onClick={handleSee}
          />
        )}
      </div>
      {!dontShowCopyField && (
        <div className="flex items-center mr-4">
          <input
            className={`border bg-transparent mr-2 border-platinum border-solid text-sm p-2 rounded-md ${
              theme == 'dark' ? 'bg-cool-gray' : 'bg-white'
            }`}
            type="text"
            value={`{{${variable}}}`}
            readOnly
          />
          <Tooltip title="Copy variable to clipboard">
            <ClipboardDocumentIcon
              className={`w-4 h-4 cursor-pointer ${
                theme == 'dark' ? 'text-crystal-bell' : 'text-raisin-black'
              }`}
              onClick={handleCopy}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default CustomItemVariable;
