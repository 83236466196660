export function parseQueryString(queryString, removeParam = null) {
  const params = new URLSearchParams(queryString);
  const result = {};

  for (const [key, value] of params) {
    if (value !== undefined && value !== null && key !== removeParam) {
      result[key] = value;
    }
  }

  return result;
}
