import { DndProvider } from 'react-dnd';
import { MultiBackend, getBackendOptions } from '@minoru/react-dnd-treeview';

const TreeDNDChatProvider = ({ children }) => {
  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      {children}
    </DndProvider>
  );
};

export default TreeDNDChatProvider;
