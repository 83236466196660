import { useEffect, useState } from 'react';
// MUI
import { Button, IconButton } from '@mui/material';
// Mobx
import { inject, observer } from 'mobx-react';
// Store
import useCreateTemplatesStore from 'Features/CreateTemplatesForm/Store';
// RHF
import { useFormContext } from 'react-hook-form';
// Icons
import {
  CheckCircleIcon,
  EyeIcon,
  PaperClipIcon,
  VariableIcon,
} from '@heroicons/react/20/solid';
// Utils
import FileContext, {
  formatedDate,
  getIconFile,
  getLabel,
} from 'Components/FileContext';
import { toCamelCase } from 'Features/CreateTemplatesForm/Utils/functions';
import { toast } from 'react-hot-toast';
import { cn } from 'utils/styles';
// Components
import TextField from '@mui/material/TextField';
import Tab from 'Components/Common/Tab';
import InfiniteScroll from 'react-infinite-scroll-component';
import useThemeStore from 'Theme/store';
import CommonModal from '../CommonModal/CommonModal';

// Constants
const YOUTUBEURLPATTERN =
  /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/i;

const VariableAttachment = () => {
  const { modalAttachment, setModalAttachment } = useCreateTemplatesStore();
  const handleClose = () => setModalAttachment(false);
  const { theme } = useThemeStore();
  // Local form
  const INITIAL_FORM = {
    name: '',
  };
  const [localForm, setLocalForm] = useState(INITIAL_FORM);
  const handleChange = (e) =>
    setLocalForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  // RHF
  const { setValue, watch } = useFormContext();
  // Handle Submit
  const handleSubmit = () => {
    if (
      watch('variables').filter(
        (i) => i.variable === toCamelCase(localForm.name)
      ).length > 0
    ) {
      toast.error(
        'Variable name already exists, please use a different name.',
        {
          icon: '🚨',
        }
      );
      return;
    }

    if (localForm.name !== '') {
      setValue('variables', [
        ...watch('variables'),
        {
          ...localForm,
          type: 'attachment',
          variable: toCamelCase(localForm.name),
        },
      ]);
      handleClose();
    } else {
      toast.error('To add attachment context, fill the field name', {
        icon: '🚨',
      });
    }
  };

  useEffect(() => {
    setLocalForm(INITIAL_FORM);
  }, [modalAttachment]);

  return (
    <>
      <div className="flex flex-col gap-6 pt-4">
        <p
          className={`font-barlow text-sm font-[400] ${
            theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
          }`}
        >
          Choosing a <strong>variable attachment field</strong> means that each
          time you run this template, you get to pick a different file or media
          to join the journey. Keep things fresh!
        </p>
        <div className="flex flex-col gap-2">
          <label
            className={`uppercase text-[12px] font-medium ${
              theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
            }`}
          >
            Field name *
          </label>
          <input
            value={localForm.name}
            onChange={handleChange}
            name="name"
            className={`w-full border border-snow-900 border-solid text-sm p-[8px] font-barlow rounded-md ${
              theme == 'dark'
                ? 'dark:bg-graphite-900 dark:text-white'
                : 'bg-snow-100 text-graphite-900'
            }`}
            placeholder="Name of your variable"
          />
        </div>
      </div>
      <div className="w-full flex justify-end mt-6">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </>
  );
};

const SkeletonCard = () => {
  const { theme: themeValue } = useThemeStore();

  return (
    <div
      className={`w-md flex w-full items-center justify-between flex-row ${
        themeValue == 'dark' ? 'bg-graphite-900' : 'bg-white'
      } rounded-lg overflow-hidden`}
      style={{ paddingLeft: '1.3rem' }}
    >
      <div className="bg-snow-600 dark:bg-graphite-700 h-8 w-10 animate-pulse"></div>
      <div className="px-4 py-2 w-full">
        <div className="bg-snow-600 dark:bg-graphite-700 h-4 w-1/2 mb-2 animate-pulse"></div>
        <div className="bg-snow-600 dark:bg-graphite-700 h-4 w-1/3 animate-pulse"></div>
      </div>
    </div>
  );
};

const FixedAttachment = inject('store')(
  observer(({ store }) => {
    const [fileSelected, setFileSelected] = useState(null);
    const [files, setFiles] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [open, setOpen] = useState(false);

    const { theme } = useThemeStore();

    const [searchTerm, setSearchTerm] = useState('');

    const [hasMore, setHasMore] = useState(true);

    const [page, setPage] = useState(1);

    const handleInputChange = (e) => {
      setSearchTerm(e.target.value);
      setFileSelected(null);
      setSelectedIndex(null);
    };

    const handleNext = () => {
      fetchFiles(page + 1);
    };

    const scrollToTop = () => {
      const container = document.getElementsByClassName(
        'infinite-scroll-component'
      )[0];
      if (container) {
        container.scrollTop = 0;
      }
    };

    const handleClose = () => setOpen(false);

    const { setModalAttachment, modalAttachment } = useCreateTemplatesStore();

    // RHF
    const { setValue, watch } = useFormContext();

    const handleSubmit = () => {
      if (!fileSelected) {
        toast.error('Please select a file to continue.', {
          icon: '🚨',
        });
        return;
      }

      setValue('files', [...watch('files'), fileSelected]);
      setModalAttachment(false);
      setSelectedIndex(null);
      setFileSelected(null);
    };

    const handleFileContextModal = () => setOpen(true);

    const fetchFiles = async (page) => {
      try {
        setPage(page);
        const response = await store.api.get(`/file/imageless`, {
          params: {
            page: page,
            search: searchTerm,
          },
        });
        if (response.data.success) {
          if (response.data.pagination.currentSearch) {
            if (page == 1) {
              setFiles(response.data.files);
            } else {
              setFiles([...files, ...(response.data.files || [])]);
            }
          } else {
            if (page == 1) {
              setFiles(response.data.files);
            } else {
              setFiles([...files, ...(response.data.files || [])]);
            }
          }
          setHasMore(response.data.pagination.next);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    useEffect(() => {
      fetchFiles(1);
      scrollToTop();
    }, [searchTerm, modalAttachment, open]);

    useEffect(() => {
      if (!modalAttachment) {
        setSelectedIndex(null);
        setFileSelected(null);
        setSearchTerm('');
      }
    }, [modalAttachment]);

    // File Context
    const handleBadInteraction = () => {};
    const handleInteraction = async (e) => {
      const response = await addReferenceFile(e[0]);

      setValue('files', [...watch('files'), response?.data?.file]);
      setModalAttachment(false);
      setSelectedIndex(null);
      setFileSelected(null);
      setOpen(false);
    };

    const addReferenceFile = async (file) => {
      const toastId = toast.loading('Uploading attachment...');
      try {
        let response;
        if (typeof file === 'string') {
          // Upload YouTube video first time
          if (YOUTUBEURLPATTERN.test(file)) {
            toast('The upload process can take some seconds. Please wait.', {
              icon: '⌛️',
            });
            response = await store.api.post(`/file/youtube`, {
              url: file,
              enabled: true,
            });
          } else {
            // Upload Web page first time
            response = await store.api.post(`/file/webpage`, {
              url: file,
              enabled: true,
            });
            if (response.data.file.words <= 10) {
              toast(
                'This web page has additional technology that unables us to access its content',
                {
                  icon: '⛔️',
                }
              );
              return;
            }
          }
        } else if (file.type == 'pdf' || file.type == 'application/pdf') {
          file.type = 'pdf';
          // Load previous pdf
          response = await store.api.post(`/file`, file);
          if (response.data.file.words === 0) {
            toast(
              `The PDF (${file.name}) you are inserting is empty or couldn't be read`,
              {
                icon: '⛔️',
              }
            );
            return;
          }
        } else if (file.type == 'image') {
          response = await store.api.post(`/file`, file);
        } else if (file.type == 'youtube') {
          response = await store.api.post(`/file/youtube`, file);
        } else if (file.type == 'webpage') {
          response = await store.api.post(`/file/webpage`, file);
          if (response.data.file.words <= 10) {
            toast(
              'This web page has additional technology that unables us to access its content',
              {
                icon: '⛔️',
              }
            );
            return;
          }
        } else if (
          file.type ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          file.type === 'docx'
        ) {
          file.type = 'docx';
          response = await store.api.post(`/file`, file);
        } else if (
          file.type ===
            'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
          file.type === 'pptx'
        ) {
          file.type = 'pptx';
          response = await store.api.post(`/file`, file);
        } else if (
          file.type ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.type === 'xlsx'
        ) {
          toast(
            'When querying an Excel Document file, formulas nor macros will be taken into account',
            {
              icon: '👀',
              duration: 12000, // 12 seconds in milliseconds
            }
          );
          file.type = 'xlsx';
          response = await store.api.post(`/file`, file);
        } else if (file.type === 'text/plain' || file.type === 'txt') {
          file.type = 'txt';
          response = await store.api.post(`/file`, file);
        } else if (file.type === 'audio/mpeg' || file.type === 'mp3') {
          file.type = 'mp3';
          response = await store.api.post(`/file/multimedia`, file);
        } else if (file.type === 'video/mp4' || file.type == 'mp4') {
          if (!('words' in file)) {
            toast('The upload process can take several minutes. Please wait.', {
              icon: '⌛️',
            });
          }
          file.type = 'mp4';
          response = await store.api.post(`/file/multimedia`, file);
        } else if (file.type === 'text/html' || file.type === 'html') {
          file.type = 'html';
          response = await store.api.post(`/file`, file);
        } else if (file.type === 'application/json' || file.type === 'json') {
          file.type = 'json';
          response = await store.api.post(`/file`, file);
        } else if (file.type === 'text/csv' || file.type === 'csv') {
          file.type = 'csv';
          response = await store.api.post(`/file`, file);
        } else {
          response = await store.api.post(`/file`, file);
        }
        return response;
      } catch (error) {
        if (error?.response?.data?.error?.message) {
          toast.error(error?.response?.data?.error?.message);
        }
      } finally {
        toast.dismiss(toastId);
      }
    };

    return (
      <>
        <div className="flex flex-col gap-6 pt-4">
          <p
            className={`font-barlow text-sm font-[400] ${
              theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
            }`}
          >
            A <strong>fixed attachment</strong> means that you add an attachment
            now, and it'll always be there, providing context whenever you use
            this template. Set it and forget it!
          </p>
          <div className="">
            <div
              className={`mb-2  font-lexend text-xs font-medium uppercase ${
                theme == 'dark' ? 'text-flax-900' : 'text-eggplant-700'
              }`}
            >
              Choose from your previous uploads
            </div>
            <TextField
              label="Search files"
              variant="outlined"
              value={searchTerm}
              onChange={handleInputChange}
              fullWidth
              sx={{ mt: 1, mb: 2 }}
              InputProps={{
                sx: {
                  height: '34px',
                  fontSize: '14px',
                  '& .MuiOutlinedInput-input': {
                    padding: '12px 14px',
                  },
                  '& .MuiInputLabel-outlined': {
                    transform: 'translate(14px, 10px) scale(1)',
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: '14px',
                  transform: 'translate(14px, 6px) scale(1)',
                  '&.MuiInputLabel-shrink': {
                    transform: 'translate(14px, -6px) scale(0.75)',
                  },
                },
              }}
            />
            <div className="max-h-64 overflow-y-scroll">
              {files.length > 0 && (
                <div className="max-h-64">
                  <InfiniteScroll
                    dataLength={files.length}
                    next={() => {
                      handleNext();
                    }}
                    height={145}
                    hasMore={hasMore}
                    loader={
                      <>
                        <div className="flex gap-3 flex-col mt-3 w-full ">
                          {[1, 2, 3, 4, 5, 6].map((index) => (
                            <SkeletonCard key={index} />
                          ))}
                        </div>
                      </>
                    }
                    endMessage={
                      <p className="text-center mt-6 mb-3 font-barlow dark:text-snow-100">
                        Congratulations, you've seen all your files!
                      </p>
                    }
                  >
                    {files.map((file, index) => (
                      <div
                        key={index}
                        className="p-1 border-b border-b-snow-900 relative"
                        onClick={() => {
                          if (!(selectedIndex === index)) {
                            setSelectedIndex(index);
                            setFileSelected({
                              ...files[index],
                              enabled: true,
                            });
                          } else {
                            setSelectedIndex(null);
                            setFileSelected(null);
                          }
                        }}
                      >
                        <div
                          className={`flex items-center cursor-pointer justify-between w-full ${
                            selectedIndex != null && selectedIndex === index
                              ? 'bg-vanilla-200 font-medium'
                              : 'font-normal'
                          } p-1 rounded`}
                        >
                          <div className="flex items-center w-full">
                            {!(selectedIndex === index) ? (
                              getIconFile(file.type, file)
                            ) : (
                              <div
                                className={
                                  'w-16 flex items-center justify-center'
                                }
                              >
                                <CheckCircleIcon
                                  color={'#E57A44'}
                                  className={'w-7 h-7'}
                                />
                              </div>
                            )}
                            <div className="ml-2 text-graphite-400 font-barlow text-sm">
                              <p
                                className={`block whitespace-nowrap truncate w-[120px] overflow-hidden ${
                                  theme == 'dark'
                                    ? 'dark:text-white'
                                    : 'text-graphite-900'
                                }`}
                              >
                                {file.name}
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center w-full justify-end">
                            <div
                              className={`hidden md:block ml-2 text-graphite-400 font-barlow text-sm ${
                                theme == 'dark'
                                  ? 'dark:text-white'
                                  : 'text-graphite-900'
                              }`}
                            >
                              {getLabel(file.type, file.size)}
                            </div>
                            <div
                              className={`hidden md:block ml-2 text-graphite-400 font-barlow text-sm ${
                                theme == 'dark'
                                  ? 'dark:text-white'
                                  : 'text-graphite-900'
                              }`}
                            >
                              {formatedDate(file.created, 'MMMM D, YYYY')}
                            </div>
                            <div
                              className={`ml-1 ${
                                theme == 'dark'
                                  ? 'dark:text-white'
                                  : 'text-graphite-900'
                              } font-barlow text-sm flex items-center gap-1`}
                            >
                              <IconButton
                                onClick={(e) => e.stopPropagation()}
                                component="a"
                                target="_blank"
                                href={file.url}
                              >
                                <EyeIcon className="w-6 h-6" />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              )}
            </div>
          </div>
          <p
            className={`font-barlow text-sm font-[400] ${
              theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
            }`}
          >
            Need to upload a file?{' '}
            <span
              onClick={handleFileContextModal}
              className="underline text-crayola-700 font-[600] cursor-pointer"
            >
              Click here
            </span>{' '}
            to add attachments to your library.
          </p>
        </div>
        <div className="w-full flex justify-end mt-6">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
        {open && (
          <FileContext
            onlySelectedOne
            defaultTab={'2'}
            models={[]}
            onInteraction={handleInteraction}
            onBadInteraction={handleBadInteraction}
            onClose={handleClose}
          />
        )}
      </>
    );
  })
);

const ModalAttachment = () => {
  const [tab, setTab] = useState(1);
  const { modalAttachment, setModalAttachment } = useCreateTemplatesStore();
  const handleClose = () => setModalAttachment(false);

  const handleChangeTab = (value) => {
    setTab(value);
  };

  // TABS
  const TABS = [
    {
      icon: <VariableIcon className="w-6 h-6" />,
      text: 'Variable',
      value: 1,
      render: () => <VariableAttachment />,
    },
    {
      icon: <PaperClipIcon className="w-6 h-6" />,
      text: 'Fixed',
      value: 2,
      render: () => <FixedAttachment />,
    },
  ];

  const { theme } = useThemeStore();

  return (
    <CommonModal
      sx={{
        minHeight: '200px',
      }}
      open={modalAttachment}
      handleClose={handleClose}
    >
      <div className="w-[350px] md:w-[400px]">
        <p
          className={`font-lexend line-clamp-1 font-bold uppercase text-md ${
            theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
          }`}
        >
          attachment Context
        </p>
        <p
          className={`my-2 font-lexend font-normal text-sm uppercase ${
            theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
          }`}
        >
          Select type of attachment *
        </p>
        <div className={cn('grid gap-1 mt-1', `grid-cols-${TABS.length ?? 1}`)}>
          {TABS.map((t, index) => (
            <Tab
              key={index}
              icon={t.icon}
              text={t.text}
              value={t.value}
              currentValue={tab}
              onClick={handleChangeTab}
            />
          ))}
        </div>
        <div className="w-full">
          {TABS.find((i) => i.value === tab).render()}
        </div>
      </div>
    </CommonModal>
  );
};

export default ModalAttachment;
