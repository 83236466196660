import { useState, useEffect } from 'react';
import { Button, SwipeableDrawer, Typography, Box } from '@mui/material';
import useCreateTemplatesStore from './Store';
import {
  PencilSquareIcon,
  SparklesIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import useThemeStore from 'Theme/store';

const ChooseTemplate = () => {
  const { setSelectedComponent, setOpenModal, openModal, selectedComponent } =
    useCreateTemplatesStore();
  const { theme } = useThemeStore();

  const handleSelectTemplate = (component) => {
    setSelectedComponent(component);
    setOpenModal(true);
  };

  const handleClose = () => setOpenModal(false);

  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (selectedComponent === null && openModal) {
      setDrawerOpen(true);
    } else {
      setDrawerOpen(false);
    }
  }, [selectedComponent, openModal]);

  return (
    <SwipeableDrawer
      anchor="right"
      open={drawerOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '550px',
          maxHeight: '66vh',
          marginTop: 'auto',
          marginBottom: 'auto',
          padding: '24px',
          borderRadius: '20px 0 0 20px',
          display: 'flex',
          alignItems: 'center',
          position: 'fixed',
          top: '50%',
          bottom: '50%',
          fontFamily: 'Figtree, sans-serif',
          bgcolor: theme === 'dark' ? '#252425' : '#F7F7F8',
        },
        elevation: 0,
      }}
    >
      <Box position="absolute" top={16} right={16}>
        <XMarkIcon
          className={`h-6 w-6 cursor-pointer ${
            theme === 'dark' ? 'text-white' : 'text-gray-500'
          }`}
          onClick={handleClose}
        />
      </Box>
      <Box
        textAlign="left"
        mb={2}
        width="100%"
        mt={2}
        sx={{ fontFamily: 'Figtree, sans-serif' }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            textAlign: 'left',
            color: theme === 'dark' ? 'white' : 'black',
            fontSize: '1.8rem',
            mb: 2,
            fontFamily: 'Figtree, sans-serif',
          }}
        >
          Welcome to the template creator!
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{
            textAlign: 'left',
            color: theme === 'dark' ? '#D4D5E6' : 'black',
            fontSize: '1.1rem',
            mb: 1,
            fontFamily: 'Figtree, sans-serif',
          }}
        >
          Choose your preferred mode
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="center"
        gap={3}
        mt={1}
      >
        <Box>
          <Box display="flex" alignItems="center" mb={1}>
            <PencilSquareIcon className="w-[24px] h-[24px] mr-2" />{' '}
            <Typography
              variant="h6"
              fontWeight="bold"
              color={theme === 'dark' ? 'white' : 'black'}
              sx={{ fontFamily: 'Figtree, sans-serif' }}
            >
              Regular Creator
            </Typography>
          </Box>
          <Box
            component="ul"
            sx={{
              paddingLeft: 3,
              listStyleType: 'disc',
              fontFamily: 'Figtree, sans-serif',
            }}
            color={theme === 'dark' ? '#cfcccc' : '#6e6e6e'}
          >
            <Typography
              component="li"
              variant="body2"
              sx={{ mb: 1, fontFamily: 'Figtree, sans-serif' }}
            >
              Full control over the template creation process.
            </Typography>
            <Typography
              component="li"
              variant="body2"
              sx={{ mb: 1, fontFamily: 'Figtree, sans-serif' }}
            >
              4 simple steps to manually configure the template.
            </Typography>
            <Typography
              component="li"
              variant="body2"
              sx={{ mb: 1, fontFamily: 'Figtree, sans-serif' }}
            >
              Customize settings such as variables, attachments, LLM selection,
              SEO optimization.
            </Typography>
            <Typography
              component="li"
              variant="body2"
              sx={{ mb: 1, fontFamily: 'Figtree, sans-serif' }}
            >
              Tailor the template to meet specific requirements.
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box display="flex" alignItems="center" mb={1}>
            <SparklesIcon className="w-[24px] h-[24px] mr-2" />{' '}
            <Typography
              variant="h6"
              fontWeight="bold"
              color={theme === 'dark' ? 'white' : 'black'}
              sx={{ fontFamily: 'Figtree, sans-serif' }}
            >
              Smart Creator
            </Typography>
          </Box>
          <Box
            component="ul"
            sx={{ paddingLeft: 3, listStyleType: 'disc' }}
            color={theme === 'dark' ? '#cfcccc' : '#6e6e6e'}
          >
            <Typography
              component="li"
              variant="body2"
              sx={{ mb: 1, fontFamily: 'Figtree, sans-serif' }}
            >
              Only need to provide a task description.
            </Typography>
            <Typography
              component="li"
              variant="body2"
              sx={{ mb: 1, fontFamily: 'Figtree, sans-serif' }}
            >
              Automatically generates all necessary settings for the template.
            </Typography>
            <Typography
              component="li"
              variant="body2"
              sx={{ mb: 1, fontFamily: 'Figtree, sans-serif' }}
            >
              Saves time and effort with automatic template creation.
            </Typography>
            <Typography
              component="li"
              variant="body2"
              sx={{ mb: 1, fontFamily: 'Figtree, sans-serif' }}
            >
              Provides effortless and convenient template generation.
            </Typography>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" gap={2} width="100%">
          <Button
            onClick={() => handleSelectTemplate('regularTemplate')}
            variant="outlined"
            fullWidth
            startIcon={<PencilSquareIcon style={{ width: 20, height: 20 }} />}
            sx={{
              borderRadius: '14px',
              fontWeight: 'bold',
              fontSize: '0.9rem',
              color: theme === 'dark' ? '#7F82C3' : '#9091A4',
              backgroundColor: theme === 'dark' ? '#3B3B45' : '#E1E1E5',
              borderColor: 'transparent',
              '&:hover': {
                borderColor: 'transparent',
              },
            }}
          >
            Regular Creator
          </Button>

          <Button
            onClick={() => handleSelectTemplate('smartTemplate')}
            variant="contained"
            fullWidth
            startIcon={<SparklesIcon style={{ width: 20, height: 20 }} />}
            sx={{
              borderRadius: '14px',
              fontWeight: 'bold',
              fontSize: '0.9rem',
              color: 'white',
              backgroundColor: theme === 'dark' ? '#464BBA' : 'primary.main',
              '&:hover': {
                backgroundColor: theme === 'dark' ? '#3d42a6' : 'primary.dark',
              },
            }}
          >
            Smart Creator
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default ChooseTemplate;
