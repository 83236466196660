import { LinkIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { SOCIAL_MEDIA_ICONS } from 'Constants/socialMediaIcons';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const SocialLinksMap = ({ socialLinks = [], variant = 'text', onDelete }) => {
  const { theme } = useThemeStore();
  return (
    <>
      {socialLinks.map((link, index) => {
        let Icon;
        let username;

        if (link.type === 'website') {
          Icon = LinkIcon;
          username = link?.url;
        } else {
          Icon = SOCIAL_MEDIA_ICONS[link.type];
          username = link?.url?.split('/').pop();
        }

        return (
          <div
            key={index}
            className={cn(
              'flex items-center gap-2 cursor-pointer text-sm hover:text-nue-blue',
              {
                'p-2 rounded-lg border-lavender border-2 bg-white':
                  variant === 'outlined',
                'bg-night-black color-white hover:text-white':
                  variant === 'outlined' && theme === 'dark',
              }
            )}
          >
            <Icon className="size-5" />
            <a
              key={index}
              href={link.url}
              target="_blank"
              rel="noreferrer"
              className="max-w-[200px] truncate"
            >
              {username}
            </a>
            {onDelete && (
              <XMarkIcon className="size-5" onClick={() => onDelete(link)} />
            )}
          </div>
        );
      })}
    </>
  );
};

export default SocialLinksMap;
