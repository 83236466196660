import { create } from 'zustand';

const useLoadingLayoutMyProfile = create((set) => ({
  loading: false,

  // Functions
  setLoading: (loading) => {
    set(() => ({
      loading: loading,
    }));
  },
}));

export default useLoadingLayoutMyProfile;
