import { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { useAudioRecorder } from 'react-audio-voice-recorder';

const ChatTool = inject('store')(
  observer(({ store }) => {
    const {
      startRecording,
      stopRecording,
      recordingBlob,
      isRecording,
      recordingTime,
    } = useAudioRecorder();

    useEffect(() => {
      if (!recordingBlob) return;

      // recordingBlob will be present at this point after 'stopRecording' has been called
      // eslint-disable-next-line no-console
      console.log(recordingBlob);
      // const audio = document.createElement('audio');
      // audio.src = url;
      // audio.controls = true;
      // document.body.appendChild(audio);

      getAudio(recordingBlob);
    }, [recordingBlob]);

    const [text, setText] = useState('---');

    const getAudio = async (blob) => {
      setText('---');
      const data = new FormData();
      data.append('file', blob, '2260c8a3-5c42-4a1b-8503-dedd931cc835.webm');
      try {
        await store.api
          .post('/auth/whisper', data, {
            // headers: {
            //   'Content-Type': `multipart/form-data`,
            // },
            // timeout: 30000,
          })
          .then(({ data }) => {
            setText(data.text);
          });
      } catch (error) {
        console.error(error);
      }
    };

    // const recorderControls = useAudioRecorder(
    //   {
    //     noiseSuppression: true,
    //     echoCancellation: true,
    //   },
    //   (err) => console.table(err) // onNotAllowedOrFound
    // );
    // const addAudioElement = (blob) => {
    //   const url = URL.createObjectURL(blob);
    //   const audio = document.createElement('audio');
    //   audio.src = url;
    //   audio.controls = true;
    //   document.body.appendChild(audio);
    // };

    return (
      <div className="font-figtree">
        <h1>---{'>'} Start</h1>
        <div className="ml-20">
          {/* <AudioRecorder
            onRecordingComplete={(blob) => addAudioElement(blob)}
            recorderControls={recorderControls}
            // downloadOnSavePress={true}
            // downloadFileExtension="mp3"
            showVisualizer={true}
          /> */}
          <br />
          <br />
          <br />
          <button
            className="bg-nue-blue hover:bg-nue-blue/70 text-white font-bold py-2 px-4 border rounded"
            onClick={() => {
              startRecording();
              setText('---');
            }}
          >
            Start recording
          </button>
          <br />
          {isRecording ? 'REC' : 'NO'}
          <br />
          {recordingTime}s
          <br />
          <button
            className="bg-lavender hover:bg-lavender/70 text-violet-blue font-bold py-2 px-4 border rounded"
            onClick={stopRecording}
          >
            Stop recording
          </button>
          <br />
          <br />
          <br />
          <br />
          <br />
          <p>{text}</p>
          <br />
          <br />
        </div>
        <h1>---&gt; End</h1>
      </div>
    );
  })
);

export default ChatTool;
