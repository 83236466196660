import React from 'react';
// utils
import { countWords } from './utils';
// store
import useChatMessageStore from './store';
import useChatStore from 'Components/Chat/store';

const useCountCoinsEdit = ({
  text,
  messages,
  editModelSelected,
  editSelected,
}) => {
  const [words, setWords] = React.useState(0);

  const getChatWordsEdit = (text, messages, refFile) => {
    let total = 0;
    if (refFile) {
      refFile.forEach((file) => {
        if (file.metadata.words && file.enabled) {
          total += file.metadata.words;
        }
      });
    }

    let chatWordsCount = 0;

    messages.forEach((item) => {
      let temp = Array.isArray(item)
        ? item.filter((m) => m.selected == true)
        : [item];
      let msg = temp.length > 0 ? temp[0] : null;

      if (msg && msg.data && msg.data.content) {
        if (typeof msg.data.content == 'string') {
          const wordsContent = countWords(msg.data.content);
          chatWordsCount += wordsContent;
        } else {
          for (let content of msg.data.content) {
            if (content.type == 'text') {
              const wordsContent = countWords(content.text);
              chatWordsCount += wordsContent;
            } else if (content.type == 'image_url') {
              const currentEditModels = JSON.parse(
                localStorage.getItem('currentModels')
              );
              const visionModel = currentEditModels.some(
                (model) => model.type == 'vision'
              );
              chatWordsCount += visionModel ? content.image_url.words : 0;
            }
          }
        }
      }
    });

    return total + chatWordsCount + countWords(text);
  };

  const { editMessChange } = useChatMessageStore();

  const refFile = useChatStore((state) => state.refFile);

  // Count the word when the text, messages, editModelSelected, editMessChange or refFile change
  React.useEffect(() => {
    if (editSelected) {
      setWords(getChatWordsEdit(text, messages, refFile));
    }
  }, [text, messages, editModelSelected, refFile, editMessChange]);

  return {
    words,
  };
};

export default useCountCoinsEdit;
