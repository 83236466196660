export const order = [
  'My Templates',
  'Share With me',
  'Image Generation',
  'Writing',
  'Programming',
  'Image Prompting',
  'Content',
  'Business',
  'Career Development',
  'Resources',
  'For fun',
  'For Educators',
  'Affiliate Marketing',
  'SEO Expert',
  'YouTube Growth',
  'LinkedIn Boosters',
  'Instagram Boosters',
].map((item) => item?.toLowerCase());

// Function to get the index of an element
export const getIndex = (element) => {
  return order.indexOf(element[0]);
};

export const getIndexV2 = (element) => {
  const lowerElement = element?.toLowerCase();
  return order.indexOf(lowerElement);
};
