export function toCamelCase(str) {
  // Transform the string to lowercase
  str = str?.toLowerCase();

  // Divide the string into words
  const words = str.split(/[\s-_]+/);

  // Convert the first letter of each word to uppercase
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word;
    }
    return word[0].toUpperCase() + word.slice(1);
  });

  // Join the words into a single string and return it
  return camelCaseWords.join('');
}

export function removeDuplicates(objectArray) {
  const uniqueSet = new Set();
  const uniqueArray = [];

  for (const object of objectArray) {
    if (!uniqueSet.has(object.variable)) {
      uniqueSet.add(object.variable);
      uniqueArray.push(object);
    }
  }

  return uniqueArray;
}
