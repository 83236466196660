// Mobx
import { inject, observer } from 'mobx-react';
// Components
import Modal from 'Components/Common/Modal/NewModal';
// store
import { useDeleteFolderChatModal } from './store';
import useTreeDNDChatStore from '../TreeDNDChat/Store';
import { TrashIcon } from '@heroicons/react/24/outline';

const DeleteModalFolderChat = inject('store')(
  observer(({ store }) => {
    const { isModalOpen, setModalOpen, selectedFolder } =
      useDeleteFolderChatModal();
    const { treeData, setTreeData } = useTreeDNDChatStore();

    const onCloseModal = () => setModalOpen(false);
    const onDeletModalFolderChat = async () => {
      try {
        const selectedElementsFolder = treeData.filter(
          (i) => i.parent == selectedFolder.id
        );
        const chatsIds = selectedElementsFolder
          .filter((i) => i.data.type === 'chat')
          .map((item) => item.id);
        const foldersIds = selectedElementsFolder
          .filter((i) => i.data.type === 'folder')
          .map((item) => item.id);

        const res = await store.api.post(
          `/folder/chatFolders/${selectedFolder.id}`,
          {
            childrensChats: chatsIds,
            childrensFolders: foldersIds,
          }
        );
        if (res.status === 200) {
          let data = treeData
            .filter((item) => item.id !== selectedFolder.id)
            .filter((item) => item.parent !== selectedFolder.id);

          const filterChats = treeData.filter(
            (item) => item.parent === selectedFolder.id
          );

          if (filterChats.length > 0) {
            const chats = filterChats.map((item) => ({ ...item, parent: 0 }));
            data = [...data, ...chats];
          }

          setTreeData(data);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        onCloseModal();
      }
    };

    return (
      <Modal
        onClose={onCloseModal}
        open={isModalOpen}
        title="Delete chat folder"
        onConfirm={onDeletModalFolderChat}
        onConfirmBtnText="Delete"
        onConfirmBtnIcon={
          <TrashIcon className="h-[18px] w-[18px]" aria-hidden="true" />
        }
      >
        <>
          <div className="font-figtree max-w-96 text-base text-black dark:text-crystal-bell text-center">
            <div className="mb-2">
              Are you sure you want to delete your folder? If you delete it your
              contained chats will go to the root directory again.
            </div>
          </div>
        </>
      </Modal>
    );
  })
);

export default DeleteModalFolderChat;
