import { useState } from 'react';

export const CircularProggres = ({ radius, weight, percent, color }) => {
  const [strokeWidth, setStrokeWidth] = useState(weight ? weight : 30);
  const [h, setH] = useState(radius * 2 + strokeWidth);
  const [w, setW] = useState(radius * 2 + strokeWidth);
  const [circumference, setCircumference] = useState(2 * Math.PI * radius);

  return (
    <div className="flex items-center justify-center">
      <svg className={`transform -rotate-90 w-[${w}px] h-[${h}px]`}>
        <circle
          cx={w / 2}
          cy={h / 2}
          r={radius}
          stroke="currentColor"
          strokeWidth={strokeWidth}
          fill="transparent"
          className="text-gray-700"
        />
        <circle
          cx={w / 2}
          cy={h / 2}
          r={radius}
          stroke={color}
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (percent / 100) * circumference}
          className="text-blue-500"
        />
      </svg>
      <span className={`absolute text-[${radius / 2}px]`}>{percent} %</span>
    </div>
  );
};
