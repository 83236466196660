import { create } from 'zustand';

const useAlertCoinsStore = create((set) => ({
  openModal: false,
  setOpenModal: (openModal) => set({ openModal }),
  dissmissed: false,
  setDissmissed: (dissmissed) => set({ dissmissed }),
}));

export default useAlertCoinsStore;
