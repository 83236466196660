// MUI
import { Dialog } from '@mui/material';
// Theme
import useThemeStore from 'Theme/store';

const FullScreenModal = ({ open, onClose, transition, sxProps, children }) => {
  const { theme } = useThemeStore();

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={transition}
      PaperProps={{
        sx: {
          backgroundColor: theme === 'light' ? '#F7F7F8' : '#252425',
          border: '1px solid #E1E1E5',
          borderRadius: '4px',
          minWidth: '400px',
          minHeight: '300px',
          ...sxProps,
        },
      }}
    >
      {children}
    </Dialog>
  );
};

export default FullScreenModal;
