import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { keyframes } from '@emotion/react';
import { Button, Dialog, Typography, Box, Slide, Tooltip } from '@mui/material';
import {
  XMarkIcon,
  Cog6ToothIcon,
  BriefcaseIcon,
  SparklesIcon,
  AcademicCapIcon,
  CheckCircleIcon,
  ArrowsPointingOutIcon,
  BoltIcon,
  BookOpenIcon,
  BuildingStorefrontIcon,
  CakeIcon,
  CalculatorIcon,
  ChartBarIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftRightIcon,
  ClockIcon,
  CodeBracketIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  HeartIcon,
  HomeIcon,
  BarsArrowDownIcon,
  LanguageIcon,
  LightBulbIcon,
  MusicalNoteIcon,
  UserGroupIcon,
  WrenchScrewdriverIcon,
  VariableIcon,
  Bars3CenterLeftIcon,
  ArrowsPointingInIcon,
} from '@heroicons/react/24/outline';

import useThemeStore from 'Theme/store';
import Loader from '../../Components/Loader';
import ModalConfirmClose from './Components/ModalConfirmClose/ModalConfirmClose';
// Context
import { useChat } from 'Context/Chat/Chat.context';
import { toast } from 'react-hot-toast';
import useCreateTemplatesStore from './Store';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const iconMap = {
  AcademicCapIcon: AcademicCapIcon,
  Cog6ToothIcon: Cog6ToothIcon,
  BriefcaseIcon: BriefcaseIcon,
  SparklesIcon: SparklesIcon,
  CheckCircleIcon: CheckCircleIcon,
  XMarkIcon: XMarkIcon,
  BoltIcon: BoltIcon,
  BookOpenIcon: BookOpenIcon,
  BuildingStorefrontIcon: BuildingStorefrontIcon,
  CakeIcon: CakeIcon,
  CalculatorIcon: CalculatorIcon,
  ChartBarIcon: ChartBarIcon,
  ChatBubbleLeftEllipsisIcon: ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftRightIcon: ChatBubbleLeftRightIcon,
  ClockIcon: ClockIcon,
  CodeBracketIcon: CodeBracketIcon,
  EnvelopeIcon: EnvelopeIcon,
  GlobeAltIcon: GlobeAltIcon,
  HeartIcon: HeartIcon,
  HomeIcon: HomeIcon,
  LanguageIcon: LanguageIcon,
  LightBulbIcon: LightBulbIcon,
  MusicalNoteIcon: MusicalNoteIcon,
  UserGroupIcon: UserGroupIcon,
  WrenchScrewdriverIcon: WrenchScrewdriverIcon,
  VariableIcon: VariableIcon,
  ArrowsPointingOutIcon: ArrowsPointingOutIcon,
};

const replaceAllVariablesPrompt = (str) => {
  if (typeof str !== 'string') return '';

  return str.replace(
    /\{\{([\w\s.,?!'*#@^&;-]*)\}\}/g,
    (match, variableName) => {
      return `\${${variableName.trim()}}`;
    }
  );
};

const SmartPromptTemplate = ({ onClose, templateData, store }) => {
  const { theme } = useThemeStore();
  const [loading, setLoading] = useState(false);
  const [modalConfirmClose, setModalConfirmClose] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [models, setModels] = useState([]);

  const { createTool, templatesFolders } = useChat();
  const { setOpenModal, setSelectedComponent, setDefaultData, setEdit } =
    useCreateTemplatesStore();

  const getModels = useCallback(async () => {
    try {
      const response = await store.api.get(`/model`);
      const data = response.data;
      const transformedModels = data.models.map((model) => ({
        id: model._id,
        title: model.name,
      }));
      setModels(transformedModels);
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  }, [store.api]);

  useEffect(() => {
    getModels();
  }, [getModels]);

  useEffect(() => {
    if (templateData) {
      templateData.variables = templateData.variables || [];
      templateData.categoricalVariables =
        templateData.categoricalVariables || [];
    }
  }, [templateData]);

  const ownFolderUser = useMemo(
    () => templatesFolders.filter((f) => Boolean(f?.creator)),
    [templatesFolders]
  );

  const defaultFolderId = ownFolderUser[0]?._id;

  const handleClose = (skipConfirm = false) => {
    if (!skipConfirm) {
      setModalConfirmClose(true);
    } else {
      onClose();
    }
  };

  const handleConfirmClose = () => {
    setModalConfirmClose(false);
    onClose();
  };

  const handleCancelClose = () => setModalConfirmClose(false);

  const toggleFullScreen = () => setIsFullScreen((prev) => !prev);

  const processText = (text) => {
    const parts = text.split(/(\{\{.*?\}\})/g);

    return parts.map((part, index) => {
      if (part.match(/\{\{.*?\}\}/)) {
        const variableText = part.replace(/\{\{|\}\}/g, '');
        const categoricalVariable = templateData.categoricalVariables?.find(
          (variable) => variable.name === variableText
        );

        if (categoricalVariable) {
          return (
            <Tooltip
              key={index}
              title={categoricalVariable.options.join(', ')}
              arrow
              placement="top"
            >
              <Box
                component="span"
                sx={{
                  backgroundColor: theme === 'dark' ? '#7a7a7a' : '#b0b0b0',
                  color: theme === 'dark' ? '#ffffff' : '#000000',
                  padding: '1px 4px',
                  borderRadius: '6px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  fontFamily: 'Figtree, sans-serif',
                }}
              >
                {variableText}
              </Box>
            </Tooltip>
          );
        }

        return (
          <Box
            key={index}
            component="span"
            sx={{
              backgroundColor: theme === 'dark' ? '#7a7a7a' : '#b0b0b0',
              color: theme === 'dark' ? '#ffffff' : '#000000',
              padding: '1px 6px',
              borderRadius: '4px',
              fontWeight: 'bold',
              fontFamily: 'Figtree, sans-serif',
            }}
          >
            {variableText}
          </Box>
        );
      } else {
        return part;
      }
    });
  };

  const IconComponent = iconMap[templateData.icon] || AcademicCapIcon;

  const buildVariables = (variables, categoricalVariables) => {
    const variablesClean = variables.map((variable) =>
      normalizeField({
        name: variable.name,
        variable: variable.name,
        type: 'textarea',
        placeholder: variable.description,
        description: variable.description,
        attr: variable.name,
        title: variable.name,
      })
    );

    const promptVariables = categoricalVariables.map((variable) =>
      normalizeField({
        name: variable.name,
        variable: variable.name,
        type: 'dropdown',
        placeholder: variable.description,
        description: variable.description,
        attr: variable.name,
        title: variable.name,
        options: variable.options.map((option, index) => ({
          id: index + 1,
          title: option,
        })),
      })
    );

    return { variablesClean, promptVariables };
  };

  useEffect(() => {
    getModels();
  }, [getModels]);

  const normalizeString = (str) => {
    return str
      .toLowerCase()
      .replace(/[\s-]+/g, '')
      .replace(/[()]/g, '');
  };

  const handleGenerateTemplate = async () => {
    if (!templateData || !templateData.templateName) {
      toast.error('Template name is required');
      return;
    }

    const normalizedModelName = normalizeString(
      templateData.optimalLLMModel.modelName
    );

    const matchedModels = models
      .filter((model) => normalizeString(model.title) === normalizedModelName)
      .map((model) => model.id);

    const validatedPrompt = templateData.suggestedPrompt
      ? replaceAllVariablesPrompt(templateData.suggestedPrompt, templateData)
      : '';

    const { variablesClean, promptVariables } = buildVariables(
      templateData.variables || [],
      templateData.categoricalVariables || []
    );

    const requestData = {
      name: templateData.templateName,
      description: templateData.description,
      value: validatedPrompt,
      default_model: '',
      variables: [...variablesClean, ...promptVariables],
      parent_folder: defaultFolderId,
      files: [],
      multi_default_models: matchedModels,
      is_public: false,
    };

    setLoading(true);
    try {
      const newTemplate = await createTool(requestData);
      toast.success('Template created successfully');
      onClose();
    } catch (error) {
      console.error('Error creating template:', error);
      toast.error('Error creating template');
    } finally {
      setLoading(false);
    }
  };

  const normalizeField = (field) => ({
    name: field.name || field.title || field.attr,
    variable: field.variable || field.attr || field.name,
    type: field.type,
    placeholder: field.placeholder || field.description || '',
    attr: field.attr || field.name,
    description: field.description || field.placeholder || '',
    title: field.title || field.name || field.attr,
    options: field.options || [],
  });

  const buildDataToFormEditForEdit = (templateData) => {
    const normalizedModelName = normalizeString(
      templateData?.optimalLLMModel?.modelName || ''
    );

    const multiDefaultModels = models
      .filter((model) => normalizeString(model.title) === normalizedModelName)
      .map((model) => model.id);

    return {
      id: templateData?._id,
      default_model: multiDefaultModels[0],
      multi_default_models: multiDefaultModels,
      name: templateData?.title,
      description: templateData?.desc,
      promptVariables: templateData?.fields
        ? templateData?.fields
            ?.map((item) => ({
              variable: item?.attr,
              name: item?.title,
              type: item?.type,
              options: item?.options?.map((option, index) => ({
                id: index,
                title: option?.title,
                value: option?.value,
              })),
              placeholder: item?.placeholder || item?.description || '',
              maxLength: item?.maxLength,
            }))
            .filter((item) => !['attachment', 'image'].includes(item?.type)) ??
          []
        : [],
      variables: templateData?.fields
        ? templateData?.fields
            ?.map((item) => ({
              variable: item?.attr,
              name: item?.title,
              type: item?.type,
              options: item?.options?.map((option, index) => ({
                id: index,
                title: option?.title,
                value: option?.value,
              })),
              placeholder: item?.placeholder || item?.description || '',
              maxLength: item?.maxLength,
            }))
            .filter((item) => ['attachment', 'image'].includes(item?.type))
        : [],
      prompt: templateData?.prompt_values?.value,
      location: defaultFolderId,
      files: templateData?.files ?? [],
      is_public: templateData?.public,
      slug: templateData?.slug,
      seo_title: templateData?.seo_title,
      meta_description: templateData?.meta_description,
      keywords: templateData?.keywords,
    };
  };

  const handleModifyTemplate = () => {
    const transformedTemplateData = {
      ...templateData,
      prompt_values: { value: templateData.suggestedPrompt },
      fields: [
        ...templateData.variables.map((variable) => ({
          attr: variable.name,
          title: variable.name,
          type: 'textarea',
          description: variable.description,
        })),
        ...templateData.categoricalVariables.map((variable) => ({
          attr: variable.name,
          title: variable.name,
          type: 'dropdown',
          options: variable.options.map((option) => ({
            title: option,
            value: option,
          })),
          description: variable.description,
        })),
      ],
      title: templateData.templateName,
      desc: templateData.description,
      default_model: normalizeString(templateData.optimalLLMModel.modelName),
      parent_folder: defaultFolderId,
    };

    const editData = buildDataToFormEditForEdit(transformedTemplateData);

    setDefaultData(editData);
    setEdit(true);
    setSelectedComponent('smartEdit');
    setOpenModal(true);
    onClose();
  };

  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  const fadeInSlideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

  return (
    <>
      <Dialog
        open={true}
        onClose={() => handleClose()}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            width: isFullScreen ? '95vw' : '600px',
            height: isFullScreen ? '100vh' : '800px',
            maxWidth: isFullScreen ? '95vw' : '90vw',
            maxHeight: isFullScreen ? '90vh' : '85vh',
            borderRadius: '20px',
            padding: isFullScreen ? '32px' : '24px',
            fontFamily: 'Figtree, sans-serif',
            bgcolor: theme === 'dark' ? '#252425' : '#F7F7F8',
            transition: 'width 0.5s, height 0.5s',
          },
          elevation: 0,
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
          >
            <Loader className="h-8 mb-2" />
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',
                color: theme === 'dark' ? 'white' : 'black',
                textAlign: 'center',
              }}
            >
              Generating your template...
            </Typography>
          </Box>
        ) : (
          <>
            <Box position="absolute" top={16} right={16}>
              <XMarkIcon
                className={`h-6 w-6 cursor-pointer ${
                  theme === 'dark' ? 'text-white' : 'text-gray-500'
                }`}
                onClick={() => handleClose()}
              />
            </Box>
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{
                textAlign: 'left',
                color: theme === 'dark' ? 'white' : 'black',
                fontSize: '1.8rem',
                mb: 2,
                fontFamily: 'Figtree, sans-serif',
              }}
            >
              New Prompt Template
            </Typography>
            <Box display="flex" alignItems="center">
              <IconComponent
                className="h-6 w-6 mr-2"
                style={{ color: theme === 'dark' ? '#7379FF' : '#464BBA' }}
              />
              <Typography
                variant="h6"
                sx={{
                  textAlign: 'left',
                  color: theme === 'dark' ? '#7379FF' : '#464BBA',

                  fontSize: '1.25rem',
                  fontWeight: 'bold',
                  fontFamily: 'Figtree, sans-serif',
                }}
              >
                {templateData?.templateName}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: theme === 'dark' ? '#39383A' : '#E1E1E5',

                color: theme === 'dark' ? '#858585' : '#9091A4',
                padding: '2px 6px',
                borderRadius: '4px',
                display: 'inline-block',
                marginTop: '4px',
                fontSize: '0.75rem',
                textAlign: 'center',
                alignSelf: 'flex-start',
                fontWeight: 'bold',
                fontFamily: 'Figtree, sans-serif',
              }}
              mb={2}
            >
              {templateData?.optimalLLMModel.modelName}
            </Box>
            <Box sx={{ position: 'relative', mb: 3 }}>
              <Typography
                variant="body2"
                sx={{
                  color: theme === 'dark' ? '#EFEFEF' : '#6e6e6e',
                  mb: 3,
                  textAlign: 'left',
                  fontFamily: 'Figtree, sans-serif',
                }}
              >
                {templateData?.description}
              </Typography>
              <Box
                sx={{
                  marginTop: '16px',
                  borderRadius: '8px',
                  padding: '8px',
                  backgroundColor: theme === 'dark' ? '#333333' : '#FFFFFF',
                  color: theme === 'dark' ? '#ffffff' : '#000000',
                  minHeight: '120px',
                  overflowY: 'auto',
                  fontFamily: 'Figtree, sans-serif',
                }}
              >
                <Typography variant="body2" component="div">
                  {processText(templateData?.suggestedPrompt || '')}
                </Typography>
              </Box>

              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: -16,
                }}
              >
                <Button
                  startIcon={
                    isFullScreen ? (
                      <ArrowsPointingInIcon className="h-5 w-5" />
                    ) : (
                      <ArrowsPointingOutIcon className="h-5 w-5" />
                    )
                  }
                  onClick={toggleFullScreen}
                  sx={{
                    textTransform: 'capitalize',
                    color: theme === 'dark' ? '#7379FF' : '#464BBA',
                    fontWeight: 'bold',
                    fontSize: '0.875rem',
                  }}
                ></Button>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Button
                onClick={() => handleButtonClick('model')}
                variant="contained"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textTransform: 'capitalize',
                  padding: '12px',
                  fontSize: '1rem',
                  width: '100%',
                  marginRight: '8px',
                  backgroundColor:
                    selectedButton === 'model'
                      ? '#464BBA'
                      : theme === 'dark'
                      ? '#3B3B45'
                      : '#f0f0f0',
                  color:
                    selectedButton === 'model'
                      ? '#EFEFEF'
                      : theme === 'dark'
                      ? '#7F82C3'
                      : '#9091A4',
                  borderRadius: '12px',
                  gap: '3px',
                  fontFamily: 'Figtree, sans-serif',
                  ml: '20%',
                }}
                startIcon={
                  <SparklesIcon
                    className="h-6 w-6"
                    style={{ marginLeft: '10px' }}
                  />
                }
              >
                Model
              </Button>

              <Button
                onClick={() => handleButtonClick('variables')}
                variant="contained"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textTransform: 'capitalize',
                  padding: '12px',
                  fontSize: '1rem',
                  width: '100%',
                  marginLeft: '8px',
                  backgroundColor:
                    selectedButton === 'variables'
                      ? '#464BBA'
                      : theme === 'dark'
                      ? '#3B3B45'
                      : '#f0f0f0',
                  color:
                    selectedButton === 'variables'
                      ? '#EFEFEF'
                      : theme === 'dark'
                      ? '#7F82C3'
                      : '#9091A4',
                  borderRadius: '12px',
                  gap: '3px',
                  fontFamily: 'Figtree, sans-serif',
                  mr: '20%',
                }}
                startIcon={
                  <VariableIcon
                    className="h-6 w-6"
                    style={{ marginLeft: '10px' }}
                  />
                }
              >
                Variables
              </Button>
            </Box>

            {selectedButton === 'model' &&
              templateData.optimalLLMModel?.reason && (
                <Box
                  sx={{
                    marginTop: '16px',
                    padding: '8px',
                    borderRadius: '8px',
                    color: theme === 'dark' ? '#D4D5E6' : '#6e6e6e',
                    textAlign: 'left',
                    animation: `${fadeInSlideUp} 0.4s ease-in-out`,
                    fontFamily: 'Figtree, sans-serif',
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 'bold',
                      mr: 1,
                      fontFamily: 'Figtree, sans-serif',
                    }}
                  >
                    Recommended LLM: {templateData.optimalLLMModel.modelName}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: 'Figtree, sans-serif' }}
                  >
                    {templateData.optimalLLMModel.reason}
                  </Typography>
                </Box>
              )}
            {selectedButton === 'variables' && (
              <>
                {templateData.variables?.map((variable, index) => (
                  <Box
                    key={index}
                    sx={{
                      marginTop: '16px',
                      padding: '8px',
                      borderRadius: '8px',
                      color: theme === 'dark' ? '#D4D5E6' : '#6e6e6e',
                      fontFamily: 'Figtree, sans-serif',
                    }}
                  >
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 'bold',
                          mr: 1,
                          fontFamily: 'Figtree, sans-serif',
                        }}
                      >
                        {variable.name}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          border: '1px solid #979AD3',
                          borderRadius: '12px',
                          padding: '2px 8px',
                          color: '#979AD3',
                          backgroundColor: 'rgba(151, 154, 211, 0.1)',
                          fontFamily: 'Figtree, sans-serif',
                        }}
                      >
                        <Bars3CenterLeftIcon className="h-4 w-4 mr-1" />
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Figtree, sans-serif',
                            fontWeight: 'bold',
                          }}
                        >
                          TEXT
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{ fontFamily: 'Figtree, sans-serif' }}
                    >
                      {variable.description}
                    </Typography>
                  </Box>
                ))}

                {templateData.categoricalVariables?.map(
                  (categoricalVariable, index) => (
                    <Box
                      key={`cat-${index}`}
                      sx={{
                        marginTop: '16px',
                        padding: '8px',
                        borderRadius: '8px',
                        color: theme === 'dark' ? '#D4D5E6' : '#6e6e6e',
                        fontFamily: 'Figtree, sans-serif',
                      }}
                    >
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 'bold',
                            mr: 1,
                            fontFamily: 'Figtree, sans-serif',
                          }}
                        >
                          {categoricalVariable.name}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid #979AD3',
                            borderRadius: '12px',
                            padding: '2px 8px',
                            color: '#979AD3',
                            backgroundColor: 'rgba(151, 154, 211, 0.1)',
                            fontFamily: 'Figtree, sans-serif',
                          }}
                        >
                          <BarsArrowDownIcon
                            className="h-4 w-4 mr-1"
                            style={{ color: '#979AD3' }}
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: 'Figtree, sans-serif',
                              fontWeight: 'bold',
                            }}
                          >
                            DROPDOWN: {categoricalVariable.options.join(', ')}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: 'Figtree, sans-serif' }}
                      >
                        {categoricalVariable.description}
                      </Typography>
                    </Box>
                  )
                )}
              </>
            )}

            <Box
              display="flex"
              justifyContent="space-between"
              gap={2}
              width="100%"
              mt="auto"
            >
              <Button
                onClick={handleModifyTemplate}
                startIcon={<Cog6ToothIcon style={{ width: 20, height: 20 }} />}
                variant="outlined"
                sx={{
                  borderRadius: '8px',
                  fontWeight: 'bold',
                  fontSize: '0.9rem',
                  color: theme === 'dark' ? '#7F82C3' : '#5256A6',
                  backgroundColor: theme === 'dark' ? '#3B3B45' : '#D4D5E6',
                  borderColor: 'transparent',
                  textTransform: 'capitalize',
                  '&:hover': {
                    borderColor: 'transparent',
                  },
                }}
              >
                Modify template
              </Button>

              <Button
                onClick={handleGenerateTemplate}
                variant="contained"
                color="primary"
                startIcon={<CheckCircleIcon className="h-5 w-5" />}
                sx={{
                  textTransform: 'capitalize',
                  borderRadius: '8px',
                  fontWeight: 'bold',
                }}
              >
                Use template
              </Button>
            </Box>
          </>
        )}
      </Dialog>
      <ModalConfirmClose
        open={modalConfirmClose}
        onCancel={handleCancelClose}
        onClose={handleConfirmClose}
      />
    </>
  );
};

export default SmartPromptTemplate;
