import styled from 'styled-components';

export const Tooltip = styled.div`
  display: none;
`;
export const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;
