import {
  FolderIcon,
  ChatBubbleLeftEllipsisIcon,
} from '@heroicons/react/24/outline';

const CustomDragPreview = (props) => {
  const item = props.monitorProps.item;

  return (
    <div className="flex items-center bg-lavender rounded-md shadow-md text-violet-blue w-fit gap-[4px] py-[4px] px-[8px] pointer-events-none">
      <div className="flex items-center">
        {!item.droppable ? (
          <ChatBubbleLeftEllipsisIcon className="w-5 h-5" />
        ) : (
          <FolderIcon className="w-5 h-5" />
        )}
      </div>
      <div className="flex items-center ">
        <p className="truncate max-w-[230px]">{item.text}</p>
      </div>
    </div>
  );
};

export default CustomDragPreview;
