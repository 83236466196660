import Loader from 'Components/Loader';
import { useRef } from 'react';
import { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import PDFFileUploaded from 'Theme/icons/pdfFileUploaded';
import toast from 'react-hot-toast';
import { PhotoIcon, XMarkIcon } from '@heroicons/react/24/solid';

import { useEffect } from 'react';
import FilePDF from 'Theme/icons/fileIcons/filepdf';
import FileWord from 'Theme/icons/fileIcons/fileword';
import FileExcel from 'Theme/icons/fileIcons/fileexcel';
import FilePowerPoint from 'Theme/icons/fileIcons/filepowerpoint';
import FileVideo from 'Theme/icons/fileIcons/filevideo';
import FileMusic from 'Theme/icons/fileIcons/musicfile';
import WebFile from 'Theme/icons/fileIcons/webfile';
import FileText from 'Theme/icons/fileIcons/textfile';
import useThemeStore from 'Theme/store';

let onChangeFile;

const FileUpload = inject('store')(
  observer(({ store, onUploaded, type = 'application/pdf', isHidden }) => {
    const [fileSelected, setFileSelected] = useState([]);
    const [dragActive, setDragActive] = useState(false);
    const [uploading, setUploading] = useState(false);

    const fileInputRef = useRef(null);

    onChangeFile = async (e, isFile = false) => {
      let filesToAdd = [];
      try {
        setUploading(true);

        for (let i = 0; i < e.target.files.length; i++) {
          let file = e.target.files[i];
          const fileExtension = file.name.split('.').pop().toLowerCase();

          // Split the type string by commas and trim each type
          const allowedTypes = type.split(',').map((t) => t.trim());

          // Check if the file extension is in the allowed extensions list
          if (!allowedTypes.includes(`.${fileExtension}`)) {
            toast.error(`Only ${type} files are allowed.`);
            continue; // Skip to the next iteration without affecting filesToAdd
          }
          if (file.type == 'application/vnd.ms-excel') {
            toast.error(`Only ${type} files are allowed.`);
            continue; // Skip to the next iteration without affecting filesToAdd
          }

          const exists = fileSelected.some((file2) => file2.name === file.name);
          if (exists) {
            toast('You already uploaded a file with the name ' + file.name, {
              icon: '😱',
            });
            continue; // Skip to the next iteration without affecting filesToAdd
          }

          if (file.size >= 26214400) {
            toast.error('Maximum content file limit (25MB)');
            continue; // Skip to the next iteration without affecting filesToAdd
          }

          const formData = new FormData();
          formData.append('file', file);

          const res = await store.api.post(`/user/upload`, formData);
          console.log('Upload response:', res); // Añadir mensaje de depuración
          if (!res.data || !res.data.url) {
            // Verificar si res.data y res.data.url están definidos
            console.error(
              'Error: The response did not include a valid URL',
              res.data
            );
            throw new Error('The response did not include a valid URL');
          }
          let value = {
            url: res.data.url,
            name: file.name,
            size: file.size,
            enabled: true,
            type: file.type,
          };
          filesToAdd.push(value);
        }

        if (filesToAdd.length > 0) {
          setFileSelected((prevFileSelected) => [
            ...prevFileSelected,
            ...filesToAdd,
          ]);
          onUploaded(filesToAdd);
        }
      } catch (error) {
        console.error(error);
        if (error?.response?.data?.error?.message) {
          toast.error(error?.response?.data?.error?.message);
        }
      } finally {
        setUploading(false);
      }
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        onChangeFile({ target: { files: e.dataTransfer.files } }, true);
        e.dataTransfer.clearData();
      }
    };

    const handleDrag = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else if (e.type === 'dragleave') {
        setDragActive(false);
      }
    };

    async function handlePaste(e) {
      e.preventDefault();

      const items = e.clipboardData.items;
      const files = [];

      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          const blob = items[i].getAsFile();
          const fileName = `image_${new Date()
            .toISOString()
            .replace(/[:.]/g, '-')}.png`;
          const fileWithMetadata = new File([blob], fileName, {
            type: blob.type,
          });
          files.push(fileWithMetadata);
        }
      }

      if (files.length > 0) {
        const simulatedEvent = {
          target: {
            files: files,
          },
        };
        await onChangeFile(simulatedEvent, true);
      }
    }

    const checkFile = () => {
      return true;
    };

    // Use useEffect to observe changes in the fileSelected state
    useEffect(() => {
      // console.log('');
    }, [fileSelected]);

    const getIconFile = (type, file) => {
      let iconComponent;

      switch (type) {
        case 'application/pdf':
          iconComponent = (
            <div className="w-7 h-7 flex items-center justify-center">
              <FilePDF color={'#211E21'} />
            </div>
          );
          break;
        case 'youtube':
          iconComponent = (
            <div className="w-16 h-10 flex items-center justify-center">
              <img
                src={file.thumbnail}
                alt="YouTube Thumbnail"
                className="w-full h-full object-cover"
              />
            </div>
          );
          break;
        case 'webpage':
          iconComponent = (
            <div className="w-7 h-7 flex items-center justify-center">
              <WebFile color={'#211E21'} />
            </div>
          );
          break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          iconComponent = (
            <div className="w-7 h-7 flex items-center justify-center">
              <FileWord color={'#211E21'} />
            </div>
          );
          break;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          iconComponent = (
            <div className="w-7 h-7 flex items-center justify-center">
              <FilePowerPoint color={'#211E21'} />
            </div>
          );
          break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          iconComponent = (
            <div className="w-7 h-7 flex items-center justify-center">
              <FileExcel color={'#211E21'} />
            </div>
          );
          break;
        case 'text/plain':
          iconComponent = (
            <div className="w-7 h-7 flex items-center justify-center">
              <FileText color={'#211E21'} />
            </div>
          );
          break;
        case 'audio/mpeg':
          iconComponent = (
            <div className="w-7 h-7 flex items-center justify-center">
              <FileMusic color={'#211E21'} />
            </div>
          );
          break;
        case 'video/mp4':
          iconComponent = (
            <div className="w-7 h-7 flex items-center justify-center">
              <FileVideo color={'#211E21'} />
            </div>
          );
          break;
        case 'image/jpeg':
          iconComponent = (
            <div className="w-16 flex items-center justify-center">
              {file.url ? (
                <img
                  src={file.url}
                  className="w-10 h-10 object-contain"
                  alt="Image"
                />
              ) : (
                <PhotoIcon className="text-raisin-black w-7 h-7" />
              )}
            </div>
          );
          break;

        // Temporal icon for HTML, JSON, CSV
        case 'text/html':
          iconComponent = (
            <div className="w-7 h-7 flex items-center justify-center">
              <FileText color={'#211E21'} />
            </div>
          );
          break;
        case 'application/json':
          iconComponent = (
            <div className="w-7 h-7 flex items-center justify-center">
              <FileText color={'#211E21'} />
            </div>
          );
          break;
        case 'text/csv':
          iconComponent = (
            <div className="w-7 h-7 flex items-center justify-center">
              <FileText color={'#211E21'} />
            </div>
          );
          break;
        default:
          iconComponent = (
            <div className="w-16 h-7 flex items-center justify-center">N</div>
          );
      }
      if (type.includes('image')) {
        iconComponent = (
          <div className="w-16 flex items-center justify-center">
            {file.url ? (
              <img
                src={file.url}
                className="w-10 h-10 object-contain"
                alt="Image"
              />
            ) : (
              <PhotoIcon className="text-raisin-black w-7 h-7" />
            )}
          </div>
        );
      }
      return (
        <div className="w-16 flex items-center justify-center">
          {iconComponent}
        </div>
      );
    };

    const { theme } = useThemeStore();

    return (
      <>
        {!uploading ? (
          <div
            style={
              isHidden
                ? {
                    position: 'absolute',
                    width: '1px',
                    height: '1px',
                    padding: '0',
                    margin: '-1px',
                    overflow: 'hidden',
                    clip: 'rect(0, 0, 0, 0)',
                    border: '0',
                    opacity: '0',
                    pointerEvents: 'none',
                  }
                : {}
            }
            onDragEnter={(e) => handleDrag(e)}
            onDragLeave={(e) => handleDrag(e)}
            onDragOver={(e) => handleDrag(e)}
            onDrop={handleDrop}
            onPaste={handlePaste}
          >
            <div
              className={`border-2 rounded border-dashed ${
                theme == 'dark'
                  ? 'dark:border-tropical-indigo'
                  : 'border-violet-blue'
              } grid justify-center items-center h-16 w-full ${
                dragActive
                  ? 'bg-lavender dark:bg-ship-grey'
                  : 'bg-ghost-white dark:bg-night-black'
              }`}
            >
              {!dragActive && (
                <div className="flex items-center">
                  <DocumentPlusIcon
                    className="h-5 w-5 text-cool-gray dark:text-quicksilver flex-shrink-0 mr-3"
                    aria-hidden="true"
                  />
                  <div className="text-cool-gray dark:text-quicksilver font-figtree text-xs font-medium uppercase">
                    Drop your file or{' '}
                    <span
                      className="text-violet-blue underline cursor-pointer"
                      onClick={() => fileInputRef.current.click()}
                    >
                      click here
                    </span>{' '}
                    to browse
                  </div>
                </div>
              )}
            </div>
            <input
              className="hidden"
              multiple={true}
              type="file"
              accept={type}
              ref={fileInputRef}
              onChange={onChangeFile}
            />
          </div>
        ) : (
          <div>
            {uploading ? (
              <div
                className={`${
                  theme == 'dark' ? 'dark:bg-night-black' : 'bg-ghost-white'
                }   focus:bg-platinum border-violet-blue border-2 rounded border-dashed grid justify-center items-center h-16 w-full`}
              >
                <Loader
                  active
                  color="#464BBA"
                  className="m-auto w-10 h-10 pr-10"
                />
              </div>
            ) : (
              <div className="border-violet-blue border-2 rounded border-dashed grid justify-center items-center h-16 w-full bg-lavender">
                <div className="flex items-center">
                  <div className="ml-2 text-cool-gray font-figtree text-sm font-semibold">
                    {fileSelected.name}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="pt-2">
          {!isHidden &&
            fileSelected.length > 0 &&
            fileSelected.map((file) => (
              <>
                <div className="p-1 border-b border-b-violet-blue relative">
                  <div className="flex items-center justify-between w-full font-normal p-1 rounded">
                    <div className="flex items-center w-full">
                      {getIconFile(file.type, file)}
                      <div className="ml-2 text-cool-gray font-figtree text-sm">
                        <p className="block whitespace-nowrap truncate w-auto">
                          {file.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
      </>
    );
  })
);

export { onChangeFile };
export default FileUpload;
