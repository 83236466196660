import LinkedIn from '../Theme/icons/linkedin';
import TikTok from '../Theme/icons/tiktok';
import Twitter from '../Theme/icons/twitter';
import Youtube from '../Theme/icons/youtube';

export const SOCIAL_MEDIA_ICONS = {
  twitter: Twitter,
  linkedin: LinkedIn,
  tiktok: TikTok,
  youtube: Youtube,
};

export const SOCIAL_MEDIA_LINKS = {
  twitter: 'https://x.com/',
  linkedin: 'https://www.linkedin.com/in/',
  tiktok: 'https://www.tiktok.com/@',
  youtube: 'https://www.youtube.com/@',
};
