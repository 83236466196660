// Components
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
// MUI
import { Button } from '@mui/material';
// Store
import useAlertCoinsStore from './store';
import { useEffect } from 'react';

const CoinsAlertModal = ({ handleConfirm, customClose }) => {
  // Store
  const { openModal, setOpenModal, setDissmissed } = useAlertCoinsStore();
  // Modal
  const handleClose = () => {
    setOpenModal(false);
    customClose && customClose();
  };

  const localHandleConfirm = () => {
    handleClose();
    handleConfirm();
  };

  const handleDismiss = () => {
    handleClose();
    localStorage.setItem('modal_alert_coins_dismiss', true);
    setDissmissed(true);
  };

  useEffect(() => {
    const dissmissed = localStorage.getItem('modal_alert_coins_dismiss');
    setDissmissed(dissmissed ?? false);
  }, []);

  return (
    <CommonModal open={openModal} handleClose={handleClose}>
      <div className="h-full flex flex-col justify-center items-center gap-8 font-figtree">
        <div className="uppercase text-raisin-black dark:text-crystal-bell text-lg font-bold mb-0 mt-8">
          Budget-Friendly Reminder
        </div>
        <p>
          Heads Up! This prompt is about to use over 1,000 coins. To save coins,
          consider:
        </p>
        <p className="ml-2">
          <strong>1). </strong> Starting a new chat
          <br />
          <strong>2). </strong> Switching to a more coin-efficient model like
          Claude 3 Haiku
        </p>
        <div className="flex w-full gap-4 left-0 items-center justify-center">
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleClose}
            fullWidth
          >
            <span className="font-bold">Cancel</span>
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={localHandleConfirm}
            fullWidth
          >
            <span className="font-bold">Proceed</span>
          </Button>
        </div>
        <Button variant="text" color="primary" onClick={handleDismiss}>
          Dismiss & Hide Alert.
        </Button>
      </div>
    </CommonModal>
  );
};

export default CoinsAlertModal;
