import { useMemo } from 'react';
// RHF
import { useFormContext } from 'react-hook-form';
// Toast
import { toast } from 'react-hot-toast';
// Utils
// Store
import TextAreaHightlight from 'Components/Chat/TextAreaHightlight/TextAreaHightlight';
import useCreateTemplatesStore from 'Features/CreateTemplatesForm/Store';
import CustomItemVariable from '../CustomItemVariable';
// Components
import {
  removeDuplicates,
  toCamelCase,
} from 'Features/CreateTemplatesForm/Utils/functions';
import useThemeStore from 'Theme/store';

const SectionPrompt = () => {
  // Modal's
  const {
    setTextModal,
    setDropdownModal,
    setModalAttachment,
    setModalImage,
    setEditTextModal,
    setEditDropdownModal,
  } = useCreateTemplatesStore();

  // Modals functions
  const handleTextModal = () => setTextModal(true);
  const handleDropdownModal = () => setDropdownModal(true);
  const handleAttachmentModal = () => setModalAttachment(true);
  const handleImageModal = () => {
    if (watch('default_model') === '65651500e319cd7affbf71b6') {
      setModalImage(true);
    } else {
      toast('Currently only GPT-4 Vision supports image attachments', {
        icon: '⚠️',
      });
    }
  };

  const { theme } = useThemeStore();

  // Form
  const { watch, setValue } = useFormContext();

  // Watch variables and files
  const variables = watch('variables');
  const files = watch('files');

  const handleDeleteVariable = (variable) => () => {
    // Remove variable from variables
    const newVariables = variables.filter((i) => i.variable !== variable);
    const currentVariable = variables.find((i) => i.variable === variable);
    setValue('variables', newVariables);

    // Remove variable from prompt if it's not an attachment or image
    if (!['attachment', 'image'].includes(currentVariable.type)) {
      setValue('prompt', watch('prompt').replaceAll(`{{${variable}}}`, ''));
    }
  };

  const handleDeletePromptVariable = (variable) => () => {
    const newPromptVariables = watch('promptVariables').filter(
      (i) => i.variable !== variable
    );

    const currentVariable = watch('promptVariables').find(
      (i) => i.variable === variable
    );

    setValue('promptVariables', newPromptVariables);
    setValue(
      'prompt',
      watch('prompt').replaceAll(`{{${currentVariable.variable}}}`, '')
    );
  };

  const handleDeleteFile = (id) => () => {
    const newFiles = files.filter((i) => i._id !== id);
    setValue('files', newFiles);
  };

  const handleEditVariable = (variable) => () => {
    const selectedVariable = watch('promptVariables').find(
      (i) => i.variable === variable
    );
    if (selectedVariable.type === 'textarea') {
      setTextModal(true);
      setEditTextModal({
        edit: true,
        defaultData: selectedVariable,
      });
    }
    if (selectedVariable.type === 'dropdown') {
      setDropdownModal(true);
      setEditDropdownModal({
        edit: true,
        defaultData: selectedVariable,
      });
    }
  };

  // Textarea prompt value and setter
  const valueTextarea = useMemo(() => watch('prompt') ?? '', [watch('prompt')]);
  const handleTextAreaChange = (newPrompt) => {
    const oldPrompt = watch('prompt');

    setValue('prompt', newPrompt);
    // Regex to identify variables
    const regex = /{{([\w\s.,?!'*#@^&;-]*)}}/g;
    // Extract variables
    const oldMatches = oldPrompt?.match(regex);
    const matches = newPrompt?.match(regex);

    if (matches) {
      // Create variables array
      const createdVariables = matches.map((variable, index) => {
        const oldName =
          oldMatches?.[index].replace('{{', '').replace('}}', '') ?? null;
        let oldDataVariable = {};
        if (oldName !== null && oldName !== undefined) {
          oldDataVariable = watch('promptVariables').find(
            (i) => i.variable === toCamelCase(oldName.trim())
          );
        }
        // Extract variable name
        const name = variable.replace('{{', '').replace('}}', '');
        // Return variable object
        return {
          ...oldDataVariable,
          type: oldDataVariable?.type ?? 'textarea',
          variable: toCamelCase(name.trim()),
          name: name,
        };
      });

      setValue('promptVariables', [...removeDuplicates(createdVariables)]);
    } else {
      setValue('promptVariables', []);
    }
  };

  return (
    <div className="flex flex-col h-full font-figtree">
      <p className={`text-xl font-bold`}>
        Step 2: Prompt and variables workspace
      </p>
      <div className="py-7">
        <label className="uppercase text-sm font-bold">Context</label>
        <p className={`text-sm mt-2`}>
          Any image and attachment fields you add will be automatically
          positioned as context at the start of your prompt.
        </p>
        <div className="flex gap-2 pt-6">
          <button
            onClick={handleImageModal}
            className={` border border-platinum px-4 rounded-md w-fit text-sm ${
              theme == 'dark'
                ? 'dark:bg-lead dark:hover:bg-night-black'
                : 'bg-white hover:bg-ghost-white '
            }`}
          >
            Image
          </button>

          <button
            onClick={handleAttachmentModal}
            className={` border border-platinum px-4 rounded-md w-fit text-sm ${
              theme == 'dark'
                ? 'dark:bg-lead dark:hover:bg-night-black '
                : 'bg-white hover:bg-ghost-white '
            }`}
          >
            Attachment
          </button>
        </div>
      </div>
      <div className="flex h-full flex-col gap-6 pt-2">
        <div className="flex flex-1 h-full flex-col gap-2">
          <label className="uppercase text-sm font-bold">Prompt *</label>
          <ul className={`text-sm list-disc px-4 text-pretty gap-2 pb-2`}>
            <li>
              Enter the texts and detailed instructions for your template.
            </li>
            <li>
              To insert text and dropdown variables, copy and paste the variable
              generated name into the prompt text. Ex: {'{{variable_name}}'}
            </li>
          </ul>
          <div className="flex flex-wrap gap-1">
            {variables
              .filter((i) => ['attachment', 'image'].includes(i.type))
              .map((i) => (
                <CustomItemVariable
                  key={i.name}
                  {...i}
                  onDelete={handleDeleteVariable(i?.variable)}
                  dontShowCopyField
                />
              ))}
            {files.map((i) => (
              <CustomItemVariable
                key={i.name}
                name={i?.name}
                type={'attachment'}
                onSee={() => window.open(i?.url, '_blank')}
                onDelete={handleDeleteFile(i?._id)}
                dontShowCopyField
              />
            ))}
          </div>
          <TextAreaHightlight
            withoutMaxHeight
            classNameContainer={`shadow-none resize-none outline-none focus:outline-none text-md rounded min-w-full text-sm ${
              theme == 'dark'
                ? 'dark:bg-night-black dark:text-crystal-bell'
                : 'bg-white text-raisin-black'
            }`}
            autoFocus
            minRows={10}
            placeholder="A description for your prompt"
            value={valueTextarea}
            onChange={handleTextAreaChange}
            highlightWords={[
              {
                highlight: /{{([\w\s.,?!;-]*)}}/g,
                className:
                  'bg-ghost-white text-cool-gray rounded-md px-2 font-semibold',
              },
            ]}
            classNameMainContainer={`${
              theme == 'dark' ? 'dark:bg-night-black' : 'bg-white'
            } min-h-[15rem] max-h-[15rem]`}
          />
        </div>
        <div className="">
          <label className="uppercase text-xs font-medium">Variables</label>
          <div className="flex flex-col-reverse gap-2">
            {watch('promptVariables')?.map((i) => (
              <CustomItemVariable
                key={i.name}
                {...i}
                onDelete={handleDeletePromptVariable(i?.variable)}
                onEdit={handleEditVariable(i?.variable)}
              />
            ))}
          </div>
          <div className="flex gap-2 pt-6">
            <button
              onClick={handleTextModal}
              className={` border border-platinum px-4 rounded-md w-fit text-sm  ${
                theme == 'dark'
                  ? 'dark:bg-lead dark:hover:bg-night-black dark:text-crystal-bell'
                  : 'bg-seasalt hover:bg-ghost-white text-raisin-black'
              }`}
            >
              Text
            </button>
            <button
              onClick={handleDropdownModal}
              className={` border border-platinum px-4 rounded-md w-fit text-sm  ${
                theme == 'dark'
                  ? 'dark:bg-lead dark:hover:bg-night-black dark:text-crystal-bell'
                  : 'bg-seasalt hover:bg-ghost-white text-raisin-black'
              }`}
            >
              Dropdown
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionPrompt;
