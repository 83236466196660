import { inject, observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import Output from '../Components/Output';

import * as outlineIcons from '@heroicons/react/24/outline';
import * as solidIcons from '@heroicons/react/24/solid';
import { get } from 'lodash';

import {
  EllipsisVerticalIcon,
  PlusCircleIcon,
  SparklesIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import MainBody from '../Components/Body';
import Subheader from '../Components/Subheader';

import Button from '../Components/Button';
import TutorialModal from '../Components/TutorialModal';
import { TranslateContext } from '../Context/translate-context';

import { ImageList, ImageListItem, useMediaQuery } from '@mui/material';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const History = inject('store')(
  observer(({ store, history }) => {
    const matches = useMediaQuery('(min-width:920px)');
    const [width] = useState(window.innerWidth);

    const [list, setList] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hover, setHover] = useState(false);
    const [promptSelected, setPromptSelected] = useState(null);
    const [language, setLanguage] = useState('');
    const [loadingBar, setLoadingBar] = useState(false);
    const [showSkeletonCardsImages, setShowSkeletonCardsImages] =
      useState(true);

    const { search } = useLocation();

    const [openDialogTutorial, setOpenDialogTutorial] = useState(false);

    const { translator } = useContext(TranslateContext);

    const [filters, setFilters] = useState([
      { id: 1, text: 'image', isActive: true },
      { id: 2, text: 'text', isActive: false },
    ]);

    const [selectedFilter, setSelectedFilter] = useState('image');

    const location = useLocation();

    const handleClick = (filterId, filterName) => {
      const updatedButtons = filters.map((button) => {
        if (button.id === filterId) {
          if (button.isActive) {
            setSelectedFilter('no-scope');
          } else {
            setSelectedFilter(filterName);
            setShowSkeletonCardsImages(true);
          }
          return { ...button, isActive: !button.isActive };
        } else {
          return { ...button, isActive: false };
        }
      });
      setFilters(updatedButtons);
    };

    useEffect(() => {
      store.labelSection = translator('sectionToolBar.labelHistory');
    }, []);

    useEffect(() => {
      getHistory();
      setPhotos([]);
    }, [search, selectedFilter]);

    const paramsToObject = (entries) => {
      const result = {};
      for (const [key, value] of entries) {
        result[key] = value;
      }
      return result;
    };

    const getDate = (date) => {
      const parsed = new Date(date);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      };
      return parsed.toLocaleDateString('en-US', options);
    };

    const getHistory = async () => {
      setLoadingBar(true);
      setHover(false);
      setPromptSelected(null);

      const urlParams = new URLSearchParams(location.search);
      const entries = urlParams.entries();
      const result = paramsToObject(entries);

      try {
        const res = await store.api.get('/history', {
          params: { ...result, scope: selectedFilter },
        });
        if (selectedFilter == 'image') {
          let response = res.data.history;
          let tempPhotos = [...photos];
          response.forEach((item) => {
            tempPhotos.push({
              src:
                width > 920
                  ? item.image_thumbnails.image_360
                  : item.image_thumbnails.image_180,
              srcBlur: item.image_thumbnails.image_64,
              width: 1,
              height: 1,
              labelModel: item.tool.model,
              labelStyle: item.attr.style.replace(/_/g, ' '),
              labelCreated: getDate(item.created),
              prompt: item.attr.description,
              toolTo: item.tool.to,
              _id: item._id,
            });
          });
          setPhotos(tempPhotos);
          setLoadingBar(false);
          await sleep(1000);
          setShowSkeletonCardsImages(false);
        } else if (selectedFilter == 'text') {
          setList(res.data.history);
          setPrompt(res.data.history[0]);
        } else {
          setList([]);
          setPrompt(null);
        }
        setLoadingBar(false);
      } catch (err) {
        setLoadingBar(false);
      }
    };

    const setPrompt = async (prompt) => {
      if (promptSelected && promptSelected._id == prompt._id) {
        return;
      }

      if (!hover) {
        setHover(true);
      }

      setLoading(true);
      setPromptSelected(null);
      try {
        const res = await store.api.get(`/history/${prompt._id}`);
        setPromptSelected(res.data.prompt);
        getLanguage(res.data.prompt.attr);
        setLoading(false);
        goToUp();
      } catch (err) {
        setLoading(false);
      }
    };

    const goToUp = () => {
      const size = window.screen.width;
      const ele = document.getElementById('content-wrapper');
      if (ele && size < 1024) {
        ele.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    };

    const getLanguage = (data) => {
      let value = '';
      if (data.language) {
        value = `${data.language}`;
      }
      setLanguage(value);
    };

    const getIcon = (icon) => {
      const Icon = get(solidIcons, icon);
      if (!Icon) get(outlineIcons, icon);
      return Icon ? <Icon className={'h-6 w-6 text-white'} /> : null;
    };

    const getIconInstance = (icon) => {
      const Icon = get(solidIcons, icon);
      if (!Icon) get(outlineIcons, icon);
      return Icon ? Icon : null;
    };

    const goToDetail = () => {
      let route = `${promptSelected.tool.to}?prompt=${promptSelected._id}`;
      history.push(route);
    };

    const onInteraction = (str) => {
      if (str == 'close') {
        setOpenDialogTutorial(false);
      }
    };

    const goToImageDetail = (id, to) => {
      let route = `${to}?prompt=${id}`;
      history.push(route);
    };

    const sleep = (time) => {
      return new Promise((resolve) => setTimeout(resolve, time));
    };

    const goToImageTool = () => {
      let route = `/images`;
      history.push(route);
    };

    return (
      <>
        <Subheader
          title={store.labelSection}
          loading={loadingBar}
          icon="BookmarkIcon"
        />

        <MainBody className="h-full overflow-auto">
          <div className={`px-4 md:px-28 h-full font-figtree`}>
            {/* Filters */}
            <div className="flex justify-start pb-4 pt-2">
              <div className="flex space-x-4">
                {filters.map((button) => (
                  <button
                    key={button.id}
                    className={`flex items-center justify-center gap-2 px-1.5 py-1 rounded-xl border-dashed border-[1px] ${
                      button.isActive
                        ? 'bg-nue-blue text-white border-nue-blue'
                        : 'bg-ghost-white text-battleship-gray'
                    } text-sm`}
                    onClick={() => handleClick(button.id, button.text)}
                  >
                    {button.isActive ? (
                      <XCircleIcon className="w-5 h-5 text-white" />
                    ) : (
                      <PlusCircleIcon className="w-5 h-5 text-cool-gray" />
                    )}
                    <span className="uppercase font-bold mr-1">
                      {button.text}
                    </span>
                  </button>
                ))}
              </div>
            </div>

            {/* Text mode */}
            {selectedFilter == 'text' ? (
              list.length ? (
                <div className="grid lg:grid-cols-2 gap-4 h-[calc(100%_-_7.95rem)]">
                  <div className="h-min mobile-wrapper">
                    {hover ? (
                      <div className="relative">
                        <Output
                          title={
                            promptSelected && promptSelected.tool
                              ? promptSelected.tool.output.title
                              : null
                          }
                          desc={
                            promptSelected && promptSelected.tool
                              ? promptSelected.tool.output.desc
                              : null
                          }
                          Icon={
                            promptSelected
                              ? getIconInstance(
                                  promptSelected.tool.output.Icon
                                ) || getIconInstance(promptSelected.tool.Icon)
                              : null
                          }
                          fromColor={
                            promptSelected
                              ? promptSelected.tool.fromColor
                              : null
                          }
                          toColor={
                            promptSelected ? promptSelected.tool.toColor : null
                          }
                          loading={loading}
                          output={
                            promptSelected && promptSelected.output !== ''
                              ? promptSelected.output
                              : null
                          }
                          outputs={
                            promptSelected && promptSelected.outputs.length
                              ? promptSelected.outputs
                              : null
                          }
                          code={promptSelected ? promptSelected.code : null}
                          image={promptSelected ? promptSelected.image : null}
                          video={promptSelected ? promptSelected.video : null}
                          language={language}
                          outputsColor={
                            promptSelected
                              ? promptSelected.tool.output.color
                              : null
                          }
                          OutputsIcon={
                            promptSelected
                              ? promptSelected.tool.output.Icon
                              : null
                          }
                          typingDone={() => {
                            // eslint-disable-next-line no-console
                            console.log('done');
                          }}
                          noAnimate={true}
                        />
                        <Shortcut
                          className="absolute top-2 right-2 p-1 ml-2 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 group flex flex-col items-center group text-cool-gray"
                          onClick={goToDetail}
                        >
                          <EllipsisVerticalIcon className="w-5 h-5" />
                          <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                            <span className="relative z-10 p-3 text-sm leading-none text-raisin-black bg-seasalt bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                              Go to details
                            </span>
                          </Tooltip>
                        </Shortcut>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="lg:overflow-auto lg:overflow-x-hidden mobile-wrapper">
                    {list.map((prompt, index) => (
                      <div
                        key={index}
                        onMouseEnter={() => setPrompt(prompt)}
                        className="bg-white ml-4 mr-4 mb-4 p-6 rounded-md transition transform hover:scale-105 hover:text-raisin-black border border-platinum shadow-lg z-50"
                      >
                        <div className="flex items-center mb-4">
                          <div
                            className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-${
                              prompt.tool.fromColor
                                ? prompt.tool.fromColor
                                : 'green-400'
                            }`}
                          >
                            {' '}
                            {/*  */}
                            {getIcon(prompt.tool.Icon)}
                          </div>
                          <div className="text-left ml-3 w-full">
                            <div className="text-lg leading-6 font-medium text-raisin-black">
                              {prompt.tool.title}
                            </div>
                            <div
                              className={`text-xs leading-6 font-bold text-${
                                prompt.tool.fromColor
                                  ? prompt.tool.fromColor
                                  : 'green-500'
                              }`}
                            >
                              {prompt.tool.category.toUpperCase()}
                            </div>
                          </div>
                          <div className="text-right mr-3 w-full">
                            <div className="text-lg leading-6 font-medium text-battleship-gray">
                              {prompt.attr.currentPrompt}
                            </div>
                            <div className="text-xs leading-6 font-extralight text-cool-gray">
                              {getDate(prompt.created)}
                            </div>
                          </div>
                        </div>
                        {prompt.image ? (
                          <div className="h-16 overflow-hidden img-gradient rounded-md">
                            <img
                              src={prompt.image}
                              className="h-full w-full object-cover"
                            />
                          </div>
                        ) : (
                          <div className="line-clamp text-transparent bg-clip-text bg-gradient-to-b from-raisin-black to-cool-gray">
                            {prompt.code
                              ? prompt.code
                              : prompt.outputs.length
                              ? prompt.outputs.join(', ')
                              : prompt.output}
                          </div>
                        )}
                        <div className="mt-4">
                          <ul className="mt-4 flex flex-wrap">
                            {prompt.tags.map((item, index) => (
                              <li
                                className="bg-platinum rounded-md px-3 py-1 mr-2 mb-2 font-normal"
                                key={index}
                              >
                                {item}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col m-auto justify-center items-center px-4 py-4 h-[calc(100%_-_7.95rem)]">
                  <div className="text-battleship-gray text-2xl text-center">
                    You haven't saved any results yet. Let's get started!
                  </div>
                  <div className="flex justify-center mt-8">
                    <Button
                      className="m-auto"
                      title={'Show me how'}
                      onClick={() => {
                        setOpenDialogTutorial(true);
                      }}
                    />
                  </div>
                </div>
              )
            ) : (
              <></>
            )}

            {/* Image mode */}
            {selectedFilter == 'image' ? (
              photos.length ? (
                showSkeletonCardsImages ? (
                  <SkeletonCardsImages></SkeletonCardsImages>
                ) : (
                  <ImageList
                    sx={{ width: 'auto', height: 'auto' }}
                    rowHeight={'auto'}
                    cols={matches ? 4 : 2}
                  >
                    {photos.map((item) => (
                      <ImageListItem
                        key={item.img}
                        className="relative cursor-pointer"
                        onClick={() => {
                          goToImageDetail(item._id, item.toolTo);
                        }}
                      >
                        <LazyLoadImage
                          height="100%"
                          width="100%"
                          src={`${item.src}?w=164&h=164&fit=crop&auto=format`}
                          placeholderSrc={item.srcBlur}
                          srcSet={`${item.src}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          alt={item.labelModel}
                          effect="blur"
                        />
                        <LabelPhoto photo={item}></LabelPhoto>
                      </ImageListItem>
                    ))}
                  </ImageList>
                )
              ) : (
                <div className="flex flex-col m-auto justify-center items-center px-4 py-4 h-[calc(100%_-_7.95rem)]">
                  <img
                    className="mt-8 md:mt-0"
                    src="/images/pr-image-wall-1.png"
                  ></img>
                  <p className="font-medium text-3xl  text-raisin-black text-center">
                    From your mind, to your feed
                  </p>
                  <p className="font-normal text-lg text-battleship-gray mt-4 text-center">
                    Use the{' '}
                    <span className="text-raisin-black font-semibold">
                      image generation tools
                    </span>{' '}
                    to create the dreams you have in your head.
                    <br className="hidden lg:block" /> Review{' '}
                    <span className="text-raisin-black font-semibold">
                      your entire image history
                    </span>{' '}
                    in your personal feed.
                  </p>
                  <div className="flex justify-start pt-4">
                    <div className="flex space-x-4">
                      <button
                        onClick={() => {
                          goToImageTool();
                        }}
                        className="flex items-center justify-center gap-2 px-8 py-2 rounded-md bg-nue-blue text-white text-sm"
                      >
                        <SparklesIcon className="w-7 h-7 text-white mr-1" />
                        <span className="uppercase font-bold mr-1 text-base md:text-lg">
                          try the image tools
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <></>
            )}
          </div>
        </MainBody>

        {openDialogTutorial ? (
          <TutorialModal
            mode={'save_history'}
            onInteraction={onInteraction}
          ></TutorialModal>
        ) : (
          <></>
        )}
      </>
    );
  })
);

const LabelPhoto = ({ photo }) => (
  <div className="absolute bottom-0 bg-raisin-black/80 right-0 w-full px-2 py-1 hidden lg:block">
    <p className="text-right font-bold text-white uppercase text-sm">
      {photo.labelModel}
    </p>
    <p className="text-right font-normal text-white capitalize text-sm">
      {photo.labelStyle}
    </p>
    <p className="text-right font-extralight text-white text-xs">
      {photo.prompt.substring(0, 40)}
    </p>
  </div>
);

const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;

const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;
const SkeletonCardsImages = () => {
  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
      {Array.from({ length: 20 }).map((_, index) => (
        <div
          key={index}
          className="w-full bg-white rounded-sm overflow-hidden border border-platinum shadow-sm h-44 md:h-60 lg:h-80"
        >
          <div className="flex flex-col h-full justify-end relative">
            <div className="px-4 py-2 absolute right-0 bottom-6">
              <div className="bg-seasalt h-4 mb-2 animate-pulse w-20 lg:w-28"></div>
            </div>
            <div className="px-4 py-2 absolute right-0 bottom-0">
              <div className="bg-seasalt h-4 mb-2 animate-pulse w-32 lg:w-40"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default History;
