/* eslint-disable no-console */
import { useRef } from 'react';
import toast from 'react-hot-toast';

const useClipboardComponent = () => {
  const componentRef = useRef(null);

  const copyToClipboard = () => {
    const componentText = componentRef.current.innerText;
    navigator.clipboard
      .writeText(componentText)
      .then(() => {
        console.log('Copied: ', componentText);
      })
      .catch((error) => {
        console.error('Error copying: ', error);
      });
  };
  const copyToClipboardWithoutRef = (message, showToast = true) => {
    navigator.clipboard
      .writeText(message)
      .then(() => {
        if (showToast) toast.success('Copied');
      })
      .catch(() => {
        if (showToast) toast.error('Error copying');
      });
  };

  const copy = async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      return false;
    }
  };
  return { componentRef, copyToClipboard, copyToClipboardWithoutRef, copy };
};

export default useClipboardComponent;
