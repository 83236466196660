function SvgComponent(props) {
  return (
    <svg
      width="27"
      height="34"
      viewBox="0 0 27 34"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.5 8.5H19.2776L23.6109 0H12.0558L0.5 22.6664H9.16667V34L26.5 8.5Z"
        fill="inherit"
      />
    </svg>
  );
}

export default SvgComponent;
