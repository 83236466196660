/* eslint-disable no-unused-vars */
import {
  ArrowDownIcon,
  ArrowsRightLeftIcon,
  ArrowUpIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  GiftIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  TrophyIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { inject, observer } from 'mobx-react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import StraicoIcon from '../../../Theme/icons/straico';
import StraicoSymbolIcon from '../../../Theme/icons/straicoSymbol';

import useChatStore, { EVENT_NEW_CHAT } from 'Components/Chat/store';
import ToggleTheme from 'Components/Common/ToggleTheme';
import DiscordModal from 'Components/DiscordModal';
import ShortModal from 'Components/ShortModal';
import useThemeStore from 'Theme/store';
import HelpModal from '../../../Components/HelpModal';
import { TranslateContext } from '../../../Context/translate-context';
import MobilePowerPanel from './MobilePowerPanel';

const SidebarComponent = ({ store, ...props }) => {
  const context = useContext(TranslateContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [openDialogHelp, setOpenDialogHelp] = useState(false);
  const [openDialogDiscord, setOpenDialogDiscord] = useState(false);
  const [openDialogShort, setOpenDialogShort] = useState(false);
  const [showDeal, setShowDeal] = useState(true);
  const [initialCoins, setInitialCoins] = useState(store.profile.credits);
  const [openSettings, setOpenSettings] = useState(false);

  const [coinsMark, setCoinsMark] = useState({
    box: 'bg-lavender text-violet-blue dark:bg-night-black dark:text-palladium',
    icon: CurrencyDollarIcon,
    iconColor: 'text-violet-blue dark:text-palladium',
  });

  const setPowerPanelOpen = useChatStore((state) => state.setPowerPanelOpen);
  const addEvent = useChatStore((state) => state.addEvent);

  const [mobilePowerPanel, setMobilePowerPanel] = useState(false);

  const inputHintList = [
    { index: 0, value: undefined },
    { index: 1, value: undefined },
  ];

  const paramsToObject = (entries) => {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  };

  const getImageProfile = () => store.profile.avatar.svg;

  const getPlaceholder = () => {
    let placeholder;
    if (location.pathname === '/history') {
      placeholder = context.translator(
        'sectionToolBar.placeholderHistorySearch'
      );
      store.labelSection = context.translator('sectionToolBar.labelHistory');
    } else {
      placeholder = context.translator('sectionToolBar.placeholderSearch');
      store.labelSection = context.translator('sectionToolBar.labelDashboard');
    }
    return placeholder;
  };

  const updateSearchParam = (search, paramName, paramValue) => {
    const searchParams = new URLSearchParams(search);
    searchParams.set(paramName, paramValue);
    return '?' + searchParams.toString();
  };

  const shortcutHandler = (e) => {
    if (e.keyCode === 75 && e.ctrlKey) {
      e.preventDefault();
      document.getElementById('q').focus();
      document.getElementById('q').select();
    }
  };

  const animateCoinsDown = async () => {
    setCoinsMark({
      box: 'bg-cool-gray text-nue-blue dark:bg-ship-grey dark:text-tropical-indigo',
      icon: ArrowDownIcon,
      iconColor: 'text-nue-blue dark:text-tropical-indigo',
    });

    await sleep(800);

    setCoinsMark({
      box: 'bg-lavender text-violet-blue dark:bg-night-black dark:text-palladium',
      icon: CurrencyDollarIcon,
      iconColor: 'text-violet-blue dark:text-palladium',
    });
  };

  const animateCoinsUp = async () => {
    setCoinsMark({
      box: 'bg-cool-gray text-nue-blue dark:bg-ship-grey dark:text-tropical-indigo',
      icon: ArrowUpIcon,
      iconColor: 'text-nue-blue dark:text-tropical-indigo',
    });

    await sleep(800);

    setCoinsMark({
      box: 'bg-lavender text-violet-blue dark:bg-night-black dark:text-palladium',
      icon: CurrencyDollarIcon,
      iconColor: 'text-violet-blue dark:text-palladium',
    });
  };

  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const ceroCoins = () => {
    setCoinsMark({
      box: 'bg-red-100 text-red-500 border border-red-100',
      icon: CurrencyDollarIcon,
      iconColor: 'text-red-500',
    });
  };

  const handleFilters = (value) => {
    const keys = ['category', 'title', 'tag', 'after', 'before', 'prompt'];
    const pattern = new RegExp(
      `(${keys.join('|')}):((\\d{4}-\\d{2}-\\d{2})|(\\w+))`,
      'g'
    );
    const result = {};
    let match;

    while ((match = pattern.exec(value)) !== null) {
      result[match[1]] = match[2];
    }

    result.keyword = value.replace(pattern, '').trim();
    navigate({
      pathname: location.pathname,
      search: '?' + new URLSearchParams(result).toString(),
    });
  };

  const onChangeToolsKeyword = (e) => {
    if (location.pathname === '/history') {
      store.toolsKeyword = e.target.value;
      handleFilters(e.target.value);
    } else {
      store.onChangeToolsKeyword(e);
    }
  };

  const onKeyUp = (e) => {
    if (location.pathname !== '/search' && location.pathname !== '/history') {
      navigate('/search');
    }
    if (e.keyCode === 8) {
      if (store.toolsKeyword === '' && location.pathname !== '/history') {
        navigate('/dashboard');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', shortcutHandler);

    return () => {
      document.removeEventListener('keydown', shortcutHandler);
    };
  }, []);

  useEffect(() => {
    if (initialCoins !== store.profile.credits) {
      let difference = store.profile.creditsPrevious - store.profile.credits;
      if (difference > 0) {
        animateCoinsDown();
      } else if (difference < 0) {
        animateCoinsUp();
      }
      setInitialCoins(store.profile.credits);
    }

    if (store.profile.credits <= 0) {
      ceroCoins();
    }
  }, [store.profile.credits]);

  useEffect(() => {
    if (location.pathname === '/chat') {
      store.labelSection = context.translator('sectionToolBar.labelChat');
    }
  }, [location.pathname, store.labelSection, context]);

  const handleClickOutside = (event) => {
    if (
      settingsRef.current &&
      !settingsRef.current.contains(event.target) &&
      profileRef.current &&
      !profileRef.current.contains(event.target)
    ) {
      setOpenSettings(false);
    }
  };

  const handleClickOutsidePanel = (event) => {
    if (
      mobilePanelRef.current &&
      !mobilePanelRef.current.contains(event.target)
    ) {
      setMobilePowerPanel(false);
    }
  };

  const settingsRef = useRef(null);
  const profileRef = useRef(null);
  const mobilePanelRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleClickOutsidePanel);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleClickOutsidePanel);
    };
  }, []);

  const fromColor = useMemo(() => {
    if (store.profile.credits <= 0) {
      return 'bg-red-200 text-red-600';
    }
    if (store.profile.status === 'trialing') {
      return '';
    }
    if (store.profile.status === 'active') {
      return '';
    }
    if (store.profile.status === 'incomplete') {
      return '';
    }
    return 'bg-red-200 text-red-600';
  }, [store.profile.credits, store.profile.status]);

  const onInteraction = async (str) => {
    if (str == 'close') {
      setOpenDialogHelp(false);
      setOpenDialogDiscord(false);
      setOpenDialogShort(false);
    }
  };
  const { theme: themeValue, isSidebarOpen } = useThemeStore();

  return (
    <>
      <Textarea
        readOnly
        name="copy-textarea"
        id="copy-textarea"
        value={store.copyToClipboardText}
      />

      <div className="bg-seasalt dark:bg-lead sticky top-0 z-40 font-figtree">
        <div className="relative flex mx-auto px-1 md:px-1 select-none p-1">
          <div className="flex-1 flex md:justify-center">
            {!openDialogHelp && !isSidebarOpen && (
              <>
                <div className="items-center hidden md:block">
                  <StraicoIcon className="fill-nue-blue duration-500" />
                </div>
                <div className="flex gap-[10px] items-center md:hidden">
                  <div
                    className="w-12 h-12 flex items-center justify-center cursor-pointer select-none"
                    onClick={() => setMobilePowerPanel(true)}
                  >
                    <StraicoSymbolIcon
                      className={`fill-nue-blue ${
                        mobilePowerPanel && 'rotate-[360deg]'
                      }`}
                    />
                  </div>
                  <PlusIcon
                    onClick={() => addEvent(EVENT_NEW_CHAT)}
                    className="w-12 h-12 text-nue-blue cursor-pointer select-none"
                  />
                </div>
              </>
            )}
          </div>

          <div className="flex mr-2">
            <div
              className={`items-center flex ${coinsMark.box} font-normal text-base rounded-[0.625rem] px-2 my-1 mr-[2px] lg:mr-2 tracking-wide max-md:text-[26px] max-md:rounded-[37px]`}
            >
              <coinsMark.icon
                className={`w-6 h-6 max-md:w-[26px] max-md:h-[26px] ${coinsMark.iconColor} mr-0.5`}
              />
              {store.profile.credits.toLocaleString('en-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>

            <div
              ref={profileRef}
              onClick={() => setOpenSettings(!openSettings)}
              className={`flex px-1 lg:px-2 cursor-pointer ${fromColor} hover:bg-platinum rounded-md transition items-center dark:hover:bg-night-black max-md:hidden`}
            >
              {store.profile.avatar.svg ? (
                <div className="avatar">
                  <div className="w-10 rounded">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getImageProfile(),
                      }}
                    />
                  </div>
                </div>
              ) : (
                <UserCircleIcon
                  className={`w-7 h-7 transition ${
                    themeValue == 'dark'
                      ? 'dark:text-palladium'
                      : 'text-cool-gray'
                  }`}
                />
              )}
            </div>
          </div>

          {openSettings && (
            <>
              <div
                ref={settingsRef}
                className={`${
                  themeValue == 'dark' ? 'bg-night-black' : 'bg-white'
                } absolute top-[91.7%] right-3 p-5 rounded-xl shadow-[0_0_6.4px_#00000040]`}
              >
                <div className="flex">
                  <button
                    onClick={() => {
                      setOpenDialogDiscord(true);
                      setOpenSettings(false);
                    }}
                    className={`flex items-center justify-center px-1 rounded-md text-sm ${
                      themeValue == 'dark'
                        ? 'hover:bg-ship-grey text-crystal-bell'
                        : 'hover:bg-lavender text-cool-gray'
                    }`}
                  >
                    <img
                      className="h-4 px-1 block"
                      src={
                        themeValue == 'dark'
                          ? '/svg/discord-mark-white.svg'
                          : '/svg/discord-mark-blue.svg'
                      }
                    />
                  </button>
                  <ToggleTheme />
                  <a
                    href="https://straico.com/faq/"
                    target="_blank"
                    rel="noreferrer"
                    className="flex "
                    onClick={() => setOpenSettings(false)}
                  >
                    <button
                      className={`flex items-center justify-center px-1 rounded-md ${
                        themeValue == 'dark'
                          ? 'text-crystal-bell hover:bg-ship-grey'
                          : 'text-raisin-black hover:bg-lavender'
                      }  text-sm`}
                    >
                      <QuestionMarkCircleIcon className="w-5 h-5 mx-1" />
                    </button>
                  </a>
                </div>
                <div className="bg-cool-gray h-[3px] w-auto mx-8 my-3 rounded-full"></div>
                <div
                  className={`${
                    themeValue == 'dark'
                      ? 'text-raisin-black'
                      : 'text-crystal-bell'
                  } text-sm leading-6`}
                >
                  <NavLink
                    to="/my-profile"
                    onClick={() => {
                      setOpenSettings(false);
                      setPowerPanelOpen(false);
                    }}
                    className={({ isActive }) =>
                      `px-3 py-2 flex rounded-lg cursor-pointer items-center ${
                        isActive
                          ? 'bg-nue-blue hover:bg-nue-blue/70 text-white transition'
                          : `${
                              themeValue == 'dark'
                                ? 'text-crystal-bell hover:bg-ship-grey hover:text-tropical-indigo'
                                : 'text-raisin-black hover:bg-lavender hover:text-violet-blue'
                            }`
                      }`
                    }
                    end
                  >
                    <li className="flex items-center justify-center">
                      {store.profile.avatar.svg ? (
                        <div className="avatar">
                          <div className="w-6 rounded">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: store.profile.avatar.svg,
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <UserCircleIcon
                          className={`w-5 h-5 transition`}
                          to="/my-profile"
                        />
                      )}
                      <span className="origin-left duration-200 ml-2">
                        User settings
                      </span>
                    </li>
                  </NavLink>

                  <NavLink
                    to="/transactions"
                    onClick={() => {
                      setOpenSettings(false);
                      setPowerPanelOpen(false);
                    }}
                    className={({ isActive }) =>
                      `px-3 py-2 flex rounded-lg cursor-pointer items-center ${
                        isActive
                          ? 'bg-nue-blue hover:bg-nue-blue/70 text-white transition'
                          : `${
                              themeValue == 'dark'
                                ? 'text-crystal-bell hover:bg-ship-grey hover:text-tropical-indigo'
                                : 'text-raisin-black hover:bg-lavender hover:text-violet-blue'
                            }`
                      }`
                    }
                  >
                    <li className="flex items-center justify-center">
                      <ArrowsRightLeftIcon
                        className={`w-5 h-5 transition`}
                        to="/transactions"
                      />
                      <span className="origin-left duration-200 ml-2">
                        Transactions
                      </span>
                    </li>
                  </NavLink>

                  <NavLink
                    to="/my-profile/pricing"
                    onClick={() => {
                      setOpenSettings(false);
                      setPowerPanelOpen(false);
                    }}
                    end
                    className={({ isActive }) =>
                      `px-3 py-2 flex rounded-lg cursor-pointer items-center ${
                        isActive
                          ? 'bg-nue-blue hover:bg-nue-blue/70 text-white transition'
                          : `${
                              themeValue == 'dark'
                                ? 'text-crystal-bell hover:bg-ship-grey hover:text-tropical-indigo'
                                : 'text-raisin-black hover:bg-lavender hover:text-violet-blue'
                            }`
                      }`
                    }
                  >
                    <li className="flex items-center justify-center">
                      <CreditCardIcon
                        to="/my-profile/pricing"
                        end
                        className={`w-5 h-5 transition`}
                      />
                      <span className="origin-left duration-200 ml-2">
                        Billing and plans
                      </span>
                    </li>
                  </NavLink>

                  <NavLink
                    to="/rewards"
                    onClick={() => {
                      setOpenSettings(false);
                      setPowerPanelOpen(false);
                    }}
                    className={({ isActive }) =>
                      `px-3 py-2 flex rounded-lg cursor-pointer items-center ${
                        isActive
                          ? 'bg-nue-blue hover:bg-nue-blue/70 text-white transition'
                          : `${
                              themeValue == 'dark'
                                ? 'text-crystal-bell hover:bg-ship-grey hover:text-tropical-indigo'
                                : 'text-raisin-black hover:bg-lavender hover:text-violet-blue'
                            }`
                      }`
                    }
                  >
                    <li className="flex items-center justify-center">
                      <TrophyIcon
                        className={`w-5 h-5 transition`}
                        to="/rewards"
                      />
                      <span className="origin-left duration-200 ml-2">
                        Rewards
                      </span>
                    </li>
                  </NavLink>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {props.children}

      {/* Dialogs */}
      {openDialogDiscord && (
        <DiscordModal onInteraction={onInteraction}></DiscordModal>
      )}
      {openDialogHelp && <HelpModal onInteraction={onInteraction}></HelpModal>}
      {openDialogShort && <ShortModal onInteraction={onInteraction} />}
      {showDeal &&
        store.profile.sale5000 &&
        store.profile.scope == 'general' && (
          <div
            className="w-80 absolute bottom-0 right-0 cursor-pointer mb-6 mr-4 z-40 p-3 bg-seasalt hover:bg-lavender rounded-md hover:shadow-md text-cool-gray border border-platinum hover:text-raisin-black shadow-md font-figtree text-center"
            onClick={() => {
              navigate('/my-profile/pricing');
            }}
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowDeal(false);
              }}
              className="absolute right-1 top-2 p-1 hover:bg-ghost-white text-cool-gray text-sm rounded-md z-50"
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
            <div className="mt-4 mb-4 flex">
              <div className="m-auto">
                <GiftIcon className="w-8 h-8 md:w-16 md:h-16" />
              </div>
            </div>
            <div className="text-nue-blue block text-lg leading-tight font-bold mb-2 uppercase mt-2">
              Exclusive Limited-Time Offer!
            </div>
            <div className="text-4xl font-bold">5,000 coins</div>
            <div className="text-base uppercase font-bold">
              for $5.00{' '}
              <span className="bg-nue-blue text-white px-1.5 rounded-sm">
                54% off
              </span>
            </div>
            <p className="my-2">Grab this exclusive deal now!</p>
          </div>
        )}

      {mobilePowerPanel && (
        <MobilePowerPanel
          ref={mobilePanelRef}
          onClose={() => setMobilePowerPanel(false)}
          setOpenDialogDiscord={setOpenDialogDiscord}
        />
      )}
    </>
  );
};

const Textarea = styled.textarea`
  position: fixed;
  right: -9990px;
  top: -9990px;
`;

export default inject('store')(observer(SidebarComponent));
