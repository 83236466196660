function SvgComponent({ fillColor = '#4286F5', ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="8" fill={fillColor} />
      <g clipPath="url(#clip0_325_16219)">
        <path
          d="M20 12.016C17.9242 12.1434 15.9666 13.0255 14.496 14.496C13.0255 15.9666 12.1434 17.9242 12.016 20H11.984C11.8568 17.9241 10.9748 15.9664 9.5042 14.4958C8.03358 13.0252 6.07588 12.1432 4 12.016V11.984C6.07588 11.8568 8.03358 10.9748 9.5042 9.5042C10.9748 8.03358 11.8568 6.07588 11.984 4H12.016C12.1434 6.07581 13.0255 8.03339 14.496 9.50397C15.9666 10.9745 17.9242 11.8566 20 11.984V12.016Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_325_16219">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
