import { create } from 'zustand';

const useChatMessageStore = create((set) => ({
  enableFile: false,
  setEnableFile: (enableFile) => set({ enableFile }),

  editMessChange: false,
  setEditMessChange: () =>
    set((state) => {
      return { editMessChange: !state.editMessChange };
    }),
}));

export default useChatMessageStore;
