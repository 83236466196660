import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

const RedirectActivateAppsumo = inject('store')(
  observer((props) => {
    const { store } = props;
    const history = useLocation();

    useEffect(() => {
      const redirectFunction = async () => {
        store.handleLogout();
      };

      redirectFunction();
    }, [store, history]);

    return <></>;
  })
);

export default RedirectActivateAppsumo;
