function SvgComponent({ fillColor = '#BE7070', ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width="24" height="24" rx="8" fill={fillColor} />
      <path
        d="M19.1401 8.66083C19.0193 8.60682 18.936 8.48213 18.9242 8.33789C18.9196 8.2876 18.791 7.03937 17.5176 5.89034C17.2558 5.65202 16.9691 5.47217 16.6644 5.35475C15.6923 4.98001 13.0612 4.36985 9.63255 6.79086C8.96787 7.26016 8.40784 7.78529 7.95291 8.3638C7.86291 8.48216 7.85479 8.49225 7.76885 8.60908C7.03476 9.63289 6.61476 10.8149 6.51626 12.1446C6.37592 14.0392 6.94657 15.5486 7.17829 15.9704C7.27801 16.152 7.22613 16.3887 7.06354 16.5003C7.04301 16.5143 6.87195 16.6606 6.78236 16.6763C6.75707 16.6807 4.45037 17.1042 4 18.586L6.01767 18.6641C6.02533 18.6644 6.03295 18.665 6.04058 18.6658C6.16736 18.6801 6.29111 18.6301 6.38045 18.529L6.52836 18.361C6.78423 18.0703 7.15223 17.9388 7.51241 18.0091C7.87263 18.0794 8.17831 18.3424 8.33016 18.7127L8.33731 18.7301C8.41422 18.9176 8.58975 19.0243 8.77403 18.9953C8.78234 18.994 8.79065 18.993 8.799 18.9924L10.8072 18.8403C10.2826 17.2536 8.53837 17.1202 8.51934 17.119C8.42413 17.1128 8.07141 16.7958 8.07122 16.637C8.0711 16.5212 8.09047 13.7779 10.0671 11.7684C11.6421 10.167 13.979 9.51266 17.013 9.82336C17.0159 9.82363 17.0187 9.82398 17.0216 9.82437C17.227 9.85115 19.0417 10.0904 19.5056 10.2335C19.5852 10.2504 19.8885 10.0689 19.9791 9.72517C20.1203 9.1885 19.514 8.82796 19.1401 8.66083ZM17.3404 7.65461C17.1853 7.77923 16.9689 7.74042 16.8569 7.5679C16.7449 7.39539 16.7798 7.15446 16.9348 7.02977C17.0898 6.90515 17.3063 6.94397 17.4183 7.11648C17.5303 7.28899 17.4954 7.52992 17.3404 7.65461Z"
        fill="white"
      />
      <path
        d="M9.26163 6.14022C9.36804 6.06512 9.47451 5.99257 9.58101 5.92179C9.3396 5.78308 8.99323 5.63425 8.55729 5.57906C7.55483 5.45207 6.45287 5.86614 5.33447 6.782C5.95325 6.89017 6.79678 7.1415 7.54874 7.72001C7.99967 7.16953 8.56126 6.63466 9.26163 6.14022Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgComponent;
