const FileExcel = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      fill={color}
    >
      <path d="M15.27,13.64l-1.97,2.36,1.97,2.36c.35,.42,.3,1.05-.13,1.41-.19,.16-.41,.23-.64,.23-.29,0-.57-.12-.77-.36l-1.73-2.08-1.73,2.08c-.2,.24-.48,.36-.77,.36-.23,0-.45-.08-.64-.23-.42-.35-.48-.98-.13-1.41l1.97-2.36-1.97-2.36c-.35-.42-.3-1.05,.13-1.41,.43-.35,1.05-.3,1.41,.13l1.73,2.08,1.73-2.08c.35-.42,.98-.48,1.41-.13,.42,.35,.48,.98,.13,1.41Zm6.73-3.15v8.51c0,2.76-2.24,5-5,5H7c-2.76,0-5-2.24-5-5V5C2,2.24,4.24,0,7,0h4.51c1.87,0,3.63,.73,4.95,2.05l3.48,3.49c1.32,1.32,2.05,3.08,2.05,4.95ZM15.05,3.46c-.32-.32-.67-.59-1.05-.81V7c0,.55,.45,1,1,1h4.34c-.22-.38-.49-.73-.81-1.05l-3.48-3.49Zm4.95,7.02c0-.16,0-.33-.02-.49h-4.98c-1.65,0-3-1.35-3-3V2.02c-.16-.02-.32-.02-.49-.02H7c-1.65,0-3,1.35-3,3v14c0,1.65,1.35,3,3,3h10c1.65,0,3-1.35,3-3V10.49Z" />
    </svg>
  );
};

export default FileExcel;
