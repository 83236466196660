import { useEffect, useState } from 'react';
import { CircularProggres } from './CircularProgress';

const Toast = ({
  message,
  colorBox,
  colorText,
  time,
  random,
  maxWidth,
  minWidth,
  position,
  onClick,
  fixed = false,
}) => {
  const [toast, setToast] = useState(null);

  useEffect(() => {
    setToast(message);
  }, [message]);

  useEffect(() => {
    setToast(message);
    if (!fixed) {
      const interval = setInterval(() => {
        if (message) {
          setToast(null);
        }
        clearInterval(interval);
      }, time);
    }
  }, [message, colorBox, colorText, time, random]);

  return (
    <>
      {toast ? (
        <div
          className={`toast toast-top toast-end mt-14 ${
            position && 'items-' + position
          }`}
        >
          {typeof toast == 'string' ? (
            <div
              className={`bg-${colorBox ? colorBox : 'green-500'} text-${
                colorText ? colorText : 'white'
              }  flex w-full items-center justify-center gap-4 p-4 rounded-2xl ${
                maxWidth && `max-w-[${maxWidth}]`
              } ${minWidth && `min-w-[${minWidth}]`}`}
            >
              <div>
                <div dangerouslySetInnerHTML={{ __html: toast }} />
              </div>
            </div>
          ) : (
            <>
              {toast.length ? (
                toast.map((message, index) => (
                  <div
                    key={index}
                    className={`bg-${colorBox ? colorBox : 'green-500'} text-${
                      colorText ? colorText : 'white'
                    }  flex w-full items-center justify-center gap-4 p-4 rounded-2xl ${
                      maxWidth &&
                      `max-w-[${maxWidth}] ${minWidth && `min-w-[${minWidth}]`}`
                    }`}
                  >
                    <div>
                      <span>
                        {typeof message == 'string' ? message : message.value}
                      </span>
                      <div
                        className={`flex ${
                          message.source || message.percent
                            ? 'justify-between items-center'
                            : 'justify-end'
                        } mt-4`}
                      >
                        {message.source ? (
                          <div className="w-16 h-16">
                            {message.source.type == 'image' && (
                              <img src={message.source.url} />
                            )}
                            {message.source.type == 'video' && (
                              <video controls className="w-full">
                                <source
                                  src={message.source.url}
                                  type="video/mp4"
                                />
                              </video>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                        {message.percent ? (
                          <div>
                            <CircularProggres
                              radius={28}
                              weight={10}
                              percent={message.percent}
                              color="#84cc16"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {onClick ? (
                          <button
                            className={`select-none py-2 px-4 border-2 border-${
                              colorText ? colorText : 'gray-400'
                            } bg-${colorBox ? colorBox : 'gray-500'} text-${
                              colorText ? colorText : 'white'
                            }  rounded-md flex md:inline-flex font-medium text-lg cursor-pointer transition`}
                            onClick={() => onClick(message)}
                          >
                            View
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Toast;
