export function replaceAllVariablesPrompt(str, data) {
  const regex = /{{([\w\s.,?!'*#@^&;-]*)}}/g;

  const replaceFn = (match) => {
    const valueMatch = match.replace('{{', '').replace('}}', '');
    const variable = data?.promptVariables?.find(
      (i) => i.variable === valueMatch
    );
    const variableName = data?.promptVariables?.find(
      (i) => i.name === valueMatch
    );

    return variable
      ? '${' + variable?.variable + '}'
      : '${' + variableName?.variable + '}';
  };

  return str.replace(regex, replaceFn);
}
