function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_848_650)">
        <path
          d="M24.0002 21.242V24H22.0002V21.242C21.9999 20.6008 21.8096 19.9742 21.4533 19.4411C21.097 18.9081 20.5907 18.4926 19.9984 18.2472C19.406 18.0018 18.7543 17.9375 18.1254 18.0624C17.4965 18.1873 16.9188 18.4959 16.4652 18.949L13.4652 21.949C12.8169 22.6009 12.0459 23.1179 11.1966 23.4699C10.3472 23.822 9.43657 24.0021 8.51719 24H3.12319C2.50588 24 1.90242 23.817 1.38913 23.4741C0.875844 23.1312 0.475781 22.6437 0.23954 22.0734C0.00329909 21.5031 -0.0585075 20.8755 0.0619368 20.2701C0.182381 19.6646 0.479666 19.1085 0.916194 18.672L4.10019 15.485L7.63919 2.227C7.71769 1.93898 7.83941 1.66453 8.00019 1.413L13.0872 6.5L4.10619 15.482L5.52019 16.9L14.5002 7.915L19.5872 13C19.3356 13.1604 19.0612 13.2818 18.7732 13.36L5.52019 16.9L2.33019 20.09C2.17465 20.247 2.06898 20.4464 2.02646 20.6633C1.98393 20.8802 2.00645 21.1047 2.09119 21.3088C2.17593 21.5129 2.3191 21.6874 2.50272 21.8104C2.68634 21.9334 2.9022 21.9993 3.12319 22H8.51719C9.17415 22.0016 9.82491 21.8729 10.4318 21.6215C11.0388 21.37 11.5898 21.0007 12.0532 20.535L15.0532 17.535C15.7864 16.8024 16.7203 16.3037 17.7369 16.1018C18.7535 15.8998 19.8072 16.0038 20.7647 16.4005C21.7222 16.7973 22.5406 17.4689 23.1165 18.3307C23.6924 19.1924 23.9999 20.2055 24.0002 21.242ZM19.2872 0.3C18.8861 0.104843 18.4463 0.00231722 18.0002 0L10.5362 0C10.2089 0.000507701 9.88399 0.054555 9.57419 0.16L14.5002 5.087L19.2872 0.3ZM20.8402 11.426C20.9456 11.1162 20.9997 10.7912 21.0002 10.464V3C20.9979 2.5549 20.8954 2.11603 20.7002 1.716L15.9152 6.5L20.8402 11.426Z"
          fill="#797779"
        />
      </g>
      <defs>
        <clipPath id="clip0_848_650">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
