const FileVideo = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      fill={color}
    >
      <path d="M19.95,5.536l-3.485-3.485c-1.322-1.322-3.08-2.05-4.95-2.05H7C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5V10.485c0-1.87-.728-3.627-2.05-4.95Zm-1.414,1.414c.315,.315,.564,.674,.781,1.05h-4.317c-.551,0-1-.449-1-1V2.683c.376,.217,.735,.466,1.05,.781l3.485,3.485Zm1.464,12.05c0,1.654-1.346,3-3,3H7c-1.654,0-3-1.346-3-3V5c0-1.654,1.346-3,3-3h4.515c.165,0,.323,.032,.485,.047V7c0,1.654,1.346,3,3,3h4.953c.016,.162,.047,.32,.047,.485v8.515Zm-3.625-5.782l-1.375,1.031v-1.25c0-.552-.448-1-1-1H7c-.552,0-1,.448-1,1v6c0,.552,.448,1,1,1h7c.552,0,1-.448,1-1v-1.25l1.375,1.031c.669,.502,1.625,.024,1.625-.812v-3.938c0-.837-.955-1.314-1.625-.812Zm-3.375,4.782h-5v-4h5v4Z" />
    </svg>
  );
};

export default FileVideo;
