import { create } from 'zustand';

const useCreateTemplatesStore = create((set) => ({
  openModal: false,
  setOpenModal: (openModal) => set({ openModal }),
  selectedComponent: null,
  setSelectedComponent: (component) => set({ selectedComponent: component }),
  step: 1,
  maxSteps: 3,
  setMaxSteps: (maxSteps) => set({ maxSteps }),
  setStep: (step) => set({ step }),
  dropdownModal: false,
  setDropdownModal: (dropdownModal) => set({ dropdownModal }),
  textModal: false,
  setTextModal: (textModal) => set({ textModal }),
  editTextModal: {
    edit: false,
    defaultData: {},
  },
  setEditTextModal: (editTextModal) => set({ editTextModal }),
  editDropdownModal: {
    edit: false,
    defaultData: {},
  },
  setEditDropdownModal: (editDropdownModal) => set({ editDropdownModal }),
  createOptionDropdownModal: false,
  setCreateOptionDropdownModal: (createOptionDropdownModal) =>
    set({ createOptionDropdownModal }),
  dropdownItems: [],
  setDropdownItems: (dropdownItems) => set({ dropdownItems }),
  loadingCreateTemplate: false,
  setLoadingCreateTemplate: (loadingCreateTemplate) =>
    set({ loadingCreateTemplate }),
  edit: false,
  setEdit: (edit) => set({ edit }),
  defaultData: {},
  setDefaultData: (defaultData) =>
    set({
      defaultData,
    }),
  modalImage: false,
  setModalImage: (modalImage) => set({ modalImage }),
  modalAttachment: false,
  setModalAttachment: (modalAttachment) => set({ modalAttachment }),
  modalConfirmClose: false,
  setModalConfirmClose: (modalConfirmClose) => set({ modalConfirmClose }),
}));

export default useCreateTemplatesStore;
