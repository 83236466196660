function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      {...props}
    >
      <path
        d="M9.51444 18.8889H15.4854M12.4999 1V2.27778M20.6317 4.36827L19.7281 5.2718M24 12.4999H22.7222M2.27778 12.4999H1M5.27173 5.2718L4.3682 4.36827M7.98231 17.0177C5.48729 14.5226 5.48729 10.4774 7.98231 7.9824C10.4773 5.48739 14.5225 5.48739 17.0176 7.9824C19.5126 10.4774 19.5126 14.5226 17.0176 17.0177L16.3185 17.7167C15.5098 18.5254 15.0555 19.6222 15.0555 20.7659V21.4444C15.0555 22.8558 13.9113 24 12.4999 24C11.0885 24 9.94438 22.8558 9.94438 21.4444V20.7659C9.94438 19.6222 9.49006 18.5254 8.68138 17.7167L7.98231 17.0177Z"
        stroke="inherit"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
