function reverseText(texto) {
  return texto.replace(/\$\{([^}]+)\}/g, '{{$1}}');
}

const buildDataToFormEdit = (templateData) => ({
  id: templateData?._id,
  default_model: templateData?.default_model?.id,
  multi_default_models:
    templateData?.multi_default_models?.length > 0
      ? templateData?.multi_default_models.map((model) => model._id)
      : [],
  name: templateData?.title,
  description: templateData?.desc,
  promptVariables: templateData?.fields
    ? templateData?.fields
        ?.map((item) => {
          return {
            variable: item?.attr,
            name: item?.title,
            type: item?.type,
            options: item?.options?.map((option, index) => {
              return {
                id: index,
                title: option?.label,
                value: option?.value,
              };
            }),
            placeholder: item?.placeholder,
            maxLength: item?.maxLength,
          };
        })
        .filter((item) => !['attachment', 'image'].includes(item?.type)) ?? []
    : [],
  variables: templateData?.fields
    ? templateData?.fields
        ?.map((item) => {
          return {
            variable: item?.attr,
            name: item?.title,
            type: item?.type,
            options: item?.options?.map((option, index) => {
              return {
                id: index,
                title: option?.label,
                value: option?.value,
              };
            }),
            placeholder: item?.placeholder,
            maxLength: item?.maxLength,
          };
        })
        .filter((item) => ['attachment', 'image'].includes(item?.type))
    : [],
  prompt: reverseText(templateData?.prompt_values?.value),
  location: templateData?.parent_folder ?? '',
  files: templateData?.files ?? [],
  is_public: templateData?.public,
  // SEO
  slug: templateData?.slug,
  seo_title: templateData?.seo_title,
  meta_description: templateData?.meta_description,
  keywords: templateData?.keywords,
});

export { buildDataToFormEdit, reverseText };
