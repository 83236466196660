/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const useTemplateShare = (api, showPromptModal, getToolsAndFilterSidebar) => {
  const [hasUpdatedUrl, setHasUpdatedUrl] = useState(false);
  const navigate = useNavigate(); // react-router hook para navegar

  // Función para clonar la herramienta
  const cloneToolShare = async (id) => {
    try {
      const res = await api.post('/tool/clone/' + id);
      await getToolsAndFilterSidebar('');

      const data = await res.data;
      const toolId = data?.tool?._id;

      // Query params
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('selectedTool', toolId);
      deleteShareData(urlParams);

      await navigate(
        {
          pathname: window.location.pathname,
          search: urlParams.toString(),
        },
        { shallow: true }
      );

      toast.dismiss('loading tool');
      toast(
        "Check out the 'SHARED WITH ME' folder; you'll find the newly added template there.",
        { icon: '🥳', duration: 10000 }
      );
    } catch (error) {
      toast.dismiss('loading tool');
      console.error(error);
    }
  };

  // Fucntion to delete share data from URL
  const deleteShareData = (urlParams) => {
    urlParams?.delete('shareToolId');
    urlParams?.delete('shareToolClone');
    urlParams?.delete('shareToolIsPublic');
  };

  // Function to select the tool
  const selectTool = async (toolId) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('selectedTool', toolId);
    deleteShareData(urlParams);

    await navigate(
      {
        pathname: window.location.pathname,
        search: urlParams.toString(),
      },
      { shallow: true }
    );

    toast.dismiss('loading tool');
  };

  // Hook `useEffect` for share tool
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const shareToolId = urlParams.get('shareToolId');
    const shareToolClone = urlParams.get('shareToolClone') === 'true';
    const shareToolIsPublic = urlParams.get('shareToolIsPublic') === 'true';

    if (shareToolClone && !hasUpdatedUrl) {
      deleteShareData(urlParams);

      const newUrl =
        window.location.pathname +
        (urlParams.toString() ? `?${urlParams.toString()}` : '');
      window.history.replaceState(null, '', newUrl);

      setHasUpdatedUrl(true);

      toast.loading('Shared template is loading ...', { id: 'loading tool' });

      if (shareToolId && !shareToolIsPublic) {
        cloneToolShare(shareToolId);
        sessionStorage.removeItem('toolClone');
      }

      if (shareToolIsPublic) {
        selectTool(shareToolId);
      }

      if (showPromptModal) {
        toast.dismiss('loading tool');
        if (shareToolIsPublic) {
          toast('This template is public search in the template section', {
            icon: '🥳',
            duration: 10000,
          });
          sessionStorage.removeItem('toolClone');
        }
      }
    }
  }, [showPromptModal, hasUpdatedUrl]);
};

export default useTemplateShare;
