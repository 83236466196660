import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { inject, observer } from 'mobx-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// Icons
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';

const VerifyMail = ({ store }) => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [userVerified, setUserVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [onlyonce, setOnlyonce] = useState(false);

  const checkCode = async () => {
    if (onlyonce) return;
    setOnlyonce(false);
    try {
      await store.api
        .post('/auth/checkmail', {
          code: code,
        })
        .then(({ data }) => {
          setUserVerified(true);
          setTimeout(() => {
            store.loginWithDataTokenAndProfile(data);
            if (data.profile.reference_1pr != null) {
              console.log('1PR email-----');
              console.log(data.profile.email);
              console.log(data.profile.customerId);
              console.log(data.profile.reference_1pr);
              console.log('--------');
              window.fpr('referral', {
                email: data.profile.email,
                uid: data.profile.customerId,
              });
            }
            navigate('/');
          }, 4000);
        });
    } catch (err) {
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    checkCode();
  }, [code, navigate]);

  return (
    <>
      <Helmet>
        <title>{`Email verification - Straico`}</title>
      </Helmet>

      <div className="h-screen flex flex-col">
        <header className="h-auto bg-nue-blue font-medium text-2xl py-3">
          <Link to="/login">
            <img
              className="h-7 pl-4 -mt-1"
              src="/svg/logo-snow-100.svg"
              alt="Logo"
            />
          </Link>
        </header>

        <div className="lg:grid lg:grid-cols-3 flex-1 bg-seasalt h-full overflow-auto">
          {/* Image container */}
          <div className="login-banner-wrapper bg-ghost-white h-full lg:col-span-2">
            <LazyLoadImage
              className="h-[calc(100vh_-_48px)] w-full object-cover"
              src="/images/straico-wall-16.png"
              placeholderSrc="/images/straico-wall-16-blur.png"
              effect="blur"
            />
          </div>
          {/* Forms container */}
          <div className="flex items-center justify-center mt-8 lg:mt-0 bg-seasalt font-figtree text-raisin-black">
            {userVerified ? (
              <div>
                <div
                  className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-lavender mt-8 lg:mt-0`}
                >
                  <CheckCircleIcon
                    className={`h-8 w-8 text-violet-blue`}
                    aria-hidden="true"
                  />
                </div>

                <div className="text-center mt-3">
                  <div className="text-3xl font-bold mb-1 text-center uppercase">
                    Email has been verified!
                  </div>
                  <p className="text-base text-cool-gray">
                    Please wait. Redirecting...
                  </p>
                </div>
                <div className="text-center mt-8">
                  <p className="text-cool-gray text-sm">
                    Go to{' '}
                    <Link to="/login" className="text-violet-blue text-sm">
                      Log in
                    </Link>
                  </p>
                </div>
              </div>
            ) : null}
            {errorMessage ? (
              <div>
                <div
                  className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-lavender mt-8 lg:mt-0`}
                >
                  <ExclamationCircleIcon
                    className={`h-8 w-8 text-violet-blue`}
                    aria-hidden="true"
                  />
                </div>

                <div className="mt-3 text-center">
                  <div className="text-3xl font-bold mb-1 text-center uppercase">
                    Link is not valid
                  </div>
                  <p className="text-base text-cool-gray">
                    Misspell code or it expired
                  </p>
                </div>
                <div className="text-center mt-8">
                  <p className="text-cool-gray text-sm">
                    Go to{' '}
                    <Link to="/login" className="text-violet-blue text-sm">
                      Log in
                    </Link>
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default inject('store')(observer(VerifyMail));
