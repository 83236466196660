import { useEffect } from 'react';

const useCopyWithoutFormat = () => {
  useEffect(() => {
    const handleCopy = (event) => {
      event.preventDefault();
      const selectedText = window.getSelection().toString();
      event.clipboardData.setData('text/plain', selectedText);
    };

    document.addEventListener('copy', handleCopy);

    return () => {
      document.removeEventListener('copy', handleCopy);
    };
  }, []);
};

export default useCopyWithoutFormat;
