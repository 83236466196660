import { createContext } from 'react';
import { useTranslation } from 'react-i18next';

export const TranslateContext = createContext();

export const TranslateContextProvider = ({ children }) => {
  const { t, i18n } = useTranslation();

  const translator = (key) => {
    return t(key);
  };

  const changer = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <TranslateContext.Provider value={{ translator, changer }}>
      {children}
    </TranslateContext.Provider>
  );
};
