function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M7.05233 11.1928C7.23214 10.8339 7.33333 10.4288 7.33333 10C7.33333 9.57123 7.23214 9.16609 7.05233 8.80717M7.05233 11.1928C6.61452 12.0667 5.71065 12.6667 4.66667 12.6667C3.19391 12.6667 2 11.4728 2 10C2 8.52724 3.19391 7.33333 4.66667 7.33333C5.71065 7.33333 6.61452 7.93326 7.05233 8.80717M7.05233 11.1928L12.9477 14.1405M7.05233 8.80717L12.9477 5.8595M12.9477 5.8595C13.3855 6.73341 14.2893 7.33333 15.3333 7.33333C16.8061 7.33333 18 6.13943 18 4.66667C18 3.19391 16.8061 2 15.3333 2C13.8606 2 12.6667 3.19391 12.6667 4.66667C12.6667 5.09544 12.7679 5.50058 12.9477 5.8595ZM12.9477 14.1405C12.7679 14.4994 12.6667 14.9046 12.6667 15.3333C12.6667 16.8061 13.8606 18 15.3333 18C16.8061 18 18 16.8061 18 15.3333C18 13.8606 16.8061 12.6667 15.3333 12.6667C14.2893 12.6667 13.3855 13.2666 12.9477 14.1405Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
