export const buildDataTreeDNDChat = (data, parentRoot) => {
  return data?.map((elem) => ({
    id: elem._id,
    parent: !parentRoot ? elem.parent_folder ?? 0 : 0,
    droppable: elem.type === 'folder',
    text: elem.name,
    data: elem,
    matchingMessages: elem.messages || [],
  }));
};
