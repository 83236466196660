import { FolderIcon } from '@heroicons/react/24/solid';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const TabIcon = ({ icon, title, active, onClick }) => {
  const { theme } = useThemeStore();
  const onLocalClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <div
      onClick={onLocalClick}
      className={cn(
        ' px-4 py-2 rounded-xl font-figtree font-semibold text-xs cursor-pointer border w-[148px] h-[66px] flex justify-center items-center flex-col gap-[6px]',
        {
          'bg-lavender text-violet-blue border-violet-blue':
            active && theme !== 'dark',
          'bg-ship-grey text-tropical-indigo border-tropical-indigo':
            active && theme === 'dark',
          'text-raisin-black bg-seasalt border-platinum':
            !active && theme !== 'dark',
          'text-crystal-bell': !active && theme === 'dark',
        }
      )}
    >
      {icon ?? <FolderIcon className="w-4 h-4" />}
      <p className="truncate w-[130px] text-center">{title}</p>
    </div>
  );
};

export default TabIcon;
