import Anthropic from './anthropic';
import Cohere from './cohere';
import Deepseek from './deepseek';
import Dolphin from './dolphin';
import Goliath from './goliath';
import Google from './google';
import Gryphe from './gryphe';
import Meta from './meta';
import Mistral from './mistral';
import Nous from './nous';
import Nvidia from './nvidia';
import OpenAI from './openAI';
import Perplexity from './perplexity';
import Qwen from './qwen';
import Reflection from './reflection';
import XAI from './xAI';

const modelIconsPalette = {
  Anthropic: {
    100: (props) => <Anthropic {...props} fillColor="#DDBAA2" />,
    200: (props) => <Anthropic {...props} fillColor="#CBAB9E" />,
    300: (props) => <Anthropic {...props} fillColor="#B89C9A" />,
    400: (props) => <Anthropic {...props} fillColor="#A68E96" />,
    500: (props) => <Anthropic {...props} fillColor="#937F92" />,
    600: (props) => <Anthropic {...props} fillColor="#81708E" />,
    700: (props) => <Anthropic {...props} fillColor="#6E618A" />,
    800: (props) => <Anthropic {...props} fillColor="#5C5286" />,
    900: (props) => <Anthropic {...props} fillColor="#494382" />,
    1000: (props) => <Anthropic {...props} fillColor="#37357E" />,
    1100: (props) => <Anthropic {...props} fillColor="#24267A" />,
    1200: (props) => <Anthropic {...props} fillColor="#121776" />,
  },
  Cohere: {
    100: (props) => <Cohere {...props} fillColor="#96C4AC" />,
    200: (props) => <Cohere {...props} fillColor="#8AB4A7" />,
    300: (props) => <Cohere {...props} fillColor="#7EA5A2" />,
    400: (props) => <Cohere {...props} fillColor="#72959D" />,
    500: (props) => <Cohere {...props} fillColor="#668598" />,
    600: (props) => <Cohere {...props} fillColor="#5A7593" />,
    700: (props) => <Cohere {...props} fillColor="#4E668F" />,
    800: (props) => <Cohere {...props} fillColor="#42568A" />,
    900: (props) => <Cohere {...props} fillColor="#364685" />,
    1000: (props) => <Cohere {...props} fillColor="#2A3680" />,
    1100: (props) => <Cohere {...props} fillColor="#1E277B" />,
    1200: (props) => <Cohere {...props} fillColor="#121776" />,
  },
  Deepseek: {
    100: (props) => <Deepseek {...props} fillColor="#FFD18C" />,
    200: (props) => <Deepseek {...props} fillColor="#E9C08A" />,
    300: (props) => <Deepseek {...props} fillColor="#D4AF88" />,
    400: (props) => <Deepseek {...props} fillColor="#BE9E86" />,
    500: (props) => <Deepseek {...props} fillColor="#A98D84" />,
    600: (props) => <Deepseek {...props} fillColor="#937C82" />,
    700: (props) => <Deepseek {...props} fillColor="#7E6C80" />,
    800: (props) => <Deepseek {...props} fillColor="#685B7E" />,
    900: (props) => <Deepseek {...props} fillColor="#534A7C" />,
    1000: (props) => <Deepseek {...props} fillColor="#3D397A" />,
    1100: (props) => <Deepseek {...props} fillColor="#282878" />,
    1200: (props) => <Deepseek {...props} fillColor="#121776" />,
  },
  Dolphin: {
    100: (props) => <Dolphin {...props} fillColor="#F3ADAD" />,
    200: (props) => <Dolphin {...props} fillColor="#DF9FA8" />,
    300: (props) => <Dolphin {...props} fillColor="#CA92A3" />,
    400: (props) => <Dolphin {...props} fillColor="#B6849E" />,
    500: (props) => <Dolphin {...props} fillColor="#A17699" />,
    600: (props) => <Dolphin {...props} fillColor="#8D6994" />,
    700: (props) => <Dolphin {...props} fillColor="#785B8F" />,
    800: (props) => <Dolphin {...props} fillColor="#644E8A" />,
    900: (props) => <Dolphin {...props} fillColor="#4F4085" />,
    1000: (props) => <Dolphin {...props} fillColor="#3B3280" />,
    1100: (props) => <Dolphin {...props} fillColor="#26257B" />,
    1200: (props) => <Dolphin {...props} fillColor="#121776" />,
  },
  Goliath: {
    100: (props) => <Goliath {...props} fillColor="#9ACE8E" />,
    200: (props) => <Goliath {...props} fillColor="#8EBD8C" />,
    300: (props) => <Goliath {...props} fillColor="#81AD8A" />,
    400: (props) => <Goliath {...props} fillColor="#759C87" />,
    500: (props) => <Goliath {...props} fillColor="#698B85" />,
    600: (props) => <Goliath {...props} fillColor="#5C7B83" />,
    700: (props) => <Goliath {...props} fillColor="#506A81" />,
    800: (props) => <Goliath {...props} fillColor="#435A7F" />,
    900: (props) => <Goliath {...props} fillColor="#37497D" />,
    1000: (props) => <Goliath {...props} fillColor="#2B387A" />,
    1100: (props) => <Goliath {...props} fillColor="#1E2878" />,
    1200: (props) => <Goliath {...props} fillColor="#121776" />,
  },
  Google: {
    100: (props) => <Google {...props} fillColor="#EEADEA" />,
    200: (props) => <Google {...props} fillColor="#DA9FDF" />,
    300: (props) => <Google {...props} fillColor="#C692D5" />,
    400: (props) => <Google {...props} fillColor="#B284CA" />,
    500: (props) => <Google {...props} fillColor="#9E76C0" />,
    600: (props) => <Google {...props} fillColor="#8A69B5" />,
    700: (props) => <Google {...props} fillColor="#765BAB" />,
    800: (props) => <Google {...props} fillColor="#624EA0" />,
    900: (props) => <Google {...props} fillColor="#4E4096" />,
    1000: (props) => <Google {...props} fillColor="#3A328B" />,
    1100: (props) => <Google {...props} fillColor="#262581" />,
    1200: (props) => <Google {...props} fillColor="#121776" />,
  },
  Gryphe: {
    100: (props) => <Gryphe {...props} fillColor="#BEC3E9" />,
    200: (props) => <Gryphe {...props} fillColor="#AEB3DF" />,
    300: (props) => <Gryphe {...props} fillColor="#9FA4D4" />,
    400: (props) => <Gryphe {...props} fillColor="#8F94CA" />,
    500: (props) => <Gryphe {...props} fillColor="#7F84BF" />,
    600: (props) => <Gryphe {...props} fillColor="#7075B5" />,
    700: (props) => <Gryphe {...props} fillColor="#6065AA" />,
    800: (props) => <Gryphe {...props} fillColor="#5156A0" />,
    900: (props) => <Gryphe {...props} fillColor="#414695" />,
    1000: (props) => <Gryphe {...props} fillColor="#31368B" />,
    1100: (props) => <Gryphe {...props} fillColor="#222780" />,
    1200: (props) => <Gryphe {...props} fillColor="#121776" />,
  },
  Meta: {
    100: (props) => <Meta {...props} fillColor="#85BAFA" />,
    200: (props) => <Meta {...props} fillColor="#7BABEE" />,
    300: (props) => <Meta {...props} fillColor="#709CE2" />,
    400: (props) => <Meta {...props} fillColor="#668ED6" />,
    500: (props) => <Meta {...props} fillColor="#5B7FCA" />,
    600: (props) => <Meta {...props} fillColor="#5170BE" />,
    700: (props) => <Meta {...props} fillColor="#4661B2" />,
    800: (props) => <Meta {...props} fillColor="#3C52A6" />,
    900: (props) => <Meta {...props} fillColor="#31439A" />,
    1000: (props) => <Meta {...props} fillColor="#27358E" />,
    1100: (props) => <Meta {...props} fillColor="#1C2682" />,
    1200: (props) => <Meta {...props} fillColor="#121776" />,
  },
  Mistral: {
    100: (props) => <Mistral {...props} fillColor="#EBA688" />,
    200: (props) => <Mistral {...props} fillColor="#D79986" />,
    300: (props) => <Mistral {...props} fillColor="#C48C85" />,
    400: (props) => <Mistral {...props} fillColor="#B07F83" />,
    500: (props) => <Mistral {...props} fillColor="#9C7281" />,
    600: (props) => <Mistral {...props} fillColor="#886580" />,
    700: (props) => <Mistral {...props} fillColor="#75587E" />,
    800: (props) => <Mistral {...props} fillColor="#614B7D" />,
    900: (props) => <Mistral {...props} fillColor="#4D3E7B" />,
    1000: (props) => <Mistral {...props} fillColor="#393179" />,
    1100: (props) => <Mistral {...props} fillColor="#262478" />,
    1200: (props) => <Mistral {...props} fillColor="#121776" />,
  },
  Nous: {
    100: (props) => <Nous {...props} fillColor="#70CFFF" />,
    200: (props) => <Nous {...props} fillColor="#67BEF3" />,
    300: (props) => <Nous {...props} fillColor="#5FAEE6" />,
    400: (props) => <Nous {...props} fillColor="#569DDA" />,
    500: (props) => <Nous {...props} fillColor="#4E8CCD" />,
    600: (props) => <Nous {...props} fillColor="#457BC1" />,
    700: (props) => <Nous {...props} fillColor="#3D6BB4" />,
    800: (props) => <Nous {...props} fillColor="#345AA8" />,
    900: (props) => <Nous {...props} fillColor="#2C499B" />,
    1000: (props) => <Nous {...props} fillColor="#23388F" />,
    1100: (props) => <Nous {...props} fillColor="#1B2882" />,
    1200: (props) => <Nous {...props} fillColor="#121776" />,
  },
  Nvidia: {
    100: (props) => <Nvidia {...props} fillColor="#C8F37D" />,
    200: (props) => <Nvidia {...props} fillColor="#B7DF7C" />,
    300: (props) => <Nvidia {...props} fillColor="#A7CB7C" />,
    400: (props) => <Nvidia {...props} fillColor="#96B77B" />,
    500: (props) => <Nvidia {...props} fillColor="#86A37A" />,
    600: (props) => <Nvidia {...props} fillColor="#758F7A" />,
    700: (props) => <Nvidia {...props} fillColor="#657B79" />,
    800: (props) => <Nvidia {...props} fillColor="#546779" />,
    900: (props) => <Nvidia {...props} fillColor="#445378" />,
    1000: (props) => <Nvidia {...props} fillColor="#333F77" />,
    1100: (props) => <Nvidia {...props} fillColor="#232B77" />,
    1200: (props) => <Nvidia {...props} fillColor="#121776" />,
  },
  OpenAI: {
    100: (props) => <OpenAI {...props} fillColor="#90E7D0" />,
    200: (props) => <OpenAI {...props} fillColor="#85D4C8" />,
    300: (props) => <OpenAI {...props} fillColor="#79C1C0" />,
    400: (props) => <OpenAI {...props} fillColor="#6EAEB7" />,
    500: (props) => <OpenAI {...props} fillColor="#629BAF" />,
    600: (props) => <OpenAI {...props} fillColor="#5788A7" />,
    700: (props) => <OpenAI {...props} fillColor="#4B769F" />,
    800: (props) => <OpenAI {...props} fillColor="#406397" />,
    900: (props) => <OpenAI {...props} fillColor="#34508F" />,
    1000: (props) => <OpenAI {...props} fillColor="#293D86" />,
    1100: (props) => <OpenAI {...props} fillColor="#1D2A7E" />,
    1200: (props) => <OpenAI {...props} fillColor="#121776" />,
  },
  Perplexity: {
    100: (props) => <Perplexity {...props} fillColor="#8ED6E1" />,
    200: (props) => <Perplexity {...props} fillColor="#83C5D7" />,
    300: (props) => <Perplexity {...props} fillColor="#77B3CE" />,
    400: (props) => <Perplexity {...props} fillColor="#6CA2C4" />,
    500: (props) => <Perplexity {...props} fillColor="#6191BA" />,
    600: (props) => <Perplexity {...props} fillColor="#567FB0" />,
    700: (props) => <Perplexity {...props} fillColor="#4A6EA7" />,
    800: (props) => <Perplexity {...props} fillColor="#3F5C9D" />,
    900: (props) => <Perplexity {...props} fillColor="#344B93" />,
    1000: (props) => <Perplexity {...props} fillColor="#293A89" />,
    1100: (props) => <Perplexity {...props} fillColor="#1D2880" />,
    1200: (props) => <Perplexity {...props} fillColor="#121776" />,
  },
  Qwen: {
    100: (props) => <Qwen {...props} fillColor="#F7BECB" />,
    200: (props) => <Qwen {...props} fillColor="#E2AFC3" />,
    300: (props) => <Qwen {...props} fillColor="#CDA0BC" />,
    400: (props) => <Qwen {...props} fillColor="#B990B4" />,
    500: (props) => <Qwen {...props} fillColor="#A481AC" />,
    600: (props) => <Qwen {...props} fillColor="#8F72A4" />,
    700: (props) => <Qwen {...props} fillColor="#7A639D" />,
    800: (props) => <Qwen {...props} fillColor="#655495" />,
    900: (props) => <Qwen {...props} fillColor="#50458D" />,
    1000: (props) => <Qwen {...props} fillColor="#3C3585" />,
    1100: (props) => <Qwen {...props} fillColor="#27267E" />,
    1200: (props) => <Qwen {...props} fillColor="#121776" />,
  },
  Reflection: {
    100: (props) => <Reflection {...props} fillColor="#D8B1F7" />,
    200: (props) => <Reflection {...props} fillColor="#C6A3EB" />,
    300: (props) => <Reflection {...props} fillColor="#B495E0" />,
    400: (props) => <Reflection {...props} fillColor="#A287D4" />,
    500: (props) => <Reflection {...props} fillColor="#9079C8" />,
    600: (props) => <Reflection {...props} fillColor="#7E6BBC" />,
    700: (props) => <Reflection {...props} fillColor="#6C5DB1" />,
    800: (props) => <Reflection {...props} fillColor="#5A4FA5" />,
    900: (props) => <Reflection {...props} fillColor="#484199" />,
    1000: (props) => <Reflection {...props} fillColor="#36338D" />,
    1100: (props) => <Reflection {...props} fillColor="#242582" />,
    1200: (props) => <Reflection {...props} fillColor="#121776" />,
  },
  XAI: {
    100: (props) => <XAI {...props} fillColor="#B3AEAE" />,
    200: (props) => <XAI {...props} fillColor="#A4A0A9" />,
    300: (props) => <XAI {...props} fillColor="#9693A4" />,
    400: (props) => <XAI {...props} fillColor="#87859F" />,
    500: (props) => <XAI {...props} fillColor="#78779A" />,
    600: (props) => <XAI {...props} fillColor="#6A6995" />,
    700: (props) => <XAI {...props} fillColor="#5B5C8F" />,
    800: (props) => <XAI {...props} fillColor="#4D4E8A" />,
    900: (props) => <XAI {...props} fillColor="#3E4085" />,
    1000: (props) => <XAI {...props} fillColor="#2F3280" />,
    1100: (props) => <XAI {...props} fillColor="#21257B" />,
    1200: (props) => <XAI {...props} fillColor="#121776" />,
  },
};

const convert = (palette) => {
  const newObject = {};

  Object.keys(palette).forEach((key) => {
    const value = palette[key];

    if (typeof value === 'object') {
      Object.keys(value).forEach((variantKey) => {
        const newKey = `${key}-${variantKey}`;
        newObject[newKey] = value[variantKey];
      });

      if (value[100]) {
        newObject[key] = value[100];
      }
    }
  });

  return newObject;
};

export const icons = convert(modelIconsPalette);
