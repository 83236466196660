import { useEffect } from 'react';
// Mobx
import { inject, observer } from 'mobx-react';
// Components
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
import useThemeStore from 'Theme/store';
// Store
import { useChat } from 'Context/Chat/Chat.context';
import useTreeDNDTemplatesStore from 'Features/FoldersTemplates/Store';
import { useCreateTemplateFolder } from './store';
// RHF
import { useForm } from 'react-hook-form';
// Toast
import { CheckIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-hot-toast';

const CreateTemplateFolder = ({ store }) => {
  const { open, setOpen } = useCreateTemplateFolder();
  const { setSelectedFolder, selectedFolder } = useTreeDNDTemplatesStore();
  const { templatesFolders, setTemplatesFolders } = useChat();

  const { handleSubmit, register, setValue, reset } = useForm();

  const { theme } = useThemeStore();

  const handleClose = () => {
    setOpen(false);
    setSelectedFolder(null);
    reset({
      name: '',
    });
  };

  useEffect(() => {
    if (selectedFolder) {
      setValue('name', selectedFolder.name);
    }
  }, [selectedFolder]);

  const editFolder = async (data) => {
    try {
      const response = await store.api.put(
        `/template/folders/${selectedFolder._id}`,
        data
      );
      const newFolder = response.data;
      setTemplatesFolders(
        templatesFolders.map((f) =>
          f._id === selectedFolder._id ? { ...f, ...newFolder } : f
        )
      );
      toast.success('Folder updated successfully');
    } catch (err) {
      toast.error(err?.data?.message);
    } finally {
      handleClose();
    }
  };

  const createFolder = async (data) => {
    try {
      const response = await store.api.post('/template/folders', {
        name: data.name,
        parentFolder: null,
      });
      const newFolder = response.data;
      setTemplatesFolders([newFolder, ...templatesFolders]);
      toast.success('Folder created successfully');
    } catch (error) {
      toast.error('Error creating folder, try again later');
    } finally {
      handleClose();
    }
  };

  const onSubmit = (data) => {
    if (data.name === '') {
      toast('Please enter a folder name', {
        icon: '🚨',
      });
      return;
    }
    if (selectedFolder) {
      editFolder(data);
    } else {
      createFolder(data);
    }
  };

  return (
    <CommonModal
      sx={{
        minHeight: '200px',
      }}
      open={open}
      handleClose={handleClose}
    >
      <div className="flex flex-col gap-5">
        <div className="w-full flex justify-center">
          <div
            className={`font-figtree line-clamp-1 font-bold text-[28px] leading-7 ${
              theme == 'dark' ? 'text-crystal-bell' : 'text-raisin-black'
            }`}
          >
            {`${selectedFolder ? 'Edit' : 'Create'} template folder`}
          </div>
        </div>
        <div className="font-figtree w-96 text-base text-black dark:text-crystal-bell text-center">
          <input
            name="name"
            type="text"
            className={`w-full border border-platinum text-sm leading-[18px] ${
              theme == 'dark'
                ? 'bg-lead text-crystal-bell'
                : 'bg-white text-raisin-black'
            } border-solid px-3 py-2 rounded-[10px] mb-2`}
            placeholder="Folder name"
            {...register('name')}
          />
        </div>
        <div className="flex w-full items-center justify-center">
          <button
            className="flex gap-3 items-center justify-center text-[18px] leading-6 font-semibold rounded-xl px-5 py-2 text-white focus:outline-none bg-nue-blue hover:bg-nue-blue/70"
            onClick={handleSubmit(onSubmit)}
          >
            <CheckIcon className="h-[18px] w-[18px]" aria-hidden="true" />
            <span>{selectedFolder ? 'Update' : 'Create'}</span>
          </button>
        </div>
      </div>
    </CommonModal>
  );
};

export default inject('store')(observer(CreateTemplateFolder));
