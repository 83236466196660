import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import Button from './Button';

import QualifyExperience from './QualifyExperience';
import TutorialModal from './TutorialModal';

const HelpModal = inject('store')(
  observer(({ mode, onInteraction }) => {
    const [openDialogTutorial, setOpenDialogTutorial] = useState(false);
    const [modeDialogTutorial, setModeDialogTutorial] = useState(false);

    const [openDialogQualifyExperience, setOpenDialogQualifyExperience] =
      useState(false);
    const [modeDialogQualifyExperience, setModeDialogQualifyExperience] =
      useState('review_basic');

    const onDialogTutorial = (mode) => {
      setOpenDialogTutorial(true);
      setModeDialogTutorial(mode);
    };

    const onDialogFeedback = () => {
      setOpenDialogQualifyExperience(true);
    };

    return (
      <>
        <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50 font-figtree">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-raisin-black opacity-75"></div>
          </div>

          <div className="relative bg-seasalt rounded-lg overflow-auto shadow-xl transform transition-all sm:max-w-lg sm:w-full max-h-full flex flex-col">
            <div className="px-6 overflow-auto relative">
              <div className="sticky top-0 bg-seasalt">
                <div className="flex items-center pt-6">
                  <div className="flex-shrink-0 inline-flex items-center justify-center h-16 w-16 rounded-full sm:mx-0 bg-ghost-white">
                    <img
                      className="object-contain w-14 h-14 rounded-full"
                      src="/gifs/straico-bot-animated.gif"
                    ></img>
                  </div>
                  <div className="mt-0 ml-4 text-left">
                    <div className="text-lg leading-6 font-medium text-raisin-black">
                      Help section
                    </div>
                    <p className="text-sm text-cool-gray">Hello buddies</p>
                  </div>
                </div>
              </div>

              <div className="dialog-content-container overflow-auto pr-2">
                <div className="mt-4 px-3 text-justify">
                  I'm Pup, a dog who was trained using prompts to provide you
                  with valuable information. Let me share some recommendations
                  with you.
                </div>
                <div className="p-3">
                  <div
                    className="bg-seasalt flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-cool-gray cursor-pointer border border-platinum md:flex relative transform hover:scale-105 hover:text-raisin-black my-4"
                    onClick={() => {
                      onDialogTutorial('save_history');
                    }}
                  >
                    <div className="w-full grid content-between">
                      <div className="p-4">
                        <div
                          className={`uppercase tracking-wide text-sm text-violet-blue font-semibold leading-none`}
                        >
                          TUTORIAL
                        </div>
                        <div
                          href="#"
                          className="block text-lg leading-tight font-medium text-raisin-black leading-none"
                        >
                          Save your history
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="bg-seasalt flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-cool-gray cursor-pointer border border-platinum md:flex relative transform hover:scale-105 hover:text-raisin-black my-4"
                    onClick={() => {
                      onDialogTutorial('create_prompt');
                    }}
                  >
                    <div className="w-full grid content-between">
                      <div className="p-4">
                        <div
                          className={`uppercase tracking-wide text-sm text-violet-blue font-semibold leading-none`}
                        >
                          TUTORIAL
                        </div>
                        <div
                          href="#"
                          className="block text-lg leading-tight font-medium text-raisin-black leading-none"
                        >
                          Create your own tool
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="bg-seasalt flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-cool-gray cursor-pointer border border-platinum md:flex relative transform hover:scale-105 hover:text-raisin-black my-4"
                    onClick={() => {
                      onDialogTutorial('review_prompt');
                    }}
                  >
                    <div className="w-full grid content-between">
                      <div className="p-4">
                        <div
                          className={`uppercase tracking-wide text-sm text-violet-blue font-semibold leading-none`}
                        >
                          TUTORIAL
                        </div>
                        <div
                          href="#"
                          className="block text-lg leading-tight font-medium text-raisin-black leading-none"
                        >
                          Review my tool
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="bg-seasalt flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-cool-gray cursor-pointer border border-platinum md:flex relative transform hover:scale-105 hover:text-raisin-black my-4"
                    onClick={() => {
                      onDialogFeedback();
                    }}
                  >
                    <div className="w-full grid content-between">
                      <div className="p-4">
                        <div
                          className={`uppercase tracking-wide text-sm text-nue-blue font-semibold leading-none`}
                        >
                          FEEDBACK
                        </div>
                        <div
                          href="#"
                          className="block text-lg leading-tight font-medium text-raisin-black leading-none"
                        >
                          Your comments are valuable
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full px-6 pb-6 bottom-0">
              <div></div>
              <Button title={'Close'} onClick={() => onInteraction('close')} />
            </div>
          </div>
        </div>

        {openDialogTutorial ? (
          <TutorialModal
            mode={modeDialogTutorial}
            onInteraction={onInteraction}
          ></TutorialModal>
        ) : (
          <></>
        )}

        {openDialogQualifyExperience ? (
          <QualifyExperience
            modal={modeDialogQualifyExperience}
            onInteraction={onInteraction}
          ></QualifyExperience>
        ) : (
          <></>
        )}
      </>
    );
  })
);

export default HelpModal;
