import { Button } from '@mui/material';
// Icons
import { StopIcon } from '@heroicons/react/24/outline';

const ActionsChat = ({ loading, onStop, chat }) => {
  return (
    <div className="absolute -top-10 w-52 md:w-80">
      {(chat?.messages?.length ?? 0) > 0 && (
        <div className="flex justify-start md:pl-28 gap-4 items-center w-full">
          {loading && (
            <Button
              sx={{ textTransform: 'capitalize', fontWeight: 'normal' }}
              onClick={onStop}
              size="small"
              variant="contained"
              color="primary"
              startIcon={<StopIcon className="w-5 h-5 " />}
            >
              Stop generating
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default ActionsChat;
