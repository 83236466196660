function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_848_655)">
        <path
          d="M20 0H4C1.79 0 0 1.79 0 4V16C0 18.21 1.79 20 4 20H6.92L10.67 23.16C11.05 23.5 11.53 23.67 12.01 23.67C12.49 23.67 12.94 23.51 13.3 23.18L17.15 20H20C22.21 20 24 18.21 24 16V4C24 1.79 22.21 0 20 0ZM17.17 9.62L15 11.39L15.9 14.12C16.02 14.49 15.9 14.9 15.59 15.13C15.28 15.37 14.86 15.38 14.53 15.17L12.01 13.53L9.53 15.19C9.38 15.29 9.2 15.34 9.02 15.34C8.83 15.34 8.63 15.28 8.47 15.16C8.16 14.93 8.03 14.52 8.15 14.15L9.01 11.39L6.83 9.62C6.54 9.37 6.43 8.97 6.56 8.61C6.69 8.25 7.04 8.01 7.42 8.01H10.17L11.14 5.4C11.27 5.04 11.62 4.8 12 4.8C12.38 4.8 12.73 5.04 12.86 5.4L13.83 8.01H16.58C16.96 8.01 17.31 8.25 17.44 8.61C17.57 8.97 17.46 9.38 17.17 9.63V9.62Z"
          fill="#797779"
        />
      </g>
      <defs>
        <clipPath id="clip0_848_655">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
