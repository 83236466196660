// Mobx
import { inject, observer } from 'mobx-react';
// Pages
import ActivateAppsumo from 'Features/Login/ActivateAppsumo';
import RedirectActivateAppsumo from 'Features/Login/RedirectActivateAppsumo';

const AppSumoLayout = ({ store }) => (
  <>{store.isLoggedIn ? <RedirectActivateAppsumo /> : <ActivateAppsumo />}</>
);

export default inject('store')(observer(AppSumoLayout));
