import React from 'react';
import { useState } from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import useThemeStore from 'Theme/store';

const ModalProfileSteps = ({ onClose = () => {}, open }) => {
  const { theme } = useThemeStore();

  return (
    <>
      {open && (
        <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-graphite-300 opacity-75"></div>
          </div>

          <div
            className={`relative ${
              theme == 'dark' ? 'dark:bg-graphite-900' : 'bg-snow-100'
            } rounded-lg overflow-auto shadow-xl transform transition-all sm:max-w-lg sm:w-full max-h-full flex flex-col`}
          >
            <div className="px-6 overflow-auto relative">
              <div
                className={`sticky top-0 ${
                  theme == 'dark' ? 'dark:bg-graphite-900' : 'bg-snow-100'
                }`}
              >
                <div className="flex items-center pt-6">
                  <div className="flex-shrink-0 inline-flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 bg-vanilla-300">
                    <UserCircleIcon
                      className={`w-8 h-8 transition text-vanilla-700`}
                    />
                  </div>
                  <div className="mt-0 ml-4 text-left">
                    <div
                      className={`text-lg leading-6  ${
                        theme == 'dark' ? 'text-flax-900' : 'text-eggplant-700'
                      } uppercase font-bold font-lexend`}
                    >
                      Customize your avatar
                    </div>
                  </div>
                </div>
              </div>

              <div className="dialog-content-container overflow-auto pr-2">
                <div className="mt-4">
                  <p
                    className={`text-base  text-justify font-barlow ${
                      theme == 'dark' ? 'text-snow-100' : 'text-graphite-400'
                    }`}
                  >
                    Personalize your avatar using the various options available
                    in Straico and earn coins by completing the task.
                  </p>
                  <div
                    className={`flex items-end justify-center animate-bounce gap-4 mt-10 font-lexend uppercase font-bold ${
                      theme == 'dark' ? 'text-snow-100' : 'text-graphite-400'
                    }`}
                  >
                    <p className="font-bold">Win 90 coins</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 flex justify-center items-center w-full px-6 pb-6 bottom-0">
              <button
                className={`btn self-center ${
                  theme == 'dark' ? 'bg-graphite-300' : 'bg-graphite-400'
                }`}
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalProfileSteps;
