import { create } from 'zustand';

export const useFoldersChatStore = create((set) => ({
  treeData: [],
  setTreeData: (param) => set(() => ({ treeData: [...param] })),
  addItemsTreeData: (items) => {
    set((prevState) => {
      const existingIds = new Set(prevState.treeData.map((item) => item.id));

      if (Array.isArray(items)) {
        // Filtrar elementos nuevos que no existan en treeData por ID
        const filteredItems = items.filter(
          (newItem) => !existingIds.has(newItem.id)
        );

        // Combinar los elementos filtrados con los existentes
        const updatedTreeData = [...prevState.treeData, ...filteredItems];

        return { treeData: updatedTreeData };
      } else {
        // Si solo se pasa un elemento, actualizar el existente si tiene el mismo ID
        const updatedTreeData = prevState.treeData.map((existingItem) =>
          existingItem.id === items.id
            ? { ...existingItem, ...items }
            : existingItem
        );

        return { treeData: updatedTreeData };
      }
    });
  },
  removeItemTreeData: (itemId) => {
    set((prevState) => {
      // Filtrar elementos que no tengan el ID que se desea eliminar
      const updatedTreeData = prevState.treeData.filter(
        (item) => item.id !== itemId
      );
      return { treeData: updatedTreeData };
    });
  },
  cleanTreeData: () => {
    set(() => ({ treeData: [] }));
  },
  isModalOpen: false,
  setModalOpen: (open) => set(() => ({ isModalOpen: open })),
}));
