import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Loading from '../../Components/Loader';
import { observer, inject } from 'mobx-react';
import StraicoIcon from '../../Theme/icons/straico';

function Login({ store }) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      store.refreshTokenAndProfile().then(() => {
        if (store.profile.status) {
          setLoading(false);
          clearInterval(refreshInterval);
          navigate('/');
        }
      });
    }, 2500);
    return () => {
      clearInterval(refreshInterval);
    };
  }, [store, navigate]);

  return (
    <>
      {loading ? null : <Navigate to="/" />}
      <div className="container mx-auto lg:px-4 py-4 flex-1 flex flex-col md:items-center md:justify-center px-4 font-figtree">
        <div
          className={`min-w-full md:min-w-0 bg-seasalt rounded-md transform shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2 border border-cool-gray`}
        >
          <div className="flex items-center justify-center px-4 py-4">
            <div className="text-center mb-4 mt-4">
              <StraicoIcon className="fill-nue-blue" />
            </div>
          </div>

          <div className="flex items-center justify-center px-4 py-4 text-center">
            <Loading className="w-24 h-24" active />
          </div>

          <div className="text-center my-8">
            <div className="text-2xl font-bold uppercase text-raisin-black">
              Updating your profile
            </div>
            <p className="text-lg text-cool-gray">
              Some stuff in deployment process
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default inject('store')(observer(Login));
