import { buildDataToFormEdit } from 'Components/Chat/TemplateModal/components/AdvancedSection/helpers';
import { useMemo } from 'react';
// Icons
import {
  BoltIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
// Mobx
import { inject, observer } from 'mobx-react';
// Store
import useCreateTemplatesStore from 'Features/CreateTemplatesForm/Store';
import useThemeStore from 'Theme/store';
import useTemplateModalStore from '../../store';
// Context
import { useChat } from 'Context/Chat/Chat.context';
// Toast
import { toast } from 'react-hot-toast';

const HOCAdvancedSection = ({ closeTemplateModal, store }) => {
  // Context
  const {
    getToolsAndFilterSidebar, // Function to get the tools and filter the sidebar
  } = useChat();
  // Store
  const { templateData, setDeleteTemplateModal, setToDelete } =
    useTemplateModalStore();
  const { setDefaultData, setEdit, setOpenModal, setSelectedComponent } =
    useCreateTemplatesStore();

  const OPTIONS = [
    {
      label: 'Edit',
      icon: <PencilSquareIcon className="size-5" />,
      onClick: () => {
        // Generate data to edit the template
        const data = buildDataToFormEdit(templateData);
        // Function to set the data and open the modal
        setDefaultData(data);
        setEdit(true);
        setSelectedComponent('edit');
        setOpenModal(true);
        closeTemplateModal();
      },
    },
    {
      label: 'Duplicate',
      icon: <BoltIcon className="size-5" />,
      onClick: async () => {
        if (
          templateData?._id == '65805947d92e370a3d5625c6' ||
          templateData?._id == '66c786d7e26442bc593e575d'
        ) {
          toast.error(
            'You cannot duplicate image generation templates. Try duplicating a different template.'
          );
          return;
        }
        try {
          await store.api.post('/tool/clone/' + templateData?._id, {
            customCategory: 'My Tools',
          });
          await getToolsAndFilterSidebar('');
          toast.success(
            'Template duplicated, check your "My Templates" section'
          );
        } catch (error) {
          toast.error('Error duplicating template, try again later');
        }
      },
    },
    {
      label: 'Delete',
      icon: <TrashIcon className="size-5" />,
      onClick: () => {
        setToDelete(templateData);
        setDeleteTemplateModal(true);
        closeTemplateModal();
      },
    },
  ];

  const options = useMemo(() => {
    if (templateData?.exclusive_straico)
      return OPTIONS.filter(
        (option) => !['Delete', 'Edit'].includes(option.label)
      );
    return OPTIONS;
  }, [templateData]);

  return <AdvancedSection options={options} />;
};

export const AdvancedSection = ({ options }) => {
  const { theme } = useThemeStore();

  return (
    <div
      className={`grid grid-cols-${
        options.length ?? 1
      } gap-4 font-figtree px-8`}
    >
      {options.map((option, index) => {
        return (
          <div
            key={index}
            onClick={option.onClick}
            className={`flex justify-center items-center m-auto py-2 px-2 cursor-pointer rounded-xl gap-3 text-md leading-6 font-semibold w-32 ${
              theme == 'dark'
                ? 'text-crystal-bell bg-tropical-indigo'
                : 'text-violet-blue bg-lavender'
            }`}
          >
            {option.icon}
            {option.label}
          </div>
        );
      })}
      {options.length === 0 && (
        <p
          className={`text-center ${
            theme == 'dark' ? 'text-crystal-bell' : 'text-raisin-black'
          }`}
        >
          No actions available
        </p>
      )}
    </div>
  );
};

export default inject('store')(observer(HOCAdvancedSection));
