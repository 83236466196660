import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const MiniTab = ({ title, active, onClick }) => {
  const { theme } = useThemeStore();
  const onLocalClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <button
      onClick={onLocalClick}
      className={cn(
        'px-4 py-2 rounded-xl font-figtree font-bold text-xs uppercase',
        {
          'bg-lavender text-violet-blue': active && theme !== 'dark',
          'bg-ship-grey text-tropical-indigo': active && theme === 'dark',
          'text-cool-gray bg-ghost-white': !active && theme !== 'dark',
          'text-white': !active && theme === 'dark',
        }
      )}
    >
      {title}
    </button>
  );
};

export default MiniTab;
