import React, { useEffect } from 'react';
// Mobx
import { inject, observer } from 'mobx-react';
// Components
import Modal from 'Components/Common/Modal/NewModal';
// Store
import { CheckIcon } from '@heroicons/react/24/outline';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';
import useTreeDNDChatStore from '../TreeDNDChat/Store';
import { useUpdateFolderChatModal } from './store';

const UpdateModalFolderChat = inject('store')(
  observer(({ store }) => {
    const [editFolderName, setEditFolderName] = React.useState('');

    const { treeData, setTreeData } = useTreeDNDChatStore();

    const onEditModalFolderChat = async () => {
      try {
        const res = await store.api.put(
          `/folder/chatFolders/${selectedFolder.id}`,
          {
            name: editFolderName,
          }
        );
        if (res.status === 200) {
          const data = treeData.map((item) => {
            if (item.id === selectedFolder.id) {
              item.text = editFolderName;
            }
            return item;
          });
          setTreeData(data);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        onCloseModal();
      }
    };

    const { isModalOpen, setModalOpen, selectedFolder } =
      useUpdateFolderChatModal();
    const onCloseModal = () => setModalOpen(false);

    useEffect(() => {
      if (selectedFolder) {
        setEditFolderName(selectedFolder?.text ?? '');
      }
    }, [isModalOpen]);

    const { theme } = useThemeStore();

    return (
      <Modal
        onClose={onCloseModal}
        open={isModalOpen}
        title="Edit chat folder"
        onConfirm={onEditModalFolderChat}
        buttonConfirmProps={{
          disabled: editFolderName === selectedFolder?.text,
        }}
        onConfirmBtnText="Update"
        onConfirmBtnIcon={
          <CheckIcon className="h-[18px] w-[18px]" aria-hidden="true" />
        }
      >
        <>
          <div className="font-figtree w-96 text-base text-black dark:text-crystal-bell text-center">
            <input
              type="text"
              className={cn(
                'w-full border border-platinum text-sm leading-[18px] border-solid px-3 py-2 rounded-[10px] mb-2',
                theme === 'dark' && 'bg-lead text-crystal-bell',
                theme === 'light' && 'bg-white text-raisin-black'
              )}
              placeholder="Edit the folder name"
              value={editFolderName}
              onChange={(e) => setEditFolderName(e.target.value)}
            />
          </div>
        </>
      </Modal>
    );
  })
);

export default UpdateModalFolderChat;
