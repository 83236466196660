const {
  UserIcon,
  InboxArrowDownIcon,
  PaintBrushIcon,
  PencilSquareIcon,
  CodeBracketIcon,
  PhotoIcon,
  BriefcaseIcon,
  DocumentTextIcon,
  PresentationChartLineIcon,
  PuzzlePieceIcon,
  InboxStackIcon,
  StarIcon,
  CursorArrowRippleIcon,
  NewspaperIcon,
  FilmIcon,
  ArrowTrendingUpIcon,
  MagnifyingGlassIcon,
  BuildingStorefrontIcon,
  LockClosedIcon,
  DevicePhoneMobileIcon,
  KeyIcon,
  AcademicCapIcon,
  ScissorsIcon,
  DocumentChartBarIcon,
} = require('@heroicons/react/24/outline');

export const CATEGORIES_ICONS = {
  'my templates': <UserIcon className="w-4 h-4" />,
  'shared with me': <InboxArrowDownIcon className="w-4 h-4" />,
  'image generation': <PaintBrushIcon className="w-4 h-4" />,
  writing: <PencilSquareIcon className="w-4 h-4" />,
  programming: <CodeBracketIcon className="w-4 h-4" />,
  'image prompting': <PhotoIcon className="w-4 h-4" />,
  content: <DocumentTextIcon className="w-4 h-4" />,
  business: <BriefcaseIcon className="w-4 h-4" />,
  'career development': <PresentationChartLineIcon className="w-4 h-4" />,
  resources: <InboxStackIcon className="w-4 h-4" />,
  'for fun': <PuzzlePieceIcon className="w-4 h-4" />,
  'for educators': <AcademicCapIcon className="w-4 h-4" />,
  'affiliate marketing': <CursorArrowRippleIcon className="w-4 h-4" />,
  'seo expert': <NewspaperIcon className="w-4 h-4" />,
  'youtube growth': <FilmIcon className="w-4 h-4" />,
  'linkedin boosters': <ArrowTrendingUpIcon className="w-4 h-4" />,
  research: <MagnifyingGlassIcon className="w-4 h-4" />,
  hiring: <BuildingStorefrontIcon className="w-4 h-4" />,
  'cybersecurity v0.1 (shared by mgc)': <LockClosedIcon className="w-4 h-4" />,
  'role playing': <StarIcon className="w-4 h-4" />,
  'instagram boosters': <DevicePhoneMobileIcon className="w-4 h-4" />,
  'discretion advised': <KeyIcon className="w-4 h-4" />,
  freestyle: <ScissorsIcon className="w-4 h-4" />,
  'product research': <DocumentChartBarIcon className="w-4 h-4" />,
};

export const CATEGORY_MAP_NAMES = {
  'my tools': 'my templates',
  share: 'shared with me',
};
