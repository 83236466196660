/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-hot-toast';
// ICONS
import { TrophyIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline';
// MOBX
import { observer, inject } from 'mobx-react';
// infinity scroll
import InfiniteScroll from 'react-infinite-scroll-component';
// COMPONENTS
import Subheader from '../Components/Subheader';
import MainBody from '../Components/Body';
import useThemeStore from 'Theme/store';

const RewardCard = inject('store')(
  observer(({ reward }) => {
    const isCompleted = useMemo(() => {
      return reward.progress >= reward.total_progress;
    }, [reward.progress, reward.total_progress]);

    const { theme: themeValue } = useThemeStore();

    return (
      <div
        className={`w-full transition duration-500 transform flex flex-col md:flex-row justify-between items-center ${
          themeValue == 'dark' ? 'bg-umbra' : 'bg-white'
        } rounded-md p-4 gap-6`}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="flex justify-center items-center w-14 h-14 mx-3">
            <TrophyIcon className="text-battleship-gray dark:text-crystal-bell" />
          </div>
          <div className="flex flex-col justify-center items-center mt-1">
            <div
              className={`flex text-cool-gray text-base font-light tracking-wide`}
            >
              <span className="text-sm font-semibold dark:text-crystal-bell">
                +{reward?.reward}
              </span>
              <CurrencyDollarIcon className="w-5 h-5 text-violet-blue" />
            </div>
          </div>
        </div>

        <div className="flex flex-col h-[100%] gap-4 w-full">
          <div className="flex flex-row">
            <div className="w-4/6">
              <div
                className={`flex justify-start items-center font-bold uppercase tracking-wide text-base text-raisin-black dark:text-crystal-bell leading-none mt-1`}
              >
                {reward?.name}
              </div>
            </div>
            <div className="w-2/6">
              <div
                className={`flex justify-end text-cool-gray text-base font-light tracking-wide`}
              >
                <span
                  className={`flex justify-start items-center uppercase tracking-wide text-xs leading-none ml-2 px-2 py-1 rounded-lg ${
                    isCompleted
                      ? 'bg-nue-blue text-white'
                      : 'bg-violet-blue text-white dark:bg-ship-grey dark:text-tropical-indigo'
                  }`}
                >
                  {isCompleted ? 'DONE' : 'TO-DO'}
                </span>
              </div>
            </div>
          </div>
          <p className="mt-1 pr-1 text-base line-clamp dark:text-crystal-bell">
            {reward?.description}
          </p>
          <div className="flex flex-row">
            <div className="w-10/12 lg:w-11/12">
              {reward?.progress / reward?.total_progress >= 1 ? (
                <></>
              ) : (
                <progress
                  className="progress progress-warning dark:bg-crystal-bell"
                  value={(reward?.progress / reward?.total_progress) * 100}
                  max="100"
                />
              )}
            </div>
            <div className="w-2/12 lg:w-1/12 text-sm text-cool-gray dark:text-crystal-bell text-right">
              {reward.total_progress < reward.progress
                ? reward.total_progress
                : reward.progress}
              {' of '}
              {reward.total_progress}
            </div>
          </div>
        </div>
      </div>
    );
  })
);

const SkeletonCard = () => {
  const { theme: themeValue } = useThemeStore();

  return (
    <div
      className={`w-md flex w-full items-center justify-between flex-row ${
        themeValue == 'dark' ? 'bg-umbra' : 'bg-white'
      } rounded-lg overflow-hidden`}
    >
      <div className="bg-ghost-white dark:bg-night-black h-32 w-32 animate-pulse"></div>
      <div className="px-4 py-2 w-full">
        <div className="bg-ghost-white dark:bg-night-black h-4 w-1/2 mb-2 animate-pulse"></div>
        <div className="bg-ghost-white dark:bg-night-black h-4 w-1/3 animate-pulse"></div>
      </div>
    </div>
  );
};

const RewardsRoom = inject('store')(
  observer(({ store }) => {
    const [loadingBar, setLoadingBar] = React.useState(false);
    const [rewards, setRewards] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [hasMore, setHasMore] = React.useState(true);

    const getRewards = async (page) => {
      setLoadingBar(true);
      try {
        const response = await store.api.get('/reward', {
          params: {
            page: page,
          },
        });
        if (response.data.success) {
          setRewards([...rewards, ...(response.data.rewardRules || [])]);
          setHasMore(response.data.pagination.next);
        }
        // eslint-disable-next-line no-unreachable
      } catch (error) {
        toast.error('Something went wrong when get rewards!');
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setLoadingBar(false);
      }
    };

    useEffect(() => {
      getRewards(page);
    }, [page]);

    const userId = store.profile._id;

    const completedRewards = useMemo(
      () => rewards?.filter((reward) => reward?.users?.includes(userId)).length,
      [rewards, userId]
    );

    const pendingRewards = useMemo(
      () =>
        rewards?.filter((reward) => !reward?.users?.includes(userId)).length ||
        0,
      [rewards, userId]
    );

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const { theme: themeValue } = useThemeStore();

    return (
      <>
        <Helmet>
          <title>{`Rewards - Straico`}</title>
        </Helmet>
        <Subheader title="Rewards" loading={loadingBar} icon="TrophyIcon" />
        <MainBody className="overflow-auto h-full">
          <div className="flex w-full flex-col gap-4 px-4 md:px-28 h-full pt-5 font-figtree">
            <div className="flex flex-col justify-evenly items-center pb-10">
              <div className="md:mx-0  mx-4 flex flex-col justify-center w-full">
                {/* Progress */}
                <div
                  className={`w-full transition duration-500 transform flex flex-col md:flex-row justify-between items-center w-sm  rounded-md  ${
                    themeValue == 'dark' ? 'bg-umbra' : 'bg-white'
                  }`}
                >
                  <div className="w-6/12 py-4 text-center relative text-battleship-gray dark:text-crystal-bell">
                    DONE ({completedRewards})
                    <div
                      className={`bg-nue-blue h-1 bottom-2 absolute w-[${(
                        (completedRewards * 100) /
                        (rewards?.length ?? 0)
                      ).toFixed(2)}%]`}
                    ></div>
                  </div>
                  <div className="w-6/12 py-4 text-center relative text-battleship-gray dark:text-crystal-bell">
                    TO-DO ({pendingRewards})
                    <div
                      className={`bg-violet-blue h-1 bottom-2 absolute w-[${(
                        (pendingRewards * 100) /
                        (rewards?.length ?? 0)
                      ).toFixed(2)}%]`}
                    ></div>
                  </div>
                </div>
                <InfiniteScroll
                  dataLength={rewards.length}
                  next={() => {
                    if (!loadingBar) setPage(page + 1);
                  }}
                  hasMore={hasMore}
                  loader={
                    <>
                      <div className="flex gap-3 flex-col mt-3 w-full ">
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                          <SkeletonCard key={index} />
                        ))}
                      </div>
                    </>
                  }
                  scrollableTarget="content-wrapper"
                  endMessage={
                    <p className="text-center my-6 dark:text-crystal-bell">
                      Congratulations, you've seen everything!
                    </p>
                  }
                >
                  <div className="flex gap-3 flex-col mt-3">
                    {rewards.map((reward, index) => (
                      <RewardCard reward={reward} key={index} />
                    ))}
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </MainBody>
      </>
    );
  })
);

export default RewardsRoom;
