export const MESSAGE_TITLE = {
  emptyFields: 'Missing Fields Alert',
  emptyAttachments: 'Missing Attachment Alert',
  both: 'Missing Fields and Attachment Alert',
};

export const MESSAGE_BODY = {
  emptyFields:
    'This template performs best with fields. Proceed without or write now?',
  emptyAttachments:
    'This template performs best with attachments. Proceed without or attach now?',
  both: 'This template performs best with attachments and fields. Proceed without or complete?',
};
