import { useEffect, useRef, useState } from 'react';
// react-router-dom
import { useParams } from 'react-router-dom';
// Mobx
import { inject, observer } from 'mobx-react';
// Store
import usePublicLayoutStore from 'Layouts/PublicLayout/State';
// MUI
import { Box, useMediaQuery } from '@mui/material';
// Icons
import { PaperClipIcon } from '@heroicons/react/24/outline';
// SEO
import { Helmet } from 'react-helmet';
// Components
import TextAreaHightlight from 'Components/Chat/TextAreaHightlight/TextAreaHightlight';
import Loader from 'Components/Loader';
import ScrollableToolsSection from 'Components/ScrollableToolsSection';
import {
  highlightWordsTextAreaV2,
  highlightWordsTextAreaV2Dark,
} from 'Constants/chat';
import ModalRegister from 'Layouts/PublicLayout/Components/ModalRegister';
import TemplateModalShare from 'Layouts/PublicLayout/Components/TemplateModalShare';
import usePublicPromptStore from 'Layouts/PublicLayout/State/prompts';
import useThemeStore from 'Theme/store';

const ShareToolPage = inject('store')(
  observer(({ store }) => {
    const { id } = useParams();
    // State
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    // textarea
    const textareaRef = useRef(null);
    const [inputValue, setInputValue] = useState('');

    const [straicoChoiceTools, setStraicoChoiceTools] = useState([]);

    //  Global state management
    const { setTools, setDefaultValues, setSelectedTool, setSelectedPrompt } =
      usePublicPromptStore();

    // Get share chat
    const onGetShareTool = async (id) => {
      setLoading(true);
      try {
        const res = await store.api.get(`/shareTools/${id}`);
        const data = await res.data;
        setData(data);
        setTools([data?.tool_id]);
        setSelectedTool(data?.tool_id);
        setDefaultValues(data?.values);
        setSelectedPrompt(data?.prompts[0]);
      } catch (err) {
        setError(true);
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      (async () => {
        try {
          const res = await store.api.get(`/v2/templates/straico-choice`);
          setStraicoChoiceTools(shuffleArray(res.data.data));
        } catch (error) {
          console.error(error);
        }
      })();
    }, []);

    useEffect(() => {
      if (id) {
        onGetShareTool(id);
      }
    }, [id]);

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    // Global state management
    const { registerModal, closeRegisterModal, openRegisterModal } =
      usePublicLayoutStore();

    // media querys
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    // Continue this conversation
    const setRedirectUrl = () => {
      sessionStorage.setItem(
        'toolClone',
        JSON.stringify({
          toolClone: data?.tool_id?._id,
          toolIsPublic: data?.tool_id?.public,
        })
      );
    };

    const { theme } = useThemeStore();

    const handleSubmitModal = (updatedVariables, prompt) => {
      let newContent = prompt.value;
      updatedVariables.forEach(([key, value]) => {
        if (typeof value !== 'object' && value !== '' && value) {
          newContent = newContent?.replaceAll(
            '${' + key + '}',
            `"'${value.trim()}'"`
          );
        }
      });
      setInputValue(newContent);
    };

    const continueConversation = () => {
      openRegisterModal();
    };

    // Error handling screen
    if (error)
      return (
        <div className="h-full w-full bg-seasalt dark:bg-lead text-raisin-black dark:text-crystal-bell flex justify-center items-center font-figtree">
          <div className="text-center">
            <h1 className="text-3xl font-bold">
              Oops, Trouble Accessing Shared Content!
            </h1>
            <p className="text-xl">
              This shared tool seems to be currently unavailable. For the best
              experience, we recommend reaching out to the original creator to
              request a new share link.
            </p>
          </div>
        </div>
      );

    return (
      <>
        {/* SEO */}
        <Helmet>
          <title>{`Shared template - ${data?.tool_id?.title}`}</title>
        </Helmet>
        {/* Screen content */}
        {loading ? (
          <div className="flex-1 w-full flex justify-center items-center bg-seasalt dark:bg-lead">
            <Loader />
          </div>
        ) : (
          <div className="relative flex-1 w-full bg-seasalt dark:bg-lead text-raisin-black dark:text-crystal-bell flex flex-col font-figtree items-center">
            <div className="flex-1 flex flex-col lg:max-w-4xl overflow-auto font-figtree">
              <div className="flex-1 relative w-full h-full flex flex-col pb-[4rem]">
                <div className="bg-seasalt dark:bg-lead flex-1 flex flex-col overflow-auto">
                  <div
                    className={`flex  ${
                      theme == 'dark' ? 'bg-lead' : 'bg-seasalt'
                    } overflow-auto flex-col h-full font-figtree px-9 justify-around`}
                  >
                    <section className="mb-6">
                      <article>
                        <h2
                          className={`text-[28px] font-bold leading-[28px] ${
                            theme == 'dark' ? 'text-white' : 'text-black'
                          }`}
                        >
                          Hey!
                        </h2>
                        <p
                          className={`text-xl ${
                            theme == 'dark' ? 'text-white' : 'text-black'
                          }`}
                        >
                          What are you willing to explore with multimodal AI
                          today?
                        </p>
                      </article>
                    </section>
                    <ScrollableToolsSection
                      title="Generate images with top IA models"
                      tools={straicoChoiceTools}
                      isSmallMobile={isMobile ? true : false}
                      dummy
                    />
                    <div className="space"></div>
                  </div>
                </div>

                {/* Input field */}
                <div
                  className={`relative m-auto w-full p-3 pb-2 flex items-start ${
                    theme == 'dark' ? 'bg-lead' : 'bg-seasalt'
                  }`}
                >
                  <div className="max-md:flex hidden h-12 justify-center flex-col items-center">
                    <PaperClipIcon className="max-md:block hidden w-8 h-8 text-agate-violet cursor-pointer select-none scale-x-[-1] rotate-45" />
                  </div>

                  <label
                    htmlFor="user-message"
                    className="-mt-1 px-2 relative transition block w-full"
                  >
                    <TextAreaHightlight
                      classNameContainer="outline-none focus:outline-none text-md rounded w-full font-regular border-nue-blue font-figtree shadow-none text-base"
                      textareaRef={textareaRef}
                      autoFocus
                      minRows={1}
                      maxRows={7}
                      value={inputValue}
                      highlightWords={
                        theme == 'dark'
                          ? highlightWordsTextAreaV2Dark
                          : highlightWordsTextAreaV2
                      }
                    />
                  </label>
                </div>
              </div>

              {/* Continue button */}
              {inputValue.length > 0 && (
                <div className="relative w-full">
                  <Box
                    sx={{
                      animation: 'jump 3s infinite ease-in-out',
                      '@keyframes jump': {
                        '0%, 20%, 50%, 80%, 100%': {
                          transform: 'translateY(0)',
                        },
                        '40%': {
                          transform: 'translateY(-15px)',
                        },
                        '60%': {
                          transform: 'translateY(-15px)',
                        },
                      },
                    }}
                    className={`absolute top-[-50px] left-0 right-0 mx-auto md:border-transparent md:dark:border-transparent md:bg-vert-light-gradient dark:md:bg-vert-dark-gradient`}
                  >
                    <div className="relative flex h-full w-full flex-1 items-center justify-center gap-2">
                      <div
                        onClick={continueConversation}
                        className="flex items-center justify-center px-4 py-2 text-lg font-bold text-white bg-nue-blue rounded-xl hover:bg-violet-blue cursor-pointer select-none"
                      >
                        <div className="flex w-full gap-2 items-center justify-center">
                          Sign up to use this template
                        </div>
                      </div>
                    </div>
                  </Box>
                </div>
              )}
            </div>
            <TemplateModalShare handleSubmitModal={handleSubmitModal} />
            <ModalRegister
              toolText
              isLoggedIn={store.isLoggedIn}
              open={registerModal}
              onClose={closeRegisterModal}
              setRedirectUrl={setRedirectUrl}
              url={`/chat`}
            />
          </div>
        )}
      </>
    );
  })
);

export default ShareToolPage;
