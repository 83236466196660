import { ClipboardIcon } from '@heroicons/react/24/outline';
import { Button, InputAdornment, TextField } from '@mui/material';
import useClipboardComponent from 'Hooks/useClipboardComponent';
import toast from 'react-hot-toast';
// Theme
import useThemeStore from 'Theme/store';

function ShareInput({ shareLink, title, description }) {
  const { theme } = useThemeStore();
  const { copy } = useClipboardComponent();
  //
  const handleCopy = () => {
    try {
      copy(shareLink);
      toast.success('Link copied to clipboard');
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  return (
    <>
      {/* clipboard input component */}
      <h1 className="text-raisin-black dark:text-crystal-bell font-figtree font-bold text-lg mb-5">
        {title ? title : 'Share this link:'}
      </h1>
      {description && (
        <div className="text-base text-agate-violet dark:text-crystal-bell mb-5">
          {description}
        </div>
      )}

      <TextField
        value={shareLink}
        fullWidth
        sx={{
          fontFamily: 'Figtree',
          flex: '1 1 0%',
          '& .MuiInputBase-root': {
            borderRadius: '12px',
            borderColor: theme == 'dark' ? '#787878' : '#9091A4',
            paddingRight: '4px',
          },
          '& .MuiInputBase-input': {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            padding: '8px 14px',
            textOverflow: 'ellipsis',
            color: theme == 'dark' ? '#EFEFEF' : '#9091A4',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                sx={{
                  color: theme === 'light' ? '#5256A6' : '#EFEFEF',
                  fontWeight: 600,
                  fontFamily: 'Figtree',
                  borderRadius: '12px',
                  textTransform: 'none',
                  padding: '4px 16px',
                  backgroundColor: theme === 'light' ? '#D4D5E6' : '#7F82C3',
                }}
                onClick={handleCopy}
                startIcon={<ClipboardIcon className="w-4 h-4" />}
              >
                Copy link
              </Button>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default ShareInput;
