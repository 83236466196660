import moment from 'moment';

export const formatTime = (seconds) => {
  const duration = moment.duration(seconds, 'seconds');
  return moment.utc(duration.asMilliseconds()).format('mm:ss');
};

export const formatedDate = (date, format = null) => {
  const mDate = moment.utc(date).local();
  const today = mDate.isSame(moment(), 'day');
  const defaultFormat = today
    ? 'hh:mm a'
    : format
    ? format
    : 'DD/MM/YY, hh:mm a';
  return mDate.format(defaultFormat);
};

export const getLabelMinutes = (seconds) => {
  const duration = moment.duration(seconds, 'seconds');
  const minutes = duration.minutes();
  const secondsStr = duration.seconds().toString().padStart(2, '0');

  return `${minutes}:${secondsStr}`;
};
