function SvgComponent({ fillColor = '#1F6974', ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width="24" height="24" rx="8" fill={fillColor} />
      <path
        d="M16.8985 3.699L12.0005 8.451H16.8985V3.699ZM16.8985 3.699V5M11.9892 3V21M16.8985 13.202L12.0005 8.45M16.8985 13.202V20.0145L12.0005 15.2625M16.8985 13.202L12 8.45M16.8985 13.202L16.8979 15.235H19V8.45H12M12.0005 8.45V15.2625M12.0005 8.45L7.10206 13.202M12.0005 15.2625L7.10206 20.0145V13.202M7.10206 13.202L7.10155 15.235H5V8.45H12M7.10206 13.202L12 8.45M12 8.451L7.10155 3.699V8.451H12Z"
        stroke="white"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default SvgComponent;
