function SvgComponent({ fillColor = '#75AA74', ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width="24" height="24" rx="8" fill={fillColor} />
      <path
        d="M19.1979 10.1455V7.75615C19.1979 7.33014 19.0293 6.92158 18.7293 6.62035C18.4293 6.31912 18.0224 6.14988 17.5981 6.14988H12.7988V5.09618C13.0427 4.87612 13.1987 4.55968 13.1987 4.2047C13.1987 3.88519 13.0723 3.57877 12.8473 3.35285C12.6223 3.12692 12.3171 3 11.9989 3C11.6807 3 11.3755 3.12692 11.1505 3.35285C10.9255 3.57877 10.7991 3.88519 10.7991 4.2047C10.7991 4.55968 10.955 4.87612 11.199 5.09618V6.14988H6.39967C5.97538 6.14988 5.56847 6.31912 5.26845 6.62035C4.96844 6.92158 4.79989 7.33014 4.79989 7.75615V10.1639L4.7423 10.168C4.54063 10.1824 4.35192 10.273 4.21417 10.4216C4.07642 10.5702 3.99989 10.7657 4 10.9687V12.5749C4 12.7879 4.08427 12.9922 4.23428 13.1428C4.38429 13.2935 4.58774 13.3781 4.79989 13.3781V17.3937C4.79989 17.8197 4.96844 18.2283 5.26845 18.5295C5.56847 18.8308 5.97538 19 6.39967 19H17.5981C18.0224 19 18.4293 18.8308 18.7293 18.5295C19.0293 18.2283 19.1979 17.8197 19.1979 17.3937V13.3781C19.41 13.3781 19.6135 13.2935 19.7635 13.1428C19.9135 12.9922 19.9978 12.7879 19.9978 12.5749V11.0185C20.007 10.8938 19.9873 10.7687 19.9402 10.653C19.7786 10.2611 19.4322 10.1672 19.1979 10.1455ZM7.99944 10.9687C7.99944 10.082 8.53697 9.36241 9.19928 9.36241C9.86158 9.36241 10.3991 10.082 10.3991 10.9687C10.3991 11.8553 9.86158 12.5749 9.19928 12.5749C8.53697 12.5749 7.99944 11.8553 7.99944 10.9687ZM15.1968 15.7875C14.3962 15.7851 8.79933 15.7875 8.79933 15.7875V14.1812C8.79933 14.1812 14.3994 14.1796 15.2 14.1812L15.1968 15.7875ZM14.7985 12.5749C14.1362 12.5749 13.5987 11.8553 13.5987 10.9687C13.5987 10.082 14.1362 9.36241 14.7985 9.36241C15.4608 9.36241 15.9983 10.082 15.9983 10.9687C15.9983 11.8553 15.4608 12.5749 14.7985 12.5749Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgComponent;
