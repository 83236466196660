import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
  Stack,
  FormHelperText,
} from '@mui/material';
// RHF
import { useController } from 'react-hook-form';

const CheckboxControlled = ({
  name,
  control,
  label,
  formGroupProps,
  formControlLabelProps,
  checkboxProps,
  border = false,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  const handleChange = (e) => {
    field.onChange(e.target.checked);
  };

  return (
    <FormControl required error={error} component="fieldset" variant="standard">
      <FormGroup {...formGroupProps} sx={{ ...(border && { width: '100%' }) }}>
        <Stack
          sx={{
            ...(border && {
              width: '100%',
              border: '1px solid #CBCCD1',
              padding: '10px 20px',
              borderRadius: '12px',
              textAlign: 'left',
            }),
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                name={name}
                checked={field.value}
                onChange={handleChange}
                {...checkboxProps}
              />
            }
            label={label}
            {...formControlLabelProps}
          />
        </Stack>
      </FormGroup>
      {error && error.message && (
        <FormHelperText sx={{ marginLeft: 0 }} id={`${name}-helper-text`}>
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CheckboxControlled;
