import { create } from 'zustand';

const useTreeDNDTemplatesStore = create((set) => ({
  page: 1,
  setPage: (page) => set({ page }),
  treeData: [],
  setTreeData: (treeData) => set({ treeData }),
  totalPage: 0,
  setTotalPage: (totalPage) => set({ totalPage }),
  loading: false,
  setLoading: (loading) => set({ loading }),
  showSearch: false,
  setShowSearch: (showSearch) => set({ showSearch }),
  search: '',
  setSearch: (search) => set({ search }),
  deleteFolderModal: false,
  setDeleteFolderModal: (deleteFolderModal) => set({ deleteFolderModal }),
  selectedFolder: null,
  setSelectedFolder: (selectedFolder) => set({ selectedFolder }),
}));

export default useTreeDNDTemplatesStore;
