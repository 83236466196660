// Mobx
import { inject, observer } from 'mobx-react';
// React Router Dom
import { Navigate } from 'react-router-dom';

const RootLayout = ({ store }) => {
  if (store.redirect) {
    <Navigate to={store.redirect} />;
  } else if (!store.isLoggedIn) {
    return <Navigate to="/login" />;
  } else {
    return store.profile.status ? (
      <Navigate to="/chat" />
    ) : (
      <Navigate to="/my-profile/pricing" />
    );
  }
};
export default inject('store')(observer(RootLayout));
