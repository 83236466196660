/* eslint-disable no-console */
import {
  ArrowUturnLeftIcon,
  BoltIcon,
  SparklesIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const PlanActionsDialog = inject('store')(
  observer(({ store, type, onInteraction, onClose, plan }) => {
    const navigate = useNavigate();

    return (
      <>
        <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 transition-opacity" onClick={onClose}>
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div className="bg-white rounded overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full max-h-full p-5">
            {type == 'free_trial' && (
              <>
                <SparklesIcon className="h-6 flex justify-center w-full mb-3 text-eggplant-700" />
                <div className="text-center not-italic text-eggplant-700 font-lexend text-sm font-bold uppercase mb-4">
                  {process.env.REACT_APP_TRIAL_PERIOD_DAYS}-day trial activated!
                </div>
                <div className="text-center not-italic text-graphite-800 font-barlow text-sm  font-medium mb-7">
                  <p className="mb-3">
                    Congratulations! You've successfully activated your{' '}
                    {process.env.REACT_APP_TRIAL_PERIOD_DAYS}-day trial of{' '}
                    <span className="text-graphite-800 font-bold capitalize">
                      Straico’s{' '}
                      {store.profile.plan.replace(/_monthly|_yearly/i, '')}{' '}
                      Plan.
                    </span>
                  </p>
                  <p>
                    Until {moment(store.profile.trial_end).format('DD/MM/YYYY')}{' '}
                    you'll have unrestricted access to our diverse range of
                    Models, and you can also try the media uploader to query on
                    any content you need.
                  </p>
                </div>
                <div className="flex justify-center w-full mb-2">
                  <button
                    type="button"
                    className="bg-[#E57A44] shadow-md rounded py-1 px-3 text-snow-100 font-bold text-center text-sm font-lexend uppercase flex"
                    onClick={() => navigate('/chat')}
                  >
                    <StarIcon className="w-5 h-5  mr-2" />
                    Explore your benefits now!
                  </button>
                </div>
              </>
            )}

            {type == 'downgrade' && (
              <div className="p-5">
                <ArrowUturnLeftIcon className="h-6 flex justify-center w-full mb-3 text-eggplant-700" />
                <div className="text-center not-italic text-eggplant-700 font-lexend text-sm font-bold uppercase mb-4">
                  Back to Basics!
                </div>
                <div className="text-center not-italic text-graphite-800 font-barlow text-sm  font-medium mb-7">
                  <p className="mb-3">
                    We see that you've switched back to our free plan. Not to
                    worry,{' '}
                    <span className="text-graphite-800 font-bold">
                      you still have access to a considerable number of exciting
                      features,
                    </span>{' '}
                    and to our community.
                  </p>
                  <p>Remember, you can always upgrade again, at any time.</p>
                </div>
                <div className="flex justify-center w-full mb-2">
                  <button
                    type="button"
                    className="bg-[#E57A44] shadow-md rounded py-1 px-3 text-snow-100 font-bold text-center text-sm font-lexend uppercase flex"
                    onClick={onInteraction}
                  >
                    <img
                      className="w-5 h-5  mr-2"
                      src="/svg/logo-symbol-snow-100.svg"
                    />
                    downgrade to free
                  </button>
                </div>
              </div>
            )}

            {type == 'upgrade' && (
              <div className="p-5">
                <BoltIcon className="h-6 flex justify-center w-full mb-3 text-eggplant-700" />
                <div className="text-center not-italic text-eggplant-700 font-lexend text-sm font-bold uppercase mb-4">
                  Eager for More?
                </div>
                <div className="text-center not-italic text-graphite-800 font-barlow text-sm  font-medium mb-7">
                  <p>
                    We love your enthusiasm! Keep in mind,{' '}
                    <span className="text-graphite-800 font-bold">
                      upgrading to the{' '}
                      <span className="capitalize">
                        {plan.abbreviation.replace(/_monthly|_yearly/i, '')}
                      </span>{' '}
                      Plan now will conclude your{' '}
                      {store.profile.trial_end &&
                      moment().isBefore(moment(store.profile.trial_end))
                        ? 'trial'
                        : `${store.profile.plan.replace(
                            /_monthly|_yearly/i,
                            ''
                          )} plan`}
                      ,
                    </span>{' '}
                    and an immediate charge will be applied for your new
                    subscription.
                  </p>
                </div>
                <div className="flex justify-center w-full mb-3">
                  <button
                    type="button"
                    className="bg-[#E57A44] shadow-md rounded py-1 px-3 text-snow-100 font-bold text-center text-sm font-lexend uppercase flex"
                    onClick={onInteraction}
                  >
                    Proceed to Change!
                  </button>
                </div>
                <div className="text-center not-italic text-graphite-800 font-barlow text-sm  font-medium mb-2">
                  <p>
                    Or click here to{' '}
                    <span
                      onClick={onClose}
                      className="text-crayola-400 font-semibold underline cursor-pointer"
                    >
                      go back
                    </span>
                    .
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  })
);

export default PlanActionsDialog;
