/* eslint-disable no-console */
/* eslint-disable react/display-name */
import { memo, useState, useRef, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

export const MemoizedOriginalPrompt = memo(({ originalPrompt, theme }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const promptRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (promptRef.current) {
      setIsOverflowing(
        promptRef.current.scrollWidth > promptRef.current.clientWidth
      );
    }
  }, [originalPrompt]);

  if (!originalPrompt) return null;

  return (
    <div
      className={`font-medium p-2 rounded-md mb-2 mx-auto w-full text-left relative ${
        theme === 'dark'
          ? 'bg-gray-400 bg-opacity-80 text-gray-100 text-opacity-70'
          : 'bg-gray-200 bg-opacity-80 text-gray-600 text-opacity-70'
      }`}
      style={{ width: '100%', paddingRight: '4%' }}
    >
      <span
        ref={promptRef}
        className={`block overflow-hidden ${
          isExpanded ? 'whitespace-normal' : 'whitespace-nowrap'
        }`}
        style={{ textOverflow: isExpanded ? 'clip' : 'ellipsis' }}
      >
        Original Prompt: {originalPrompt}
      </span>

      {isOverflowing && (
        <ChevronDownIcon
          onClick={toggleExpand}
          className={`w-5 h-5 absolute cursor-pointer transition-transform ${
            isExpanded ? 'rotate-180' : ''
          } ${
            isExpanded
              ? 'top-auto bottom-2 right-1/2 transform translate-x-1/2'
              : 'top-1/2 right-2 transform -translate-y-1/2'
          }`}
        />
      )}
    </div>
  );
});
