/* eslint-disable no-console */
import { Component } from 'react';
import { PencilIcon, ClockIcon } from '@heroicons/react/24/outline';

import { Grid, Col } from '../../Components/Body';
import EntryText from '../../Components/EntryText';
import Button from '../../Components/Button';
import Output from '../../Components/Output';
import Countdown from 'react-countdown';

import { observable, makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';

import StraicoSymbolIcon from '../../Theme/icons/straicoSymbol';

@inject('store')
@observer
class Referral extends Component {
  countdown;
  @observable output = '';
  @observable outputs = [];
  @observable outputError = '';

  @observable option = 'Start Using';
  @observable options = [];
  @observable currentOption = '';

  @observable loading = false;
  @observable date = Date.now() + 1000;

  // Basic Input
  @observable prompt = '';
  @observable promptError = '';
  @observable promptNumber = 0;

  // Options of Inputs
  @observable promptOptions = [];

  // Currently Selected Input Option
  @observable currentPromptOption = '';

  // Multiple Input Option
  @observable prompts = [];

  @observable feedbacks = [];

  @observable tool = {};

  constructor(props) {
    super(props);
    makeObservable(this);
    this.init();
  }

  init = async () => {
    this.refreshFeedback();
  };

  refreshFeedback = async () => {
    let response = await this.props.store.api.post('/user/feedback/view');
    this.feedbacks = [...response.data];
  };

  handleFeedback = async () => {
    // console.log(this.props.store);
    try {
      await this.props.store.api.post('/user/feedback', {
        feedback: this.props.store.feedback,
        api: this.props.store.apiUrl,
        category: this.props.store.category,
        subcategory: this.props.store.subcategory,
        prompt: this.props.store.prompt,
      });
      this.refreshFeedback();
      this.output = 'Thank you for your feedback!';
      this.props.store.feedback = ``;
    } catch (err) {
      console.log(err);
      this.output =
        'There seems to have been an error, please try submitting again';
    }
  };

  onClick = async () => {
    this.loading = true;
    await this.handleFeedback();
    this.date = Date.now() + 10000;
    this.props.store.feedback = '';
    this.countdown.start();
    this.loading = false;
  };
  onChange = (e) => {
    this.props.store.feedback = e.target.value;
  };

  render() {
    return (
      <>
        <Grid>
          <Col span="6">
            <EntryText
              title="Feedback"
              desc="Provide some feedback about your experience"
              prompt={this.props.store.feedback}
              onChange={this.onChange}
            />
            <Countdown
              ref={(countdown) => (this.countdown = countdown)}
              date={this.date}
              renderer={(props) => (
                <Button
                  title={
                    props.total
                      ? `Try again after ${props.total / 1000}s`
                      : 'Submit Feedback'
                  }
                  disabled={props.total}
                  Icon={props.total ? ClockIcon : PencilIcon}
                  onClick={this.onClick}
                />
              )}
            />
          </Col>
          <Col span="6">
            <Output
              title={`Straico`}
              desc={`Feedback Response`}
              Icon={StraicoSymbolIcon}
              fromColor={`violet-blue`}
              toColor={`nue-blue`}
              loading={this.loading}
              output={this.output}
            />
            {this.feedbacks &&
              this.feedbacks.map((feedback) => (
                <Output
                  key={feedback._id}
                  title={`Feedback Received`}
                  desc={`${feedback.created}`}
                  Icon={PencilIcon}
                  fromColor={feedback.response ? `green-400` : `cool-gray`}
                  toColor={feedback.response ? `green-600` : `battleship-gray`}
                  output={feedback.feedback}
                  outputs={feedback.response ? [feedback.response] : null}
                />
              ))}
          </Col>
        </Grid>
      </>
    );
  }
}

export default Referral;
