import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  prompt: yup.string().required('Prompt is required'),
  // variables: yup.array().min(1, 'At least one variable is required'),
  description: yup.string().required('Description is required'),
  multi_default_models: yup
    .array()
    .of(yup.string())
    .max(4, 'Select up to 4 models'),
});
