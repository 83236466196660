const ImageDialog = ({ onClose, children }) => {
  return (
    <>
      <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50">
        <div className="fixed inset-0 transition-opacity" onClick={onClose}>
          <div className="absolute inset-0 bg-raisin-black opacity-55"></div>
        </div>

        <div className="relative bg-seasalt rounded-[14px] shadow-[0px_4px_24.5px_4px_rgba(0,0,0,0.39)] transform transition-all max-h-[calc(100vh_-_2rem)]">
          {children}
        </div>
      </div>
    </>
  );
};

export default ImageDialog;
