import { useEffect, useState } from 'react';
import Loader from './Loader';
import Typist from 'react-typist';
import { CheckIcon } from '@heroicons/react/24/solid';
import {
  DocumentDuplicateIcon,
  ExclamationCircleIcon,
  PlusIcon,
  ArrowDownOnSquareStackIcon,
  FlagIcon,
} from '@heroicons/react/24/outline';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';

import CodeEditor from '@uiw/react-textarea-code-editor';
import Button from './Button';
import Progress from './Progress';
import { useLocation } from 'react-router-dom';

export const Output = inject('store')(
  observer(
    ({
      store,
      title,
      desc,
      Icon,
      output,
      code,
      language,
      outputs,
      image,
      video,
      loading,
      children,
      fromColor,
      toColor,
      outputsColor,
      OutputsIcon,
      typingDone,
      tags,
      addTags,
      removeTag,
      api,
      category,
      subcategory,
      prompt,
      saved,
      noAnimate = false,
      toSave = false,
      saveCallback = null,
      process = null,
    }) => {
      const location = useLocation();
      const [percent, setPercent] = useState(0);

      useEffect(() => {
        if (process) {
          store.listenEvent('processing', (event) => {
            if (process != null && event.process == process) {
              setPercent(event.percent);
            }
          });

          store.listenEvent('process_complete', (event) => {
            let promptItem = new URLSearchParams(location.search).get('prompt');
            if (promptItem && promptItem == event.history._id) {
              setPercent(0);
            }
          });

          store.listenEvent('process_failed', (event) => {
            let promptItem = new URLSearchParams(location.search).get('prompt');
            if (promptItem && promptItem == event.history._id) {
              setPercent(0);
            }
          });
        }
      }, [process]);

      return (
        <div className="relative mb-12 font-figtree">
          {/* <div className={`absolute inset-0 bg-${fromColor ? fromColor : "green-400"} shadow-lg transform md:skew-y-0 -rotate-2 rounded-md -mt-1 md:mt-0`}></div> */}
          <div className=" align-bottom bg-seasalt rounded-md text-left  transform transition-all sm:align-middle shadow-md hover:shadow-2xl focus:shadow-2xl border border-platinum">
            <div className=" px-6 py-6">
              <div className="sm:flex sm:items-start">
                {loading ? (
                  <>
                    <Loader active={loading} className="w-10 h-10 pr-10" />
                  </>
                ) : (
                  <>
                    <div
                      className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-${
                        fromColor ? fromColor : 'green-400'
                      }`}
                    >
                      {Icon ? (
                        <Icon
                          className={`h-6 w-6 text-white fill-white`}
                          aria-hidden="true"
                        />
                      ) : null}
                    </div>
                  </>
                )}
                <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="text-lg leading-6 font-medium text-raisin-black">
                    {title}
                  </div>
                  <p className="text-sm text-cool-gray">{desc}</p>
                </div>
              </div>
              {saved && tags && !process ? (
                <div className="flex items-center justify-between mt-4">
                  <div>
                    <ul className="flex flex-wrap">
                      {tags.length ? (
                        tags.map((item, index) => (
                          <li
                            className="bg-platinum rounded-lg px-3 py-1 mr-2 mt-2"
                            key={index}
                          >
                            {item}{' '}
                            <span
                              className="ml-2 cursor-pointer"
                              onClick={() => removeTag(item)}
                            >
                              x
                            </span>
                          </li>
                        ))
                      ) : (
                        <div className="text-platinum font-semibold">
                          No tags
                        </div>
                      )}
                    </ul>
                  </div>
                  <div>
                    <Shortcut
                      className="p-1 ml-2 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative group flex flex-col items-center group text-platinum"
                      onClick={addTags}
                    >
                      <PlusIcon className="w-5 h-5" />
                    </Shortcut>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {code ? null : output ? (
                <div className="whitespace-pre-wrap min-w-full text-cool-gray h-auto text-lg divide-y px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {output ? (
                    <>
                      {noAnimate ? (
                        <div>{output}</div>
                      ) : (
                        <Typist
                          stdTypingDelay={0}
                          avgTypingDelay={7}
                          cursor={{
                            show: true,
                            blink: true,
                            element: '|',
                            hideWhenDone: true,
                            hideWhenDoneDelay: 250,
                          }}
                          onTypingDone={typingDone}
                        >
                          {output}
                        </Typist>
                      )}
                    </>
                  ) : null}
                </div>
              ) : null}
              {output && outputs && outputs.length ? (
                <div className="divide-y divide-dashed divide-platinum">
                  <div></div>
                  <div></div>
                </div>
              ) : null}
              {outputs && outputs.length ? (
                <Outputs
                  outputs={outputs}
                  outputsColor={outputsColor}
                  OutputsIcon={OutputsIcon}
                  typingDone={typingDone}
                  noAnimate={noAnimate}
                />
              ) : null}
              {code && code.length ? (
                <CodeEditor
                  style={{
                    fontFamily: 'JetBrains Mono',
                    fontSize: '1rem',
                  }}
                  padding={30}
                  language={language}
                  value={code}
                />
              ) : null}
              {image ? (
                <div className="whitespace-pre-wrap min-w-full text-raisin-black h-auto text-lg divide-y px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <img src={image} />
                </div>
              ) : null}
              {video ? (
                <div className="whitespace-pre-wrap min-w-full text-raisin-black h-auto text-lg divide-y px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <video controls className="w-full">
                    <source src={video} type="video/mp4" />
                  </video>
                </div>
              ) : null}
              {toSave ? (
                <div className="mb-4">
                  <Button
                    title={'Save output'}
                    Icon={FlagIcon}
                    onClick={saveCallback}
                  />
                </div>
              ) : (
                <></>
              )}
              <QuickTools
                outputs={outputs}
                output={output}
                code={code}
                image={image}
                video={video}
                api={api}
                category={category}
                subcategory={subcategory}
                prompt={prompt}
              />

              {process && (
                <Progress
                  className="mt-4"
                  bgcolor="#84cc16"
                  progress={percent}
                  height={25}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  )
);

export const QuickTools = inject('store')(
  observer(
    ({
      store,
      output,
      outputs,
      code,
      image,
      video,
      api,
      category,
      subcategory,
      prompt,
    }) => {
      const handleDownload = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'image.jpg';
        link.target = '_blanck';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      return (
        <>
          {output || code || (outputs && outputs.length) || image || video ? (
            <div className="flex">
              {image || video ? (
                <Shortcut
                  className="p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative group flex flex-col items-center group text-platinum"
                  onClick={() => store.handleDownload(image || video)}
                >
                  <ArrowDownOnSquareStackIcon className="w-5 h-5" />
                  <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                    <span className="relative z-10 p-3 text-sm leading-none text-raisin-black bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                      Download
                    </span>
                  </Tooltip>
                </Shortcut>
              ) : (
                <Shortcut
                  className="p-1 rounded-lg cursor-pointer hover:bg-green-200 hover:text-green-700 relative group flex flex-col items-center group text-platinum"
                  onClick={() =>
                    store.copyToClipboard(output || code || outputs)
                  }
                >
                  <DocumentDuplicateIcon className="w-5 h-5" />
                  <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                    <span className="relative z-10 p-3 text-sm leading-none text-raisin-black bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                      Copy text to clipboard
                    </span>
                  </Tooltip>
                </Shortcut>
              )}
              <div className="flex-1"></div>
              <Shortcut
                className="p-1 rounded-lg cursor-pointer hover:bg-red-200 hover:text-red-700 relative group flex flex-col items-center group text-platinum"
                onClick={() =>
                  store.reportToFeedback(
                    output || code || outputs,
                    api,
                    category,
                    subcategory,
                    prompt
                  )
                }
              >
                <ExclamationCircleIcon className="w-5 h-5" />
                <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                  <span className="relative z-10 p-3 text-sm leading-none text-raisin-black bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                    Report issue with output
                  </span>
                </Tooltip>
              </Shortcut>
            </div>
          ) : null}
        </>
      );
    }
  )
);

const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;
const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;
function Outputs({
  outputs,
  outputsColor,
  OutputsIcon,
  typingDone,
  noAnimate = false,
}) {
  const propagateTyping = () => {
    typingDone(true);
  };

  return (
    <div className="whitespace-pre-wrap min-w-full py-4 text-raisin-black h-auto text-lg divide-y">
      {noAnimate ? (
        <div>
          {outputs.map((output, index) => (
            <div className="py-2 flex items-start" key={index}>
              <div
                className={`mr-4 flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${
                  outputsColor ? outputsColor : 'green'
                }-200 text-${outputsColor ? outputsColor : 'green'}-600`}
              >
                {OutputsIcon === 'false' ? (
                  `${index + 1}`
                ) : OutputsIcon ? (
                  <OutputsIcon
                    className={`h-4 w-4 text-${
                      outputsColor ? outputsColor : 'green'
                    }-600`}
                    aria-hidden="true"
                  />
                ) : (
                  <CheckIcon
                    className={`h-4 w-4 text-${
                      outputsColor ? outputsColor : 'green'
                    }-600`}
                    aria-hidden="true"
                  />
                )}
              </div>
              {output}
            </div>
          ))}
        </div>
      ) : (
        <Typist
          stdTypingDelay={0}
          avgTypingDelay={7}
          className="divide-y"
          cursor={{
            show: false,
            blink: false,
            element: '|',
            hideWhenDone: true,
            hideWhenDoneDelay: 250,
          }}
          onTypingDone={() => propagateTyping()}
        >
          {outputs.map((output, index) => (
            <div className="py-2 flex items-start" key={index}>
              <div
                className={`mr-4 flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${
                  outputsColor ? outputsColor : 'green'
                }-200 text-${outputsColor ? outputsColor : 'green'}-600`}
              >
                {OutputsIcon === 'false' ? (
                  `${index + 1}`
                ) : OutputsIcon ? (
                  <OutputsIcon
                    className={`h-4 w-4 text-${
                      outputsColor ? outputsColor : 'green'
                    }-600`}
                    aria-hidden="true"
                  />
                ) : (
                  <CheckIcon
                    className={`h-4 w-4 text-${
                      outputsColor ? outputsColor : 'green'
                    }-600`}
                    aria-hidden="true"
                  />
                )}
              </div>
              {output}
            </div>
          ))}
        </Typist>
      )}
    </div>
  );
}

export default Output;
