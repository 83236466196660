export const supportLanguagesMessages = [
  'javascript',
  'typescript',
  'python',
  'java',
  'js',
  'php',
  'css',
  'html',
  'jsx',
  'rust',
  'go',
  'bash',
  'cpp',
  'ruby',
  'swift',
  'php',
  'sql',
  'markdown',
];

export const highlightWordsTextArea = [
  {
    word: /\$\{(\w+)\}/g,
    classnames: 'bg-lavender text-nue-blue rounded-md py-1 px-2',
  },
  {
    word: /"'(.+?)'"/gs,
    classnames: 'bg-lavender text-nue-blue rounded-md py-1 px-2',
  },
];

export const highlightWordsTextAreaV2 = [
  {
    highlight: /\$\{(\w+)\}/g,
    className: 'bg-lavender text-nue-blue rounded-md py-[2px]',
    prefix: ' ',
    suffix: ' ',
  },
  {
    highlight: /""'(.+?)'""/gs,
    className: 'bg-lavender text-nue-blue rounded-md px-[3px] py-[2px]',
    prefix: `   `,
    suffix: `   `,
  },
  {
    highlight: /"'(.+?)'"/gs,
    className: 'bg-lavender text-nue-blue rounded-md px-[2px] py-[2px]',
    prefix: `  `,
    suffix: `  `,
  },
];

export const highlightWordsTextAreaV2Dark = [
  {
    highlight: /\$\{(\w+)\}/g,
    className: 'bg-ship-grey text-powder-blue rounded-md py-[2px]',
    prefix: ' ',
    suffix: ' ',
  },
  {
    highlight: /""'(.+?)'""/gs,
    className: 'bg-ship-grey text-powder-blue rounded-md px-[3px] py-[2px]',
    prefix: `   `,
    suffix: `   `,
  },
  {
    highlight: /"'(.+?)'"/gs,
    className: 'bg-ship-grey text-powder-blue rounded-md px-[2px] py-[2px]',
    prefix: `  `,
    suffix: `  `,
  },
];

export const modelLabels = {
  'gpt-3.5-turbo': 'gpt-3.5',
  'gpt-4': 'gpt-4',
};

export const MAX_TITLE_LENGTH = 85;
