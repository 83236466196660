/* eslint-disable no-constant-condition */
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Logo
import StraicoIcon from 'Theme/icons/straico';
//RHF
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
//Yup
import * as yup from 'yup';
// Components
import Button from '../../Components/Button';
import CheckboxControlled from '../../Components/Forms/CheckboxControlled/CheckboxControlled';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import * as React from 'react';

import useThemeStore from 'Theme/store';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styles = {
  menuItemText: {
    whiteSpace: 'normal', // Permite que el texto ocupe más de una línea
    wordBreak: 'break-word', // Rompe las palabras largas para ajustarse al contenedor
  },
};

const isValidString = (str) => {
  return str && str.trim() !== '' && str !== null && str !== undefined;
};

const findIdByName = (array, name) => {
  const item = array.find((item) => item.name === name);
  return item ? item.id : null;
};

const distributeValues = (categories) => {
  let welcomeUseOne = '';
  let welcomeUseTwo = '';
  let welcomeUseThree = '';

  let nonOtherIndex = 0;

  categories.forEach((category) => {
    if (category != 'Other') {
      if (nonOtherIndex === 0) {
        welcomeUseOne = category;
      } else if (nonOtherIndex === 1) {
        welcomeUseTwo = category;
      } else if (nonOtherIndex === 2) {
        welcomeUseThree = category;
      }
      nonOtherIndex++;
    }
  });

  return { welcomeUseOne, welcomeUseTwo, welcomeUseThree };
};

const itemsPurpose = [
  {
    id: 'idea_generation',
    name: 'Idea Generation',
    subtitle: 'Create proposals, campaigns, product updates, website layouts.',
  },
  {
    id: 'product_management',
    name: 'Product Management',
    subtitle:
      'Create roadmaps, feature specifications, technical feasibilities, prototypes.',
  },
  {
    id: 'search_engine_optimization',
    name: 'Search Engine Optimization',
    subtitle:
      'Create Keyword research, optimize page content, meta tags and descriptions.',
  },
  {
    id: 'content_generation',
    name: 'Content Generation',
    subtitle:
      'Create feedback surveys, drafting emails, social media, blog posts.',
  },
  {
    id: 'summarization',
    name: 'Summarization',
    subtitle:
      'Create summaries of academic journals, legal documents, database entries.',
  },
  {
    id: 'other',
    name: 'Other',
    subtitle: 'The posibilites are endless, write in the next field.',
  },
];

const itemsWho = [
  { id: 'personal', name: 'Personal' },
  { id: 'organization', name: 'Organization' },
];

const itemsSize = [
  { id: 'just_me', name: 'Just me' },
  { id: 'less_than_10', name: 'Less than 10' },
  { id: 'between_11_and_50', name: 'Between 11 and 50' },
  { id: 'more_than_50', name: 'More than 50' },
];

const validationSchemaWelcome = yup.object().shape({
  agree_mailing_list: yup.boolean(),
  age_checked: yup.boolean().oneOf([true], 'You must confirm your age.'),
  agree_terms_and_conditions: yup
    .boolean()
    .oneOf([true], 'You must agree to the terms and conditions.'),
  // primarily_use_pr: yup
  //   .string()
  //   .required('Please select how you primarily use Straico.'),
  // kind_of_work: yup.string().required('Please select your kind of work.'),
});

const Welcome = inject('store')(
  observer(({ store }) => {
    let navigate = useNavigate();
    const { theme } = useThemeStore();
    // Form Welcome logic with RHF
    const { control, handleSubmit, setValue } = useForm({
      defaultValues: {
        state: '',
        agree_mailing_list: true,
        age_checked: true,
        agree_terms_and_conditions: true,
        // primarily_use_pr: '',
        // kind_of_work: '',
      },
      resolver: yupResolver(validationSchemaWelcome),
    });

    const [purposeName, setPurposeName] = React.useState([]);
    const [otherName, setOtherName] = React.useState('');
    const [whoName, setWhoName] = React.useState('');
    const [sizeName, setSizeName] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState(false);

    const nextState = (state) => {
      switch (state) {
        case 'modal_beta':
          return 'beta';
        case 'modal_join_list':
          return 'join_list';
        default:
          return state;
      }
    };

    useEffect(() => {
      setValue('state', nextState(store.profile.continue));
    }, []);

    const onSubmit = async () => {
      let valid = true;

      // Validate question 1 and 2
      if (purposeName.length > 0 && isValidString(whoName)) {
        //Validate other
        if (purposeName.includes('Other')) {
          if (!isValidString(otherName)) {
            valid = false;
          }
        }
        //Validate organization
        if (whoName == 'Organization') {
          if (!isValidString(sizeName)) {
            valid = false;
          }
        }
      } else {
        valid = false;
      }

      if (valid) {
        setErrorMessage(false);

        const { welcomeUseOne, welcomeUseTwo, welcomeUseThree } =
          distributeValues(purposeName);

        let newData = {
          state: 'beta',
          agree_mailing_list: true,
          age_checked: true,
          agree_terms_and_conditions: true,
          welcome_use_one: findIdByName(itemsPurpose, welcomeUseOne),
          welcome_use_two: findIdByName(itemsPurpose, welcomeUseTwo),
          welcome_use_three: findIdByName(itemsPurpose, welcomeUseThree),
          welcome_use_other: isValidString(otherName) ? otherName : '',
          welcome_who_item: findIdByName(itemsWho, whoName),
          welcome_who_size: findIdByName(itemsSize, sizeName),
        };

        // console.log('-----------------------------');
        // console.log(valid);
        // console.log(newData);
        // console.log('-----------------------------');

        try {
          await store.api.post('/user/beta', newData);
          if (store.profile.scope == 'appsumo') {
            navigate('/my-profile/pricing');
          }
          store.refreshTokenAndProfile();
          window.userGuiding.previewGuide(108975);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      } else {
        // console.log('Errorr');
        setErrorMessage(true);
      }
    };

    const handleChangePurpose = (event) => {
      const {
        target: { value },
      } = event;
      setOtherName('');
      if (value.length <= 3) {
        setPurposeName(typeof value === 'string' ? value.split(',') : value);
      }
    };

    const handleOtherChange = (event) => {
      setOtherName(event.target.value);
    };

    const handleChangeWho = (event) => {
      setSizeName('');
      setWhoName(event.target.value);
    };

    const handleChangeSize = (event) => {
      setSizeName(event.target.value);
    };

    return (
      <div className="font-figtree fixed inset-x-0 inset-0 flex items-center justify-center z-50">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-raisin-black opacity-55"></div>
        </div>

        <div
          className={`welcome-wrapper relative ${
            theme == 'dark'
              ? 'bg-lead text-crystal-bell'
              : 'bg-seasalt text-raisin-black'
          } rounded-[21px] overflow-auto transform transition-all max-w-3xl h-[840px] shadow-xl`}
        >
          <div
            className={`p-7 h-full overflow-auto overflow-rtl ${
              theme == 'dark' ? 'overflow-rtl-dark' : 'overflow-rtl-light'
            }`}
          >
            <div className="mb-[16px]">
              <StraicoIcon className="fill-nue-blue duration-500" />
            </div>

            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-[16px]"
            >
              <div>
                <p className="text-lg font-medium leading-7">
                  Welcome, {store.profile.fname + ' ' + store.profile.lname}!{' '}
                  <br />
                  Get ready to unlock the full potential of generative AI.
                </p>
              </div>
              <div>
                <p className="text-cool-gray text-base font-normal leading-5 text-justify">
                  All the AI tools you need in one place. Boost your
                  productivity, unleash your creativity, and stay ahead of the
                  competition with ease.{' '}
                  <strong className="font-bold text-cool-gray">
                    A world of limitless possibilities right at your fingertips!{' '}
                  </strong>
                  <br />
                </p>
              </div>
              <div>
                <p className="text-lg font-medium leading-7">
                  First, tell us a little about yourself.
                </p>
              </div>
              <div className="flex flex-col gap-[16px]">
                <div className="flex flex-col gap-[10px]">
                  <p className=" text-cool-gray font-semibold text-sm">
                    What is the main usage you're going to use Straico for?*
                    <span className="font-light"> (select up to 3)</span>
                  </p>

                  <FormControl sx={{}}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Question 1
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={purposeName}
                      onChange={handleChangePurpose}
                      input={<OutlinedInput label="Question 1" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {itemsPurpose.map((item) => (
                        <MenuItem key={item.name} value={item.name}>
                          <Checkbox
                            checked={purposeName.indexOf(item.name) > -1}
                          />
                          <ListItemText
                            primary={item.name}
                            secondary={item.subtitle}
                            primaryTypographyProps={{
                              style: styles.menuItemText,
                            }}
                            secondaryTypographyProps={{
                              style: styles.menuItemText,
                            }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {purposeName.includes('Other') && (
                    <>
                      <p className=" text-cool-gray font-semibold text-sm">
                        Give us more details about the other option*
                      </p>
                      <TextField
                        label="Specify Other"
                        value={otherName}
                        onChange={handleOtherChange}
                        variant="outlined"
                        sx={{ width: '100%' }}
                      />
                    </>
                  )}
                </div>
                <div className="flex flex-col gap-[10px]">
                  <p className=" text-cool-gray font-semibold text-sm">
                    Is the main usage you're going to use Straico is for an
                    organization or personal?*
                  </p>

                  <FormControl sx={{ mb: 2, minWidth: 120 }}>
                    <InputLabel id="demo-single-select-label">
                      Question 2
                    </InputLabel>
                    <Select
                      labelId="demo-single-select-label"
                      id="demo-single-select"
                      value={whoName}
                      onChange={handleChangeWho}
                      input={<OutlinedInput label="Question 2" />}
                    >
                      {itemsWho.map((item) => (
                        <MenuItem key={item.name} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {whoName == 'Organization' && (
                    <>
                      <p className=" text-cool-gray font-semibold text-sm">
                        How many people form part of your organization?*
                      </p>
                      <FormControl sx={{ mb: 2, minWidth: 120 }}>
                        <InputLabel id="organization-select-label">
                          Size
                        </InputLabel>
                        <Select
                          labelId="organization-select-label"
                          id="organization-select"
                          value={sizeName}
                          onChange={handleChangeSize}
                          input={<OutlinedInput label="Size" />}
                        >
                          {itemsSize.map((item) => (
                            <MenuItem key={item.name} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}

                  {/* <SelectControlled
                  control={control}
                  name="primarily_use_pr"
                  options={[
                    {
                      id: 'work',
                      title: 'For work',
                    },
                    {
                      id: 'personal_use',
                      title: 'For personal use',
                    },
                    {
                      id: 'personal_business',
                      title: 'For my personal business/startup',
                    },
                  ]}
                /> */}
                </div>
                <div className="flex flex-col gap-[8px]">
                  {/* <CheckboxControlled
                  name="agree_mailing_list"
                  control={control}
                  label="Join our mailing list"
                /> */}
                  <CheckboxControlled
                    name="age_checked"
                    control={control}
                    label="Confirm that you’re older than 14*"
                  />
                  <CheckboxControlled
                    name="agree_terms_and_conditions"
                    control={control}
                    label={
                      <p>
                        Accept Straico’s{' '}
                        <a
                          href="https://straico.com/terms-and-conditions/"
                          target="__blank"
                          className="text-violet-blue underline"
                        >
                          terms and conditions
                        </a>
                        *
                      </p>
                    }
                  />
                </div>
              </div>
              <div>
                <p className="text-lg font-medium leading-7">
                  Thanks for joining the Straico community!
                </p>
              </div>
              {errorMessage ? (
                <>
                  <p className="text-red-400 text-sm">
                    You must fill all fields with a * character
                  </p>
                </>
              ) : (
                <></>
              )}
              <div className="flex justify-center md:justify-start items-center md:items-start">
                <Button
                  type="submit"
                  title={'Get Started Now!'}
                  CustomIcon={
                    <img
                      src="/svg/logo-symbol-snow-100.svg"
                      className="cursor-pointer duration-500 undefined h-6 w-6 ml-2.5 mt-2"
                      alt="logo symbol"
                    />
                  }
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  })
);

export default Welcome;
