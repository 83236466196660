function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_848_646)">
        <path
          d="M18 19V23.7C18.7058 23.458 19.348 23.0602 19.879 22.536L22.535 19.878C23.0598 19.3477 23.458 18.7057 23.7 18H19C18.7348 18 18.4804 18.1054 18.2929 18.2929C18.1054 18.4804 18 18.7348 18 19Z"
          fill="#797779"
        />
        <path
          d="M7.172 13.828C6.80042 14.1995 6.50566 14.6405 6.30457 15.1259C6.10347 15.6113 5.99998 16.1316 6 16.657V18H7.343C7.86841 18 8.38868 17.8965 8.87409 17.6954C9.3595 17.4943 9.80053 17.1996 10.172 16.828L21.5 5.49998C21.8978 5.10216 22.1213 4.56259 22.1213 3.99998C22.1213 3.43737 21.8978 2.89781 21.5 2.49998C21.1022 2.10216 20.5626 1.87866 20 1.87866C19.4374 1.87866 18.8978 2.10216 18.5 2.49998L7.172 13.828Z"
          fill="#797779"
        />
        <path
          d="M24 4.952C23.8297 5.6938 23.4557 6.37332 22.92 6.914L11.586 18.243C11.0301 18.8019 10.3688 19.2449 9.64049 19.5465C8.91218 19.8481 8.13128 20.0022 7.343 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V16.657C3.99781 15.8688 4.15207 15.088 4.45384 14.3598C4.75561 13.6316 5.1989 12.9706 5.758 12.415L17.086 1.086C17.6226 0.549539 18.2983 0.173439 19.037 0C19.024 0 19.013 0 19 0H5C3.67441 0.00158786 2.40356 0.528882 1.46622 1.46622C0.528882 2.40356 0.00158786 3.67441 0 5L0 19C0.00158786 20.3256 0.528882 21.5964 1.46622 22.5338C2.40356 23.4711 3.67441 23.9984 5 24H16V19C16 18.2044 16.3161 17.4413 16.8787 16.8787C17.4413 16.3161 18.2044 16 19 16H24V5C24 4.984 24 4.969 24 4.952Z"
          fill="#797779"
        />
      </g>
      <defs>
        <clipPath id="clip0_848_646">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
