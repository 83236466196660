import { NavLink } from 'react-router-dom';

function NotFound() {
  return (
    <main className="h-screen w-full flex flex-col justify-center items-center font-figtree text-raisin-black">
      <div className="relative">
        <h1 className="text-9xl font-extrabold tracking-widest">404</h1>
        <div className="bg-nue-blue px-2 text-sm rounded rotate-12 absolute text-white bottom-12 left-[5rem]">
          Page Not Found
        </div>
      </div>
      <p className="my-4 text-lg">
        The page
        <span className="font-bold"> {window.location.pathname} </span>
        does not exist.
      </p>
      <NavLink to="/">
        <button className="mt-5">
          <a className="relative inline-block text-sm font-medium text-nue-blue group active:text-crayola-800 focus:outline-none focus:ring">
            <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-nue-blue group-hover:translate-y-0 group-hover:translate-x-0"></span>

            <span className="relative block px-8 py-3 bg-nue-blue text-white border border-current border-seasalt">
              Go Home
            </span>
          </a>
        </button>
      </NavLink>
    </main>
  );
}

export default NotFound;
