function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <path
        d="M4.76471 3C4.17647 3 3 3.37647 3 4.88235V6.29412C3 6.92157 3.35294 8.17647 4.76471 8.17647H16.2353C16.8235 8.17647 18 7.8 18 6.29412V4.88235C18 4.2549 17.6471 3 16.2353 3H4.76471Z"
        stroke="inherit"
        strokeWidth="1.5"
      />
      <path
        d="M4.76471 10.5294C4.17647 10.5294 3 10.9059 3 12.4118V17.1176C3 17.7451 3.35294 19 4.76471 19H9.17647C9.76471 19 10.9412 18.6235 10.9412 17.1176V12.4118C10.9412 11.7843 10.5882 10.5294 9.17647 10.5294H4.76471Z"
        stroke="inherit"
        strokeWidth="1.5"
      />
      <path
        d="M16.2353 10.5294H14.9118C14.3235 10.5294 13.1471 10.9059 13.1471 12.4118V17.1176C13.1471 17.7451 13.5 19 14.9118 19H16.2353C16.8235 19 18 18.6235 18 17.1176V12.4118C18 11.7843 17.6471 10.5294 16.2353 10.5294Z"
        stroke="inherit"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default SvgComponent;
