import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useCatalogStore } from './store/catalog';

const useCatalog = (store) => {
  const { catalog, setCatalog } = useCatalogStore();

  const getCatalog = async () => {
    try {
      const response = await store.api.get(`/tool/catalog/`);
      const data = await response.data;
      setCatalog(data);
    } catch (error) {
      toast.error('Failed to fetch catalog');
      setCatalog(null);
    }
  };

  useEffect(() => {
    if (store && catalog === null) getCatalog();
  }, [store]);
};

export default useCatalog;
