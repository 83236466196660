import { useState } from 'react';
import { inject, observer } from 'mobx-react';
// Icons
import { ShareIcon } from '@heroicons/react/24/outline';
import { LinkIcon } from '@heroicons/react/24/solid';
// Components
import Modal from 'Components/Common/Modal/Modal';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import useClipboardComponent from 'Hooks/useClipboardComponent';
import config from 'config';

const ShareTool = inject('store')(
  observer(({ store, isOpen, closeModal, tool }) => {
    // Copy to clipboard
    const { copyToClipboardWithoutRef } = useClipboardComponent();
    // Modal
    const [loading, setLoading] = useState(false);
    // modify share state tool
    const onShare = async () => {
      setLoading(true);
      try {
        const res = await store.api.post('/shareTools', {
          title: tool.title,
          creator: store.profile._id,
          tool_id: tool._id,
          prompt_id: tool.prompts[0]._id,
          newContent: '.',
        });
        const data = await res.data;
        copyToClipboardWithoutRef(
          `${config.baseFrontUrl}/share/tool/${data.share_id}`,
          false
        );
        toast.success('Tool shared successfully');
      } catch (err) {
        toast.error('Error sharing tool');
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    // return
    return (
      <>
        <Modal
          open={isOpen}
          title="SHARE YOUR TEMPLATE"
          iconTitle={
            <ShareIcon
              className="h-5 w-5 text-violet-blue"
              aria-hidden="true"
            />
          }
          onClose={closeModal}
        >
          <div className="my-4">
            <div className="dialog-content-container-grade overflow-auto pr-2">
              <div className="mb-2">
                <p className="font-figtree text-raisin-black dark:text-crystal-bell">
                  You're set to inspire! Along with your template, variable
                  inputs will be shared too, offering users an example of its
                  work with GPT-3.5 turbo (soon you will be able to pick the
                  preferred model for your template).
                </p>
              </div>
            </div>

            <div className="flex justify-center mt-4">
              <LoadingButton
                loading={loading}
                onClick={onShare}
                variant="contained"
                color="primary"
                sx={{
                  mt: 3,
                  color: 'white',
                  fontWeight: 'bold',
                  minWidth: '200px',
                  fontSize: '18px',
                  fontFamily: 'lexend deca',
                  textTransform: 'uppercase',
                }}
                startIcon={<LinkIcon className="h-5 w-5" />}
              >
                Copy Link
              </LoadingButton>
            </div>
          </div>
        </Modal>
      </>
    );
  })
);

export default ShareTool;
