import { useEffect } from 'react';
// Mobx
import { inject, observer } from 'mobx-react';
// Components
import Modal from 'Components/Common/Modal/NewModal';
// Icons
import { TrashIcon } from '@heroicons/react/24/outline';

const DeleteChat = inject('store')(
  observer(({ onClose, onDelete, deleted, setDeleted }) => {
    useEffect(() => {
      setDeleted(false);
    }, []);

    return (
      <>
        <Modal
          open={true}
          title="Delete chat"
          onClose={onClose}
          onConfirm={onDelete}
          buttonConfirmProps={{
            disabled: deleted,
          }}
          onConfirmBtnText="Delete"
          onConfirmBtnIcon={
            <TrashIcon className="h-[18px] w-[18px]" aria-hidden="true" />
          }
        >
          <div className="font-figtree max-w-64 text-base text-black dark:text-crystal-bell text-center">
            <div className="mb-2">
              Are you sure you want to delete this chat?
            </div>

            {deleted ? (
              <div className="text-violet-blue bg-lavender rounded-md p-1 text-center mt-4">
                Information deleted
              </div>
            ) : null}
          </div>
        </Modal>
      </>
    );
  })
);
export default DeleteChat;
