import { create } from 'zustand';

const usePromptLibraryStore = create((set) => ({
  openPromptLibrary: false,
  setOpenPromptLibrary: (open) => set({ openPromptLibrary: open }),
  favTemplates: [],
  setFavTemplates: (favTemplates) => set({ favTemplates }),
}));

export default usePromptLibraryStore;
