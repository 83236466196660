import { forwardRef } from 'react';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const TextAreaInput = forwardRef(({ disabled, readonly, ...props }, ref) => {
  const { theme } = useThemeStore();

  return (
    <textarea
      {...props}
      ref={ref}
      disabled={disabled}
      readOnly={readonly}
      className={cn(
        'rounded-[10px] border-[2.5px] px-3 py-2 w-full font-figtree disabled:font-[500] disabled:cursor-not-allowed ',
        {
          'bg-night-black border-sonic-silver text-white  disabled:bg-umbra disabled:border-night-black disabled:text-sonic-silver':
            theme === 'dark',
          'bg-white text-raisin-black border-lavender disabled:bg-[#C9CADB] disabled:border-lavender disabled:text-cool-gray':
            theme !== 'dark',
        }
      )}
    />
  );
});

TextAreaInput.displayName = 'TextInput';

export default TextAreaInput;
